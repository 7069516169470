const {  SET_AUTH_TOKEN, REMOVE_TOKEN  } = require("Redux/Actions/ActionTypes");

const intialState = {
  authtoken: "",
};

const PrintTableAuth = (state = intialState, {type, payload}) => {
    
  switch (type) {

    case SET_AUTH_TOKEN:
      return {
        ...state,
        authToken :payload
      };

   case REMOVE_TOKEN :
      return {
        ...state,
        authToken:payload
      }

    default:
      return state;
  }
};

export default PrintTableAuth;
