export const Category = [
    {
        value:1,
        label:"Dragon Series"
    },
    {
        value:2,
        label:"Robot Series"
    },
    {
        value:3,
        label:"Mari Series"
    },

]



export const SnakeRedeem = [
    {
        value:1,
        label:"Yes"
    },
    {
        value:2,
        label:"No"
    },

]
export const MintOptions = [
        {
            value:4,
            label:"ALL"
        },
        {
            value:2,
            label:"MINTED"
        },
        {
            value:1,
            label:"NON-MINTED"
        },
        {
            value:3,
            label:"BURN"
        }
]