import { LABELS } from "Shared";

export const SUBSCRIPTION_HEADINGS = [LABELS.S_NO, LABELS.USER_NAME, LABELS.TYPE, LABELS.UID, LABELS.ORDER_ID, LABELS.START_TIME, LABELS.END_TIME, ''];
export const ACTIVITIES_HEADINGS = [LABELS.S_NO, LABELS.CREATION_DATE, LABELS.STATUS];

export const SUBSCRIPTION_STATE = {
    1: 'SUBSCRIPTION_RECOVERED',
    2: 'SUBSCRIPTION_RENEWED',
    3: 'SUBSCRIPTION_CANCELED',
    4: 'SUBSCRIPTION_PURCHASED',
    5: 'SUBSCRIPTION_ON_HOLD',
    6: 'SUBSCRIPTION_IN_GRACE_PERIOD',
    7: 'SUBSCRIPTION_RESTARTED',
    8: 'SUBSCRIPTION_PRICE_CHANGE_CONFIRMED',
    9: 'SUBSCRIPTION_DEFERRED',
    10: 'SUBSCRIPTION_PAUSED',
    11: 'SUBSCRIPTION_PAUSE_SCHEDULE_CHANGED',
    12: 'SUBSCRIPTION_REVOKED',
    13: 'SUBSCRIPTION_EXPIRED',
    14: 'SUBSCRIPTION_STATE_PENDING'
};