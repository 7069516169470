// components
import {  CreateNFT , Listing } from "Components/molecules";
import Airdrop from "Components/molecules/Listing/Airdrop";
import Nft from "Views/Nft";
import Royalty from "Views/Royalty";
import PrivateGames from "Views/PrivateGames";
import UserList from "Views/UserList";
import CashList from "Views/CashList";
import AllowBots from "Views/AllowBots";
import TournamentMaintenance from "Views/TournamentMaintenance";
import TournamentLogs from "Views/TournamentLogs";
import Referral from "Views/Referral";
import TransactionHistory from "Views/TransactionHistory";
import UserRewardsHistory from "Views/UserRewardsHistory";
import Teams from "Views/Teams";
import TeamTournamentWinners from "Views/TeamTournamentWinners";
import MonthlyLogs from "Views/MonthlyLogs";
import FreeTrials from "Views/FreeTrials";

// utils
import { ROUTES } from "./Constants";
import Subscriptions from "Views/Subscriptions";
import { LABELS, REDEEM_TYPE } from "Shared";
import TeamTournamentRules from "Views/TeamTournamentRules";
import Jackpot from "Views/Jackpot";
import RedeemControl from "Views/RedeemControl";
import UserLogs from "Views/UserLogs";
import ViewDayUserLogsDetail from "Views/UserLogs/ViewDayUserLogsDetail";
import CashPointRedeem from "Views/CashPointRedeem";

export const PRIVATE_ROUTES = [
  {
    path: ROUTES.BLACKLIST_NFT,
    component:Nft ,
    title: LABELS.BLACKLIST_NFT,
  },
  {
    path: ROUTES.CREATE_NFT,
    component: CreateNFT,
    title: LABELS.CREATE_NFT,
  },
  {
    path: ROUTES.REDEEM_AIRDROP,
    component: Airdrop,
    title: LABELS.REDEEM_AIRDROP,
  },
  {
    path: ROUTES.REDEEM_NFT,
    component: Listing,
    title: LABELS.REDEEM_AIRDROP,
  },
  {
    path: ROUTES.REDEEM_CONTROL,
    component: RedeemControl,
    title: LABELS.REDEEM_CONTROL,
  },
  {
    path: ROUTES.ROYALTY,
    component:Royalty ,
    title: LABELS.ROYALTY,
  },
  {
    path: ROUTES.JACKPOT,
    component:Jackpot ,
    title: LABELS.JACKPOT,
  },
  {
    path: ROUTES.PRIVATE_GAMES,
    component: PrivateGames,
    title: LABELS.PRIVATE_GAMES,
  },
  {
    path: ROUTES.USER_LIST,
    component: UserList,
    title: LABELS.USER_LIST,
  },
  {
    path: ROUTES.CASH_LIST,
    component: CashList,
    title: LABELS.CASH_LIST,
  },
  {
    path: ROUTES.ALLOW_BOTS,
    component: AllowBots,
    title: LABELS.ALLOW_BOTS,
  },
  {
    path: ROUTES.REDEEM_USD,
    component: () => <CashPointRedeem type={REDEEM_TYPE.CASH_FROM_TROPHY} heading={LABELS.CASH_POINT_REDEEM_USD} />,
    title: LABELS.CASH_POINT_REDEEM_USD,
  },
  {
    path: ROUTES.REDEEM_BTC,
    component: () => <CashPointRedeem type={REDEEM_TYPE.BITCOIN_FROM_TROPHY} heading={LABELS.CASH_POINT_REDEEM_BTC} />,
    title: LABELS.CASH_POINT_REDEEM_BTC,
  },
  {
    path: ROUTES.REDEEM_HKD,
    component: () => <CashPointRedeem type={REDEEM_TYPE.HKD_FROM_TROPHY} heading={LABELS.CASH_POINT_REDEEM_HKD} />,
    title: LABELS.CASH_POINT_REDEEM_HKD,
  },
  {
    path: ROUTES.REFERRAL,
    component: Referral,
    title: LABELS.REFERRAL,
  },
  {
    path: ROUTES.TRANSACTION_HISTORY,
    component: TransactionHistory,
    title: LABELS.TRANSACTION_HISTORY,
  },
  {
    path: ROUTES.TOURNAMENT_MAINTENANCE,
    component: TournamentMaintenance,
    title: LABELS.TOURNAMENT_MAINTENANCE,
  },
  {
    path: ROUTES.TOURNAMENT_LOGS,
    component: TournamentLogs,
    title: LABELS.TOURNAMENT_LOGS,
  },
  {
    path: ROUTES.USER_REWARDS_HISTORY,
    component: UserRewardsHistory,
    title: LABELS.USER_REWARDS_HISTORY,
  },
  {
    path: ROUTES.TEAMS,
    component: Teams,
    title: LABELS.TEAMS,
  },
  {
    path: ROUTES.TEAM_TOURNAMENT_WINNERS,
    component: TeamTournamentWinners,
    title: LABELS.TEAM_TOURNAMENT_WINNERS
  },
  {
    path: ROUTES.TEAM_TOURNAMENT_RULES,
    component: TeamTournamentRules,
    title: LABELS.TEAM_TOURNAMENT_RULES
  },
  {
    path: ROUTES.CASH_POINT_LOGS,
    component: MonthlyLogs,
    title: LABELS.CASH_POINT_LOGS,
  },
  {
    path: ROUTES.FREE_TRIALS,
    component: FreeTrials,
    title: LABELS.FREE_TRIALS,
  },
  {
    path: ROUTES.SUBSCRIPTIONS,
    component: Subscriptions,
    title: LABELS.SUBSCRIPTIONS,
  },
  {
    path: ROUTES.USER_LOGS,
    component: UserLogs,
    title: LABELS.USER_LOGS,
    exact: true
  },
  {
    path: `${ROUTES.USER_LOGS}/:dayId`,
    component: ViewDayUserLogsDetail,
    title: LABELS.USER_LOGS,
    exact: true
  },
];
