export const ALLOW_BOTS = "ALLOW_BOTS";
export const FETCH_BOTS_STATUS = "FETCH_BOTS_STATUS";

export const allowBots = ( payload,callback) => {
  return {
    type: ALLOW_BOTS,
    payload,
    callback,
  };
};

export const fetchBotStatus = ( callback) => {
  return {
    type: FETCH_BOTS_STATUS,
    callback,
  };
};