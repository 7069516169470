import React from 'react';
import { useField, ErrorMessage } from "formik"

function TextField({ mandatory = true, textAria = false, children, type, step, min, max, disabled, onChange, rows, label, icon = "", ...props } = {}) {
  const [field, meta] = useField(props);


  return (
    <>
      <label>{label} {mandatory?<sup className="text-danger">*</sup>:null}</label>
      <div className="form-group"> 
        { !textAria ? <input min={min} max={max} type={type} step={step} disabled={disabled} onChange={onChange}
          className={`form-control ${meta.touched && meta.error && "is-invalid"}`}
          autoComplete="off" {...field}{...props} 
        /> :
          <textarea
            className={`form-control ${meta.touched && meta.error && "is-invalid"}`}
            autoComplete="off"
            rows={rows}
            {...field}{...props}
          >{children}</textarea>
        }
        <span className="error d-block"><ErrorMessage name={field.name} /></span>
      </div>
    </>
  )
}
export default TextField;
