// libs
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { ToastContainer, toast } from 'react-toastify';
// hooks
import { useDispatch } from 'react-redux';
// components
// utils
// helpers
// actions
import { getTeamRules, updateTeamRules } from 'Redux/Actions/Team';
import { LABELS } from 'Shared';
import { Button } from 'Components';


function TeamTournamentRules() {
  // ref
  const editorRef = useRef(null);
  // hooks
  const dispatch = useDispatch();

  // states
  const [initialValue, setInitialValue] = useState("");

  useEffect(() => {
    fetchListData();
  }, []);

  const fetchListData = () => {
    dispatch(
      getTeamRules({}, (data) => {
        setInitialValue(data?.data);
      })
    );
  };

  const log = () => {
    if (editorRef.current) {
      dispatch(updateTeamRules({ rawData: editorRef.current.getContent()}, (data) => {
        toast.success(data?.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }));
    }
  };

  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <div className="card mt-4">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-sm-4 mb-3">
                <h4 className="h6 mb-0">{LABELS.TEAM_TOURNAMENT_RULES}</h4>
              </div>
              <Editor
                apiKey='isty61kcszb82xzei0755svt1mmh06k51i2fcx7tt5sx7nl8'
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={initialValue}
                init={{
                  height: 500,
                  menubar: 'insert',
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'media', 'table', 'code', 'help', 'wordcount'
                  ],
                  toolbar: 'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />
              <div className="mt-4 d-flex justify-content-end">
                <Button onClick={log} className="btn btn-lg btn-secondary" type="submit">
                  Add Data
                </Button>
              </div>
                
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default TeamTournamentRules;
