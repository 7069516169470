const TableHeading = () => {
    return (
      <thead className="bg-dark text-white align-content-center">
        <tr>
          <th> S.no </th>
          <th> Creation Date </th>
          <th> Name </th>
          <th>Email</th>
          <th>USD</th>
          <th>UID</th>
          <th>
            Status
          </th>
        </tr>
      </thead>
    );
  };
  
  export default TableHeading;
  