
import { START, STOP } from "Redux/Actions/LoaderAction";

const initialState = {
  loader: false,
};
const LoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case START: {
      return {
    
        loader:true
      };
    }
    case STOP: {
        return {
         
          loader:false
          ,
        };
      }
    default: {
      return state;
    }
  }
};

export default LoaderReducer;
