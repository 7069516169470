import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getGameData } from "Redux/Actions/GamesAction";

function ViewModal({ currentID }) {
  const dispatch = useDispatch();
  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    dispatch(
      getGameData(currentID, (data) => {
        setTempData(data?.data);        
      })
    );
  }, []);

  

  return (
    <>
      <h2 className="h4 mb-3">Private Game Details</h2>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label> Min Player:</label>
                <span className="d-inline-block ms-2"> {tempData?.minPlayers || 0}</span>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-2">  
                <label> mDollar:</label>
                <span className="d-inline-block ms-2"> {Math.trunc(tempData?.entryFee?.mDollar * 100) / 100 || 0}</span>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label> No of Rounds: </label>
                <span className="d-inline-block ms-2"> {Math.trunc(tempData?.rounds * 100) / 100 || 0}</span>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label>Start Time:</label>
                <span className="d-inline-block ms-2"> {moment.utc(tempData?.creationTime).local().format("lll")}
                </span>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label> Bonus Point: </label>
                <span className="d-inline-block ms-2"> {Math.trunc(tempData?.bonusPoint * 100) / 100 || 0}</span>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label> Win Points: </label>
                <span className="d-inline-block ms-2"> {Math.trunc(tempData?.entryFee?.winPoint * 100) / 100 || 0}</span>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label> Game Timer (Room Time): </label>
                <span className="d-inline-block ms-2"> {Math.trunc(tempData?.gameTime * 100) / 100 || 0}</span>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label> Is entry by code valid: </label>
                <span className="d-inline-block ms-2"> {Math.trunc(tempData?.isCodeEntryValid * 100) / 100 ? "Yes" : "No"}</span>
              </div>
            </div>            
          </div>
        </div>
      </div>

      <div className="mt-4">
        <h3 className="h5">Rewards</h3>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <fieldset className="fieldset mb-2 h-100">
                  <legend>Rank 1</legend> 
                  <div className="mb-2">
                    <label>mDollor: </label>
                    <span className="d-inline-block ms-2">
                      {tempData?.rewards ? Math.trunc(tempData?.rewards[0]?.mDollar * 100) / 100 : 0}
                    </span>
                  </div>               
                  <div className="mb-2">
                    <label>mTrophy: </label>
                    <span className="d-inline-block ms-2">
                      {tempData?.rewards ? Math.trunc(tempData?.rewards[0]?.mTrophy * 100) / 100 : 0}
                    </span>
                  </div>               
                  <div className="mb-2">
                    <label>USD: </label>
                    <span className="d-inline-block ms-2">
                      {tempData?.rewards ? Math.trunc(tempData?.rewards[0]?.USD * 100) / 100 : 0}
                    </span>
                  </div>
                </fieldset>
              </div>

              <div className="col-md-4 col-sm-6">
                <fieldset className="fieldset mb-2 h-100">
                  <legend>Rank 2</legend>
                  <div className="mb-2">
                      <label>mDollor: </label>
                      <span className="d-inline-block ms-2">
                        {tempData?.rewards ? Math.trunc(tempData?.rewards[1]?.mDollar * 100) / 100 : 0}
                      </span>
                    </div> 
                    
                    <div className="mb-2">
                      <label>mTrophy: </label>
                      <span className="d-inline-block ms-2">
                        {tempData?.rewards ? Math.trunc(tempData?.rewards[1]?.mTrophy * 100) / 100 : 0}
                      </span>
                    </div>
                    
                    <div className="mb-2">
                      <label>USD: </label>
                      <span className="d-inline-block ms-2">
                        {tempData?.rewards ? Math.trunc(tempData?.rewards[1]?.USD * 100) / 100 : 0}
                      </span>
                    </div>
                </fieldset>
              </div>

              <div className="col-md-4 col-sm-6">
                <fieldset className="fieldset mb-2 h-100">
                  <legend>Rank 3</legend>
                  <div className="mb-2">
                    <label>mDollor:</label>
                    <span className="d-inline-block ms-2">
                      {tempData?.rewards ? Math.trunc(tempData?.rewards[2]?.mDollar * 100) / 100 : 0}
                    </span>
                  </div>
                
                  <div className="mb-2">
                    <label>mTrophy: </label>
                    <span className="d-inline-block ms-2">
                      {tempData?.rewards ? Math.trunc(tempData?.rewards[2]?.mTrophy * 100) / 100 : 0}
                    </span>
                  </div>
                
                  <div className="mb-2">
                    <label>USD: </label>
                    <span className="d-inline-block ms-2">
                      {tempData?.rewards ? Math.trunc(tempData?.rewards[2]?.USD * 100) / 100 : 0}
                    </span>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(ViewModal);