import { withSnackbar } from "notistack";

const JackpotType = ({
  subHeading,

  countName = "",
  countHeading,
  initialCount = 0,
  countError = false,

  valueHeading,
  valueName = "",
  initialValue = 0,
  valueError = false,

  handleChange,
}) => {
  return (
      <div>
        <h2 className="h4 mb-4">{subHeading}</h2>
        <div className="row align-items-end">
          <div className="col-md-6 col-lg-6">
            <div className="form-group">
            <label> {countHeading} </label>
              <input
                type={"number"}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={initialCount}
                name={countName}
                placeholder={"Enter the amount"}
                className="form-control"
                min={0}
                required
              />
              {countError && <span className="error d-block">{countError}</span>}
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="form-group">
              <label> {valueHeading} </label>
              <input
                type={"number"}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={initialValue}
                name={valueName}
                placeholder={"Enter the amount"}
                className="form-control"
                min={0}
                required
              />
              {valueError && <span className="error d-block">{valueError}</span>}
            </div>
          </div>
        </div>
      </div>
  );
};

export default withSnackbar(JackpotType);
