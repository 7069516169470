// libs
import React, { useEffect, useState } from 'react';
// hooks
import { useDispatch } from 'react-redux';
// components
import { CustomTable } from 'Components';
// utils
import { indexHistory } from 'Services/Api/Constants';
import { MONTHLY_LOGS_HEADINGS } from './Helpers';
// actions
import { getMonthlyLogs } from 'Redux/Actions/Common';
import { LABELS } from 'Shared';

// constants
const limit = 10;

function MonthlyLogs() {
  // hooks
  const dispatch = useDispatch();

  // states
  const [listData, setListData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    fetchListData();
  }, []);

  const fetchListData = () => {
    const payload = {
      limit: limit,
      skip: 0,
    };
    dispatch(
      getMonthlyLogs(payload, (data) => {
        setListData(data?.data);
        setPageCount(Math.ceil(data?.count / 10));
      })
    );
  };

  const handlePageClick = (pageData) => {
    let currentPage = pageData.selected;
    setCurrentIndex(currentPage);
    const payload = {
      skip: currentPage * limit,
      limit: 10,
    };
    dispatch(
        getMonthlyLogs(payload, (data) => {
        setListData(data?.data);
        setPageCount(Math.ceil(data?.count / 10));
      })
    );
  };

  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <div className="card mt-4">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-sm-4 mb-3">
                <h4 className="h6 mb-0">{LABELS.CASH_POINT_LOGS}</h4>
              </div>
            </div>

            <div className="row">
              {listData?.length ? (
                <div className="col-md-12">
                  <CustomTable
                    isPagination
                    headings={MONTHLY_LOGS_HEADINGS}
                    pageCount={pageCount}
                    currentIndex={currentIndex}
                    handlePageClick={handlePageClick}
                  >
                    {listData?.map((item, index) => {
                      return (
                        <tr key={item.name}>
                          <td>{indexHistory(0) + index}</td>
                          <td>{item?.name || ''}</td>
                          <td>{item?.cashPointGivenOut || 0}</td>
                        </tr>
                      );
                    })}
                  </CustomTable>
                </div>
              ) : (
                <p className="d-flex justify-content-center">{LABELS.NO_RECORDS_FOUND}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MonthlyLogs;
