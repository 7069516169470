// libs
import React, { useEffect, useState } from 'react';
// components
import { CustomTable } from 'Components';
import CustomModal from 'Components/atoms/CustomModal';
import GiveRewardModal from '../GiveRewardModal';
// hooks
import { useDispatch } from 'react-redux';
// utils
import { getTeamTournamentWinnerById } from 'Redux/Actions/Team';
import {
  WINNER_TEAM_TABLE_HEADINGS,
  handleShowTeamStatus,
} from 'Views/TeamTournamentWinners/Helpers';
import { LABELS, TOURNAMENT_REWARD_STATUS } from 'Shared';

function ViewTournamentDetails({ isOpen = false, handleModalClose = () => {}, currentId = '' }) {
  // /hooks
  const dispatch = useDispatch();
  // states
  const [details, setDetails] = useState([]);
  const [giveRewardPopup, setGiveRewardPopup] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchTeamDetails();
    }
  }, [isOpen]);

  const fetchTeamDetails = () => {
    dispatch(
      getTeamTournamentWinnerById({ id: currentId }, (data) => {
        setDetails(data?.data || []);
      })
    );
  };

  return (
    <>
     <CustomModal handleToggle={handleModalClose} isOpen={isOpen} extralarge>
        <h2 className="h4 mb-3">{LABELS.TEAM_TOURNAMENT_DETAILS}</h2>
        <div className="card">
          <div className="card-body">
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <div className="">
                <label>{LABELS.TOURNAMENT_NAME}:</label>
                <span className="ms-2">{currentId || ''}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <h3 className="h5">{LABELS.TOURNAMENT_WINNER_TEAMS}</h3>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <CustomTable headings={WINNER_TEAM_TABLE_HEADINGS}>
                    {details?.length ? (
                      details?.map((item) => (
                        <tr key={item.teamId}>
                          <td>
                            {item?.teamName}
                            {item?.isTeamLeader ? <span className="ml-1">{LABELS.LEADER}</span> : ''}
                          </td>
                          <td>{item?.rank}</td>
                          <td>{item?.totalGamePlayed}</td>
                          <td>{item?.totalGold}</td>
                          <td>{handleShowTeamStatus(item?.status)}</td>
                          <td>
                            {item?.status == TOURNAMENT_REWARD_STATUS.PENDING ? (
                              <button
                                className="btn btn-sm btn-primary mx-1"
                                onClick={() => setGiveRewardPopup(item?.teamId)}
                              >
                                {LABELS.GIVE_REWARD}
                              </button>
                            ) : null}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={WINNER_TEAM_TABLE_HEADINGS.length}>
                          <p className="no_data">{LABELS.NO_RECORDS_FOUND}</p>
                        </td>
                      </tr>
                    )}
                  </CustomTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>

      <GiveRewardModal
        isOpen={!!giveRewardPopup}
        handleModalClose={() => setGiveRewardPopup(!giveRewardPopup)}
        currentTeamId={giveRewardPopup}
        currentId={currentId}
        fetchTeamDetails={fetchTeamDetails}
      />
    </>
  );
}

export default ViewTournamentDetails;
