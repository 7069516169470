import { takeLatest, all, put } from "redux-saga/effects";
import {
  getRequest,
   postRequest,
} from "Shared/Axios";
import { startLoading, stopLoading } from "Redux/Actions/LoaderAction";
import { ALLOW_BOTS, FETCH_BOTS_STATUS } from "Redux/Actions/AllowBot";

const api = require(`../../Services/Api/Constants`);

function* postAllowBotsData({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({
      API: `${api.API_URLS.ALLOW_BOTS}`,
      DATA: payload,
    });
    callback(response);
  } catch (err) {
    callback(err)
  } finally {
    yield put(stopLoading());
  }
}

function* fetchBotStatus({callback }) {
    try {
      yield put(startLoading());
      const response = yield getRequest({
        API: api.API_URLS.FETCH_BOT_STATUS,
      });
      callback(response);
    } catch (error) {
      callback(error);
    } finally {
      yield put(stopLoading());
    }
  }

function* AllowBotsSaga() {
    yield all([
      takeLatest(ALLOW_BOTS, postAllowBotsData),
      takeLatest(FETCH_BOTS_STATUS, fetchBotStatus),
    ]);
  }
  
  export default AllowBotsSaga;