import { LABELS } from "Shared"

export const JACKPOT_TAB_TYPES = {
    DEFAULT: "Default",
    CUSTOM: "Custom",
}

export const JACKPOT_FIELDS = {
    START_TIME: {
        label: LABELS.START_TIME,
        name: "startTime",
        placeholder: LABELS.START_TIME
    },
    END_TIME: {
        label: LABELS.END_TIME,
        name: "endTime",
        placeholder: LABELS.END_TIME
    },
    ACTIVE: {
        label: LABELS.JACKPOT_STATUS,
        name: "active",
        placeholder: LABELS.STATUS
    },
    BIG_JACKPOT : {
        label: LABELS.BIG_JACKPOT,
       COUNT: {
        label : LABELS.JACKPOT_COUNT,
        name: "bigCount",
        placeholder: LABELS.JACKPOT_COUNT
       },
       VALUE: {
        label : LABELS.JACKPOT_VALUE,
        name: "bigValue",
        placeholder: LABELS.JACKPOT_VALUE
       },
       TYPE : {
        name: "bigType"
       }
    },
    MEDIUM_JACKPOT : {
        label: LABELS.MEDIUM_JACKPOT,
       COUNT: {
        label : LABELS.JACKPOT_COUNT,
        name: "mediumCount",
        placeholder: LABELS.JACKPOT_COUNT
       },
       VALUE: {
        label : LABELS.JACKPOT_VALUE,
        name: "mediumValue",
        placeholder: LABELS.JACKPOT_VALUE
       },
       TYPE : {
        name: "mediumType"
       }
    },
    SMALL_JACKPOT : {
        label: LABELS.SMALL_JACKPOT,
       COUNT: {
        label : LABELS.JACKPOT_COUNT,
        name: "smallCount",
        placeholder: LABELS.JACKPOT_COUNT
       },
       VALUE: {
        label : LABELS.JACKPOT_VALUE,
        name: "smallValue",
        placeholder: LABELS.JACKPOT_VALUE
       },
       TYPE : {
        name: "smallType"
       }
    },
}