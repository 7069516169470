import { takeLatest, all, put } from "redux-saga/effects";
import {
  getRequest,
   postRequest,
} from "Shared/Axios";

import { NFT_UPDATE, PREVIOUS_RANGE, RESTRICTION_RANGE } from "Redux/Actions/NftData";
import { startLoading, stopLoading } from "Redux/Actions/LoaderAction";

const api = require(`../../Services/Api/Constants`);

function* postFormData({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({
      API: `${api.API_URLS.POST_UPDATE_AMMOUNT}`,
      DATA: payload,
    });
    callback(response);
  } catch (err) {
    callback(err)
  } finally {
    yield put(stopLoading());
  }
}

function* post_restriction({ payload, callback }) {
    try {
      yield put(startLoading());
      const response = yield postRequest({
        API: `${api.API_URLS.POST_RANGE_AMMOUNT}`,
        DATA: payload,
      });
      callback(response);
    } catch (err) {
      callback(err)
    } finally {
      yield put(stopLoading());
    }
  }

  function* get_previous_range({callback }) {
    try {
      yield put(startLoading());
      const response = yield getRequest({
        API: api.API_URLS.GET_PREVIOUS_ROYALITY,
      });
      callback(response);
    } catch (error) {
      callback(error);
    } finally {
      yield put(stopLoading());
    }
  }



function* NftSaga() {
  yield all([
    takeLatest(NFT_UPDATE, postFormData),
    takeLatest(RESTRICTION_RANGE, post_restriction),
    
    takeLatest(PREVIOUS_RANGE, get_previous_range),




    
  ]);
}

export default NftSaga;
