// libs
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import moment from "moment";
import { withSnackbar } from "notistack";
// components
import Button from "../../Components/atoms/Button";
import Table from "../../Components/molecules/Listing/Table";
import TableHeading from "./Component/TableHeading";

// actions
import {
  putReddeemRequest,
  royality_Data,
} from "Redux/Actions/PrintTableActions";
import { nftUpdate } from "Redux/Actions/NftData";
// utils
import { GetDefaultValue, indexHistory, Status } from "Services/Api/Constants";
import LoaderImage from "../../Components/atoms/Images/Loader.gif";
import { LABELS } from "Shared";

const Royalty = ({ enqueueSnackbar }) => {
  const [royaltyData, setRoyaltyData] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const dispatch = useDispatch();
  const loader = useSelector((state) => state.printTable.loader);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("");

  const limit = 10;
  const divref = useRef(null);


  const updateReddemRequest = (selected, id) => {
    if(selected.value == 1){
      return ;
    }
    const payload = id;

    dispatch(
      putReddeemRequest(payload, (data) => {
        enqueueSnackbar(data.data.message, {
          variant: data.status ? "success" : "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        const payload = {
          type:2,
          skip: 0,
          limit: 10,
          searchKey:search
        };
        dispatch(
          royality_Data(payload, (data) => {
            setRoyaltyData(data?.data);
          })
        );
      })
    );
  };

  const handlePageClick = (pageData) => {
    let currentPage = pageData.selected;
    setCurrentIndex(currentPage);
    const payload = {
      type:2,
      skip: currentPage * limit,
      limit: 10,
      searchKey:search
    };
    dispatch(
      royality_Data(payload, (data) => {
        setRoyaltyData(data?.data);
      })
    );
  };

  useEffect(() => {
    const payload = {
      type:2,
      skip: 0,
      limit: 10,
      searchKey:search
    };
    dispatch(
      royality_Data(payload, (data) => {
        // console.log("data of royality ", data)
        let tempPageCount = Math.ceil(data?.count / 10);
        setPageCount(tempPageCount);
        setRoyaltyData(data?.data);
      })
    ); //this is used to get all reddem request from user
  }, []);

 
  const valueChange = () => {
    const payload = {
      updatedRoyality: value,
    };
    dispatch(
      nftUpdate(payload, (data) => {
        enqueueSnackbar(data.data.message, {
          variant: data.status ? "success" : "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      })
    );
  };

  const OnSubmit = () => {
    const payload = {
          limit: 10,
          skip: 0,
          type:2,
          searchKey:search
        };
        dispatch(
          royality_Data(payload, (data) => {
            let tempPageCount = Math.ceil(data?.count / 10);
            setRoyaltyData(data?.data);
            setPageCount(tempPageCount);
            setCurrentIndex(0);
          })
        );
  
    }

  return (
    <>
      {loader && (
        <div className="loader">
          <img src={LoaderImage} />
        </div>
      )}
      <div className="container-fluid page-body-wrapper">
        <h2 className="h4 mb-4">{LABELS.ROYALTY}</h2>
        <div className="card">
          <div className="card-body">
            <div className="row align-items-end">
              <div className="col-md-5 col-lg-3">
                <label> Royalty Amount (ETH) </label>
                <div className="mb-3">
                  <input
                    type={"number"}
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    placeholder={"Enter the amount"}
                    className="form-control"
                    min={0}
                    required
                  />
                </div>
              </div>
              <div className="col-md-5 col-lg-3 mb-3">
                <Button
                  className="btn btn-md btn-secondary"
                  onClick={() => {
                    valueChange();
                  }}
                > 
                  Update 
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-4">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-md-4">
                <h3 className="h6 mb-4 text-captialize">Redeem Royalty List</h3>
              </div>
              <div className="col-md-8">
              <div className="search_bar input-group ms-auto mb-4">
                <input
                type="text"
                placeholder="Enter email/userID"
                className="form-control"
                onChange={(e)=>{
                  setSearch(e.target.value)
                }}

                />
                <div className="input-group-prepend">
                  <button className="btn btn-md btn-primary" onClick={()=>OnSubmit()}>submit</button>
                </div>
              </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive " ref={divref}>
                  <Table className="table table-bordered">
                    <TableHeading />
                    <tbody className="table-light table-bordered">
                      {royaltyData?.map((item,index) => {
                        return (
                          <tr key={item?.id}>
                            <td>{indexHistory(currentIndex) + index}</td>
                            <td>{moment(item.createdAt).format("L")}</td>

                            <td>{item?.email}</td>
                            <td>{item?.amount}</td>
                            {/* <td>{item?.amountInUSD}</td> */}
                            <td>{item?.userId}</td>
                            <td>{item?.userName}</td>
                            <td>{item?.walletAddress}</td>
                            <td>
                              <Select
                                defaultValue={() =>
                                  GetDefaultValue(item.status)
                                }
                                name={"groupId"}
                                onChange={(e) => {
                                  updateReddemRequest(e, item.id);
                                }}
                                onMenuOpen={(e)=>{
                                  if(index == royaltyData.length - 1  || index == royaltyData.length - 2){
                                    let divElement = document.querySelector(".table-responsive");
                                    let height = divElement.offsetHeight ;
                                    divElement.style.height = height + 82 + "px";                              
                                  }
                                }}
                                onMenuClose={(e)=>{
                                  if(index == royaltyData.length - 1  || index == royaltyData.length - 2){
                                    divref.current.style.height = null;                            
                                  }
                                }}
                                isDisabled={item.status == 2}
                                isSearchable={false}
                                placeholder={"Select Status"}
                                options={Status}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    {/* <TableBody updateReddemRequest={updateReddemRequest} /> */}
                  </Table>
                </div>

                <ReactPaginate
                  previousLabel=""
                  nextLabel=""
                  breakLabel="..."
                  pageCount={pageCount}
                  marginPagesDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName="pagination justify-content-center mt-4"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLinkClassName="page-link"
                  breakClassName="page-item"
                  activeClassName="active"
                  forcePage={currentIndex}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withSnackbar(Royalty);
