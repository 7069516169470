import { LABELS } from 'Shared';

export const REFERRAL_HEADINGS = [
  LABELS.S_NO,
  LABELS.CODE,
  LABELS.CREATION_DATE,
  LABELS.EXPIRATION_DATE,
  LABELS.CLAIMED_COUNT,
  LABELS.MDOLLAR,
  LABELS.STATUS,
  LABELS.ACTION,
];
