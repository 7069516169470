export const LABELS = {
  TEAM_TOURNAMENT_WINNERS: 'Team Tournament Winners',
  TEAM_TOURNAMENT_RULES: 'Team Tournament Rules',
  NO_TEAMS_FOUND: 'No Teams Found',
  S_NO: 'S.No',
  TOURNAMENT: 'Tournament',
  ACTION: 'Action',
  TEAM_NAME: 'Team Name',
  RANK: 'Rank',
  GAME_PLAYED: 'Game Played',
  GOLD: 'Gold',
  STATUS: 'Status',
  PENDING: 'Pending',
  COMPLETED: 'Completed',
  TEAM_TOURNAMENT_DETAILS: 'Team Tournament Details',
  TOURNAMENT_NAME: 'Tournament Name',
  TOURNAMENT_WINNER_TEAMS: 'Tournament Winner Teams',
  LEADER: '(Leader)',
  GIVE_REWARD: 'Give Reward',
  NO_RECORDS_FOUND: 'No records found.',
  REWARD: 'Reward',
  GIVE: 'Give',
  REWARD_AMOUNT: 'Reward Amount',
  CASH_POINT_LOGS: 'Cash Point Logs',
  NAME: 'Name',
  CASH_POINT: 'Cash Point',
  FREE_TRIALS: 'Free Trials',
  UID: 'UID',
  EXPIRY_TIME: 'Expiry Time',
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
  ALLOW_BOTS: 'Allow Bots',
  IS_BOTS_ALLOWED: 'Is bots are allowed?',
  UPDATE: 'Update',
  USER_NAME: 'Username',
  TYPE: "Type",
  SUBSCRIPTIONS: 'Subscriptions',
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  SUBMIT: 'Submit',
  TEAMS: 'Teams',
  USER_REWARDS_HISTORY: 'User Rewards History',
  TOURNAMENT_LOGS: 'Tournament Logs',
  TOURNAMENT_MAINTENANCE: 'Tournament Maintenance',
  TRANSACTION_HISTORY: 'Transaction History',
  REFERRAL: 'Referral',
  TROPHY_REDEEM: 'Trophy Redeem',
  CASH_POINT_REDEEM_USD:"Cash Point Redeem (USD)",
  CASH_POINT_REDEEM_BTC:"Cash Point Redeem (BTC)",
  CASH_POINT_REDEEM_HKD:"Cash Point Redeem (HKD)",
  REDEEM_CASH_LIST: 'Redeem Cash List',
  USER_LIST: 'User List',
  PRIVATE_GAMES: 'Private Games',
  ROYALTY: 'Royalty',
  JACKPOT: 'Jackpots',
  JACKPOT_COUNT: 'Jackpot Count',
  JACKPOT_VALUE: 'Jackpot Value',
  BIG_JACKPOT: 'Big Jackpot',
  MEDIUM_JACKPOT: 'Medium Jackpot',
  SMALL_JACKPOT: 'Small Jackpot',
  BLACKLIST_NFT: 'Blacklist NFT',
  CREATE_NFT: 'Create NFT',
  REDEEM_NFT: 'NFT Redeem',
  REDEEM_AIRDROP: "Airdrop Redeem",
  REDEEM_CONTROL: "Redeem Control",
  NFT_LISTING: 'NFT Listing',
  ENTER_MIN_VALUE: 'Enter the min value',
  ENTER_MAX_VALUE: 'Enter the max value',
  CODE: "Code",
  CREATION_DATE: "Creation Date",
  EXPIRATION_DATE: "Expiration Date",
  CLAIMED_COUNT: "Claimed Count",
  MDOLLAR: "MDollar",
  ENTER_M_DOLLAR: "Enter the MDollar value",
  ENTER_EXPIRY_TIME: "Enter the Expiry time (in days)",
  CREATE: "CREATE",
  REFERRAL_LIST: "Referral List",
  EDIT: "Edit",
  DELETE: "Delete",
  EMAIL:"Email",
  TRANSACTION_TYPE: "Transaction Type",
  TRANSACTION_DATE: "Transaction Date",
  TRANSACTION_ID: "Transaction ID",
  CURRENCY_TYPE: "Currency Type",
  AMOUNT: "Amount",
  SUBSCRIPTION_ACTIVITIES: 'Subscription Activities',
  ORDER_ID: 'Order ID',
  SOMETHING_WENT_WRONG:"Something went wrong",
  ALLOW_REDEEM_FOR_USERS:"Allow Redeem for users?",
  ALLOW_AIRDROP_FOR_USERS:"Allow Airdrop for users?",
  USER_LOGS: "User Logs",
  DEFAULT: "Default",
  CUSTOM: "Custom",
  JACKPOT_STATUS: "Jackpot Status",
  START_TIME_GREATER_THAN_END_TIME: "Start time should be less than end time",
  SAVE : "Save",
  WON: "Won",
  LOST: "Lost",
  SHOW_MORE: "Show More",
  SHOW_LESS: "Show Less",
};

export const VAL_MESSAGES = {
  AMOUNT_CAN_NOT_NEGATIVE: "Reward Amount can't be negative values",
  AMOUNT_MAX: 'Maximum amount reached ie.99999999999',
  REQUIRED: 'Required',
  PLZ_ENTER_CORRECT_VALUES: 'Please enter correct values',
};

export const PLACEHOLDERS = {
  REWARD_AMOUNT: 'Enter reward amount',
  USERNAME_UID: 'Enter Username/UID',
  MAX: 'Max',
  MIN: 'Min',
};
