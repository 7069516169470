import { GET_MONTHLY_LOGS, GET_SUBSCRIPTIONS, GET_SUBSCRIPTION__FREE_TRIALS, GET_SUBSCRIPTIONS_USER_ACTIVITIES, GET_JACKPOT_DATA, UPDATE_JACKPOT_DATA, GET_REGISTERED_LOGS_BY_ID, GET_REGISTERED_USER_LOGS } from "./ActionTypes";

export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TOURNAMENTS_LOGS = "GET_TOURNAMENTS_LOGS";
export const GET_TOURNAMENTS_GAME_USERS = "GET_TOURNAMENTS_GAME_USERS";

export const getTransactions = ( payload,callback) => {
  return {
    type: GET_TRANSACTIONS,
    payload,
    callback,
  };
};

export const getTournamentLogs = ( payload,callback) => {
  return {
    type: GET_TOURNAMENTS_LOGS,
    payload,
    callback,
  };
};

export const getTournamentGameUsers = ( payload,callback) => {
  return {
    type: GET_TOURNAMENTS_GAME_USERS,
    payload,
    callback,
  };
};

export const getMonthlyLogs = ( payload,callback) => {
  return {
    type: GET_MONTHLY_LOGS,
    payload,
    callback,
  };
};

export const getSubscriptionFreeTrials = ( payload,callback) => {
  return {
    type: GET_SUBSCRIPTION__FREE_TRIALS,
    payload,
    callback,
  };
};

export const getSubscriptions = ( payload,callback) => {
  return {
    type: GET_SUBSCRIPTIONS,
    payload,
    callback,
  };
};

export const getUserActivities = ( payload,callback) => {
  return {
    type: GET_SUBSCRIPTIONS_USER_ACTIVITIES,
    payload,
    callback,
  };
};

export const getRegisteredUserLogs = ( payload, callback) => {
  return {
    type: GET_REGISTERED_USER_LOGS,
    payload,
    callback,
  };
};

export const getRegisteredLogsById = ( payload, callback) => {
  return {
    type: GET_REGISTERED_LOGS_BY_ID,
    payload,
    callback,
  };
};
