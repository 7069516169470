// libs
import React, { useEffect, useState } from "react";
// components
import { CustomTable, ViewUserDetailsModal } from "Components";
// hooks
import { useDispatch } from "react-redux";
// utils
import { VIEW_USER_LOGS_HEADINGS } from "Views/UserLogs/Helpers";
import { LABELS, UTILITIES } from "Shared";
import { getRegisteredLogsById } from "Redux/Actions";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { ROUTES } from "Routes/Constants";

function ViewDayUserLogsDetail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { dayId } = useParams();
  const [details, setDetails] = useState([]);
  const [viewDetailsModal, setViewDetailsModal] = useState("");

  useEffect(() => {
    if (dayId) {
      fetchDetails();
    }
  }, [dayId]);

  const fetchDetails = () => {
    dispatch(
      getRegisteredLogsById({ id: dayId }, (data, msg, type ) => {
        if(type === "error"){
          history.push(ROUTES.USER_LOGS);
        }else{
          setDetails({ data: data?.data || [], date: data?.date || "" });
        }
      })
    );
  };

  return (
    <>
      {viewDetailsModal && (
        <ViewUserDetailsModal
          isOpen={!!viewDetailsModal}
          handleToggle={() => setViewDetailsModal("")}
          currentID={viewDetailsModal}
        />
      )}

      <div className="container-fluid page-body-wrapper">
        <div className="card mt-4">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-sm-4 mb-3">
                <h4 className="h6 mb-0">{`${moment(details?.date).format("DD MMM YYYY")}'s ${
                  LABELS.USER_LOGS
                }`}</h4>
              </div>
            </div>

            <div className="row">
              {details?.data?.length ? (
                <CustomTable headings={VIEW_USER_LOGS_HEADINGS}>
                  {details?.data?.map((item) => (
                    <tr key={item?.userId}>
                      <td>{item?.createdAt ? UTILITIES.renderDate(item?.createdAt) : "--"}</td>
                      <td>{item?.userId || "--"}</td>
                      <td>{item?.userName || "--"}</td>
                      <td>{item?.email || "--"}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-primary mx-1"
                          onClick={() => setViewDetailsModal(item?.userId)}
                        >
                          {/* <img src={viewIcon} alt="view" width="20" /> */}
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </CustomTable>
              ) : (
                <p className="d-flex justify-content-center">
                  {LABELS.NO_RECORDS_FOUND}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewDayUserLogsDetail;
