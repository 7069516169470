export const UPDATE_MAINTENANCE_STATUS = "UPDATE_MAINTENANCE_STATUS";
export const FETCH_MAINTENANCE_STATUS = "FETCH_MAINTENACE_STATUS";
export const UPDATE_REDEEM_CONTROL = "UPDATE_REDEEM_CONTROL";
export const FETCH_REDEEM_CONTROL = "FETCH_REDEEM_CONTROL";

export const updateTournamentMaintenance = (payload, callback) => {
  return {
    type: UPDATE_MAINTENANCE_STATUS,
    payload,
    callback,
  };
};

export const fetchTournamentMaintenance = (callback) => {
  return {
    type: FETCH_MAINTENANCE_STATUS,
    callback,
  };
};

export const updateRedeemControl = (payload, callback) => {
  return {
    type: UPDATE_REDEEM_CONTROL,
    payload,
    callback,
  };
};

export const fetchRedeemControl = (callback) => {
  return {
    type: FETCH_REDEEM_CONTROL,
    callback,
  };
};