// saga
import { takeLatest, all, put } from 'redux-saga/effects';

// axios
import { getRequest, postRequest } from 'Shared/Axios';

// utils
import { STATUS_CODE } from '../../Shared/Constants';
import { startLoading, stopLoading } from 'Redux/Actions/LoaderAction';
import {
  GET_TEAMS,
  GET_TEAM_DETAILS,
  GET_TEAM_RULES,
  GET_TEAM_TOURNAMENT_BY_ID,
  GET_TEAM_TOURNAMENT_WINNERS,
  SEND_TEAM_WINNERS_REWARDS,
  UPDATE_TEAM_RULES,
} from 'Redux/Actions/ActionTypes';

const api = require(`../../Services/Api/Constants`);

function* getTeamsList({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: api.API_URLS.TEAM_LIST,
      params: payload,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data);
    } else {
      callback(response?.data?.message);
    }
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* getTeamsDetails({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: api.API_URLS.GET_TEAM,
      params: payload,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data);
    } else {
      callback(response?.data?.message);
    }
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* getTeamTournamentWinners({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: api.API_URLS.TEAM_TOURNAMENT_WINNERS,
      params: payload,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data);
    } else {
      callback(response?.data?.message);
    }
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* getTeamTournamentWinnerById({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: api.API_URLS.TEAM_TOURNAMENT_WINNER_BY_ID,
      params: payload,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data);
    } else {
      callback(response?.data?.message);
    }
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* sendTeamWinnerRewards({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({
      API: api.API_URLS.SEND_TEAM_WINNERS_REWARDS,
      DATA: payload,
    });
    callback(response);
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* getTeamRules({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: api.API_URLS.TEAM_RULES_LIST,
      params: payload,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data);
    } else {
      callback(response?.data?.message);
    }
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* updateTeamRules({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({
      API: api.API_URLS.TEAM_RULES_LIST,
      DATA: payload,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data);
    } else {
      callback(response?.data?.message);
    }
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}


function* TeamSaga() {
  yield all([
    takeLatest(GET_TEAMS, getTeamsList),
    takeLatest(GET_TEAM_DETAILS, getTeamsDetails),
    takeLatest(GET_TEAM_TOURNAMENT_WINNERS, getTeamTournamentWinners),
    takeLatest(GET_TEAM_TOURNAMENT_BY_ID, getTeamTournamentWinnerById),
    takeLatest(SEND_TEAM_WINNERS_REWARDS, sendTeamWinnerRewards),
    takeLatest(GET_TEAM_RULES, getTeamRules),
    takeLatest(UPDATE_TEAM_RULES, updateTeamRules),
  ]);
}

export default TeamSaga;
