export const START = "START";
export const STOP = "STOP";

export const startLoading = () => {
  // console.log("strat loading")
       return {
      type: START,
    };
  };

  export const stopLoading = () => {
    return {
   type: STOP,
 };
};