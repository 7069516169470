import { takeLatest, all, put } from "redux-saga/effects";
import { STATUS_CODE } from "../../Shared/Constants";
import { getRequest } from "Shared/Axios";
import { startLoading, stopLoading } from "Redux/Actions/LoaderAction";
import { GET_USER_REWARDS_LIST, logout } from "Redux/Actions";

const api = require(`../../Services/Api/Constants`);

function* getUsersRewardsList({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: api.API_URLS.USER_REWARD_DATA,
      params : payload
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(logout());
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data);
    }
  } catch (err) {
    console.log(err,"error");
  } finally {
    yield put(stopLoading());
  }
}

function* UserRewardsSaga() {
  yield all([
    takeLatest(GET_USER_REWARDS_LIST, getUsersRewardsList)
  ]);
}

export default UserRewardsSaga;
