// libs
import React, { useEffect, useMemo, useState } from "react";
import { Table } from "@mui/material";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
// components
import TableHeading from "../TableHeading";
import DeleteModal from "../DeleteModal";
import CustomModal from "Components/atoms/CustomModal";
import ViewModal from "../ViewModal";
import EditModal from "../EditModal";
// actions
import {
  listGame,
  listSkip,
} from "Redux/Actions/GamesAction";
// utils
import { indexHistory } from "Services/Api/Constants";
import { privateGames,privateGamesStatus } from "Shared/Constants";
// images
import TrashIcon from "assets/images/icons/trash_icon.svg";
import viewIcon from "assets/images/icons/eye_icon.svg";
import editIcon from "assets/images/icons/edit_icon.svg";

function TableList() {
  

  const gamesList = useSelector((state) => state.PrivateGame.PrivateGameList);
  const tempPageCount = useSelector((state) => state.PrivateGame.pageCount);

  const [isModalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [listStatus, setListStatus] = useState(0);
  const [currentGame, setCurrentGame] = useState(0);
  const [currentID, setCurrentID] = useState(0);
  const [dataStatus, setDataStatus] = useState(4);

  const limit = 10;

  const dispatch = useDispatch();

  const handlePageClick = (pageData) => {
    let currentPage = pageData.selected;
    setCurrentIndex(currentPage);
    const payload = {
      status: dataStatus,
      skip: currentPage * limit,
      limit: 10,
    };
    dispatch(listSkip(currentPage * limit));
    dispatch(listGame(payload, (data) => {}));
  };
  useEffect(() => {
    const payload = {
      status: dataStatus,
      limit: 10,
      skip: 0,
    };
    dispatch(listGame(payload, (data) => {}));
  }, [dataStatus]);

  const action = (status, name, id) => {
    if(status === 1) {
        return (
          <div className="d-flex">
            <button
              className="btn btn-sm btn-primary mx-1"
              onClick={() => modalShow(1, name, id)}
            >
              <img src={editIcon} alt="edit" width="20" />
            </button>
            <button
              className="btn btn-sm btn-primary mx-1"
              onClick={() => modalShow(2, name, id)}
            >
              <img src={viewIcon} alt="view" width="20" />
            </button>
            <button className="btn btn-sm btn-danger mx-1" onClick={() => modalShow(3, name, id)}>
              <img
                src={TrashIcon}
                alt="trash"
                width="20"
              />
            </button>
          </div>
        );
      }
    else{
        return (
          <div className="d-flex">
            <button className="btn btn-sm btn-primary mx-1"
              onClick={() => modalShow(2, name, id)}
            >
              <img src={viewIcon} alt="view" width="20" />
            </button>
            {/* <button className="btn btn-sm btn-danger mx-1"
              onClick={() => modalShow(3, name, id)}
            >
              <img src={TrashIcon} alt="trash" width="20" />
            </button> */}
          </div>
        );
    }
  };

  const modalShow = (status, name, id) => {
    setCurrentGame(name);
    setCurrentID(id);
    setListStatus(status);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);                                  
  };

  const showSelectedValue = (value) =>{
    switch(value){
      case "1" :
        return ({ value: 1, label: "UPCOMING GAMES"});

      case "2" :
        return ({ value: 2, label: "ONGOING GAMES" });

      case "3" :
        return ({ value: 3, label: "COMPLETED GAMES" });

      case "4" :
        return ({ value: 4, label: "ALL GAMES" });

      default :
        return  ({ value: 4, label: "ALL GAMES" });
      
    }
  }

  const modalComponent = useMemo( () => {
    if (listStatus === 1) {
     return <EditModal currentID={currentID} handleModalClose={handleModalClose} />
    } else if (listStatus === 2) {
      return <ViewModal currentID={currentID} />;
    } else {
      return(
        <DeleteModal
          currentID={currentID}
          name={currentGame}
          handleModalClose={handleModalClose}
        />
      );
    }
  },[currentGame, currentID, listStatus]);


  return (
    <>
      <div className="card mt-4">
        <div className="card-body">
        <div className="row align-items-center">
            <div className="col">
              <h4 className="h6 mb-3">Private Game List</h4>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <Select
                  options={privateGamesStatus}
                  placeholder={"Select list  Status"}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    setDataStatus(e.value)
                    
                  }}
                  value={showSelectedValue(dataStatus)}
                />
              </div>
            </div>
          </div> 

          <div className="row">
            {gamesList.length ? (
              <div className="col-md-12">
                <div className="table-responsive">
                
                  <Table className="table table-bordered">
                    <TableHeading />
                    <tbody className="table-light table-bordered">
                      {gamesList?.map((item, index) => {
                        return (
                          <tr key={item?.id}>
                            <td>{indexHistory(currentIndex) + index}</td>
                            <td>{item?.name}</td>
                            <td>{item?.code}</td>

                            <td>{moment(item.creationTime).format("L")}</td>
                            <td>{Math.trunc(item?.entryFee?.mDollar * 100)/100 || 0}</td>
                            <td>{Math.trunc(item?.entryFee?.winPoint * 100)/100 || 0}</td>
                            <td>{item?.id}</td>
                            <td>{privateGames(item?.status)}</td>
                            <td>
                              {action(item?.status, item?.name, item?.id)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>

                <ReactPaginate
                  previousLabel=""
                  nextLabel=""
                  breakLabel="..."
                  pageCount={Math.ceil(tempPageCount / 10)}
                  marginPagesDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName="pagination justify-content-center mt-4"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLinkClassName="page-link"
                  breakClassName="page-item"
                  activeClassName="active"
                />
              </div>
            ) : (
              <p>No Private Games has been created </p>
            )}
          </div>
        </div>
      </div>

      <CustomModal
        handleToggle={() => handleModalClose()}
        isOpen={isModalOpen}
        large={listStatus == 3 ? false:true}
      >
        <button className="close-btn" onClick={() => handleModalClose()}>
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17 3.5C9.54416 3.5 3.5 9.54416 3.5 17C3.5 24.4558 9.54416 30.5 17 30.5C24.4558 30.5 30.5 24.4558 30.5 17C30.5 9.54416 24.4558 3.5 17 3.5ZM0.5 17C0.5 7.8873 7.8873 0.5 17 0.5C26.1127 0.5 33.5 7.8873 33.5 17C33.5 26.1127 26.1127 33.5 17 33.5C7.8873 33.5 0.5 26.1127 0.5 17Z"
              fill="#fff"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.5607 11.4393C23.1464 12.0251 23.1464 12.9749 22.5607 13.5607L13.5607 22.5607C12.9749 23.1464 12.0251 23.1464 11.4393 22.5607C10.8536 21.9749 10.8536 21.0251 11.4393 20.4393L20.4393 11.4393C21.0251 10.8536 21.9749 10.8536 22.5607 11.4393Z"
              fill="#fff"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.4393 11.4393C12.0251 10.8536 12.9749 10.8536 13.5607 11.4393L22.5607 20.4393C23.1464 21.0251 23.1464 21.9749 22.5607 22.5607C21.9749 23.1464 21.0251 23.1464 20.4393 22.5607L11.4393 13.5607C10.8536 12.9749 10.8536 12.0251 11.4393 11.4393Z"
              fill="#fff"
            />
          </svg>
        </button>

        {modalComponent}
      </CustomModal>
    </>
  );
}

export default TableList;
