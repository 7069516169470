import {
  GET_CUSTOM_JACKPOT_DATA,
  GET_JACKPOT_DATA,
  UPDATE_CUSTOM_JACKPOT_DATA,
  UPDATE_JACKPOT_DATA,
} from "./ActionTypes";

export const getJackpotData = (callback) => {
  return {
    type: GET_JACKPOT_DATA,
    callback,
  };
};

export const updateJackpotData = (payload, callback) => {
  return {
    type: UPDATE_JACKPOT_DATA,
    payload,
    callback,
  };
};

export const getCustomJackpotData = (callback) => {
  return {
    type: GET_CUSTOM_JACKPOT_DATA,
    callback,
  };
};

export const updateCustomJackpotData = (payload, callback) => {
  return {
    type: UPDATE_CUSTOM_JACKPOT_DATA,
    payload,
    callback,
  };
};
