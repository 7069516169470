// libs
import React from "react";
import { Form, Formik } from "formik";
import { ToastContainer } from "react-toastify";
import * as Yup from "yup";
// material components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
// componets
import { TextField } from "Components";
// styles
import "react-toastify/dist/ReactToastify.css";
import "./style.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const validate = Yup.object({
  category: Yup.string().required("Required"),
  groupId: Yup.string().required("Required"),
  // groupId: Yup.object().required("Required").nullable(),
  snakeName: Yup.string().required("Required"),

  description: Yup.string()
    .trim()
    .min(1, "Cant enter white spaces in front of text")
    .max(6000, "Nice try, nobody has a title that long")
    .required("Required"),

  damage: Yup.number()
    .min(1, "mininum level will be 1")
    .required("Required"),
  speed: Yup.number()
    .min(1, "mininum level will be 1")
    .required("Required"),
  health: Yup.number()
    .min(1, "mininum level will be 1")
    .required("Required"),
  magnet: Yup.number()
    .min(1, "mininum level will be 1")
    .required("Required"),
  fireball: Yup.number()
    .min(1, "mininum level will be 1")
    // .max(11, "max level will be 11")
    .required("Required"),
  bomb: Yup.number()
    .min(1, "mininum level will be 1")
    .required("Required"),
  imageURL: Yup.string()
    .trim()
    .min(1, "Cant enter white spaces in front of text")
    .required("Required"),
  star: Yup.number()
    .min(0, "mininum level will be 0")
    .required("Required"),

  nftCount: Yup.number()
    .min(1, "mininum level will be 1")
    .required("Required"),
});


const Modal = ({ isModalOpen, selectedData, handleClose = () => { }, handleSubmit = () => { } }) => {

  return (
    <>
      <Dialog
        open={isModalOpen}
        TransitionComponent={Transition}
        keepMounted
        //  fullScreen
        onClose={() => handleClose()}
        aria-describedby="alert-dialog-slide-description"
        className={"custom_modal"}
      >

        <DialogActions>
          <Button className="btn btn-dark" onClick={() => handleClose()} >Close</Button>
        </DialogActions>
        <DialogTitle className="text-center">UPDATE NFT </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">

            <Formik
              onSubmit={(e) => {
                handleSubmit(e)
                handleClose();
              }}
              validationSchema={validate}
              enableReinitialize
              initialValues={{

                category: selectedData.category,
                metaKey: selectedData["Meta-Key"],
                groupId: selectedData.groupId,
                snakeName: selectedData.snakeName,
                description: selectedData.description || "empty",
                damage: selectedData.damage,
                speed: selectedData.speed,
                health: selectedData.health,
                magnet: selectedData.magnet,
                fireball: selectedData.fireball,
                bomb: selectedData.bomb,
                star: selectedData.star,
                nftCount: selectedData.nftToken,
                imageURL: selectedData.imageUrl,
              }}
            >
              {(props) => (
                <>

                  <Form className="update-modal">
                    <div className=" container">
                      <div className="row">
                        <div className=" col-lg-6">
                          <TextField
                            disabled="true "
                            label={"Category"}
                            name={"category"}
                            type="text"
                          />
                        </div>

                        {selectedData["Meta-Key"] !== 0 && <div className="col-lg-6">
                          <TextField
                            label={"Meta Key"}
                            name={"metaKey"}
                            type="text"
                          />
                        </div>}

                        <div className="col-lg-6">
                          <TextField
                            disabled="true "
                            label={"Group ID"}
                            name={"groupId"}
                            type="text"
                          />
                        </div>

                        <div className="col-lg-6">
                          <TextField
                            disabled="true "
                            label={"Snake name"}
                            name={"snakeName"}
                            type="text"
                          />
                        </div>

                        <div className="col-lg-6">
                          <TextField
                            label={"Description"}
                            name={"description"}
                            type="text"
                          />
                        </div>

                        <div className="col-lg-6">
                          <TextField
                            label={"Damage"}
                            name={"damage"}
                            type="text"
                            step={"0.01"}
                            min={1}
                          />
                        </div>

                        <div className="col-lg-6">
                          <TextField
                            label={"Speed"}
                            name={"speed"}
                            placeholder={"Enter speed"}
                            type="text"
                            min={1}
                            step={"0.01"}
                          />
                        </div>

                        <div className="col-lg-6">
                          <TextField
                            label={"Health"}
                            name={"health"}
                            placeholder={"Enter health"}
                            type="number"
                            step={"0.01"}
                            min={1}
                          />
                        </div>

                        <div className="col-lg-6">
                          <TextField
                            label={"Magnet"}
                            name={"magnet"}
                            placeholder={"Enter magnet"}
                            type="number"
                            min={1}
                            step={"0.01"}
                          />
                        </div>

                        <div className="col-lg-6">
                          <TextField
                            label={"Fireball"}
                            name={"fireball"}
                            placeholder={"Enter fireball"}
                            type="number"
                            min={1}
                            step={"0.01"}
                          />
                        </div>

                        <div className="col-lg-6">
                          <TextField
                            label={"Bomb"}
                            name={"bomb"}
                            placeholder={"Enter bomb"}
                            type="number"
                            min={1}
                            step={"0.01"}
                          />
                        </div>

                        <div className="col-lg-6">
                          <TextField
                            label={"Star"}
                            name={"star"}
                            placeholder={"Enter star"}
                            type="number"
                            min={1}
                            step={"0.01"}
                          />
                        </div>

                        <div className="col-lg-6">
                          <TextField
                            label={"NFT token"}
                            name={"nftCount"}
                            disabled="true "
                            placeholder={"Enter count"}
                            type="number"
                            min={1}
                          />
                        </div>

                        <div className="col-lg-12">
                          <TextField
                            label={"Image Url"}
                            name={"imageURL"}
                            placeholder={"Enter url"}
                            type="text"
                          />
                        </div>

                        <div className="col-lg-12 text-center">
                          <label className="d-none d-lg-block"> &nbsp;</label>
                          <Button
                            className="btn btn-secondary"
                            type="submit"
                          >
                            Update
                          </Button>
                        </div>

                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>

          </DialogContentText>
        </DialogContent>

      </Dialog>

      <ToastContainer />
    </>
  );
};

export default Modal;