// libs
import React, { useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { withSnackbar } from "notistack";
import * as Yup from "yup";
// components
import { Button,  IOSSwitch,  TextField } from "../../../../Components/atoms";
// utils
import { editGame, getGameData, listGame } from 'Redux/Actions/GamesAction';
import { STATUS_CODES } from 'Services/Api/Constants';



const validate = Yup.object({
  minPlayer: Yup.number().required("Enter Minimum no Player"),
  mDollar: Yup.number().required("Enter mDollar "),
  rounds: Yup.number().required("Enter no of Rounds to be Played"),
  bonusPoint: Yup.number().required("Enter Bonus Point "),
  winPoint: Yup.number().required("Enter Win Points "),
  gameTime: Yup.number().required("Enter Game Timer"),
  isCodeEntryValid : Yup.bool().oneOf([true,false], 'Please select is entry by code valid'),
  
  mDollar1: Yup.number().required("Enter mDollar Amount for Rank 1"),
  mDollar2: Yup.number().required("Enter mDollar Amount for Rank 2"),
  mDollar3: Yup.number().required("Enter mDollar Amount for Rank 3"),
  mTrophy1: Yup.number().required("Enter mTrophy Amount for Rank 1"),
  mTrophy2: Yup.number().required("Enter mTrophy Amount for Rank 2"),
  mTrophy3: Yup.number().required("Enter mTrophy Amount for Rank 3"),
  usd1: Yup.number().required("Enter USD Amount for Rank 1"),
  usd2: Yup.number().required("Enter USD Amount for Rank 2"),
  usd3: Yup.number().required("Enter USD Amount for Rank 3"),
});

function EditModal({currentID,handleModalClose,enqueueSnackbar}) {

  const dispatch = useDispatch();
  const [ tempData, setTempData] = useState([]);

  useEffect(() => {
    dispatch(
      getGameData(currentID, (data) => {
        setTempData(data?.data);
      })
    );
  }, []);


  const editGamesAction = (value) => {
    const payload = {
      currentID:currentID,
      body:{
        minPlayers: value.minPlayer,
      // creationTime: value.startTime,
      mDollar: value.mDollar,
      isCodeEntryValid : value.isCodeEntryValid,
      rewards: [
        {
          rank: 1,
          mDollar: value.mDollar1,
          mTrophy: value.mTrophy1,
          USD: value.usd1,
        },
        {
          rank: 2,
          mDollar: value.mDollar2,
          mTrophy: value.mTrophy2,
          USD: value.usd2,
        },
        {
          rank: 3,
          mDollar: value.mDollar3,
          mTrophy: value.mTrophy3,
          USD: value.usd3,
        },
      ],
      rounds: value.rounds,
      bonusPoint : value.bonusPoint,
      winPoint : value.winPoint,
      gameTime : value.gameTime
      }
      
    };
    dispatch(
      editGame(payload, (data) => { 
        enqueueSnackbar(data.data.message, {
          variant: data.status == STATUS_CODES.SUCCESS ? "success" : "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        if(data.status == STATUS_CODES.SUCCESS){
          const payload = {
            status: 1,
            limit: 10,
            skip: 0,
          };
          dispatch(listGame(payload, () => { handleModalClose();}));
        }
      })
    );

  }

  // console.log( moment.utc(tempData?.creationTime).local().format("H")," {moment.utc(tempData?.creationTime).local().f}")
    return (
        <>
        {/* <div className="modal-body">
          <div className="row text-center">
            <div className="col-md-12">
             <p>Edit Modal</p>
            </div>
          </div>
          
        </div> */}
        <Formik
      onSubmit={(value, { resetForm }) => {
        
        editGamesAction(value)
      }}
      enableReinitialize
      validationSchema={validate}
      initialValues={{
        minPlayer: tempData?.minPlayers,
        mDollar: tempData?.entryFee?.mDollar || 0,
        rounds: tempData?.rounds,
        // startTime: "22:53:05",
        mDollar1: tempData?.rewards ? tempData?.rewards[0]?.mDollar : 0,
        mDollar2: tempData?.rewards ? tempData?.rewards[1].mDollar : 0,
        mDollar3: tempData?.rewards ? tempData?.rewards[2].mDollar : 0,
        mTrophy1: tempData?.rewards ? tempData?.rewards[0].mTrophy : 0,
        mTrophy2: tempData?.rewards ? tempData?.rewards[1].mTrophy : 0,
        mTrophy3: tempData?.rewards ? tempData?.rewards[2].mTrophy : 0,
        usd1: tempData?.rewards ? tempData?.rewards[0]?.USD:0,
        usd2: tempData?.rewards ? tempData?.rewards[1]?.USD:0,
        usd3: tempData?.rewards ? tempData?.rewards[2]?.USD:0,
        bonusPoint : tempData?.bonusPoint,
        winPoint : tempData?.entryFee?.winPoint || 0,
        gameTime : tempData?.gameTime,
        isCodeEntryValid : tempData?.isCodeEntryValid || false
      }}
    >
      {(props) => (
        <>
          <Form>
            <h2 className="h4 mb-4 text-capitalize">Private Games Details</h2>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <TextField
                      label={"Min Player"}
                      name={"minPlayer"}
                      type="number"
                      min={1}
                      step="any"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <TextField
                      label={"mDollar"}
                      name={"mDollar"}
                      type="number"
                      min={0}
                      step="any"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <TextField
                      label={"No of Rounds"}
                      name={"rounds"}
                      type="number"
                      min={1}
                      step="any"
                    />
                  </div>

                  <div className="col-sm-6 col-lg-4">
                    <TextField
                      label={"Bonus Point "}
                      name={"bonusPoint"}
                      placeholder={"Enter Bonus Point"}
                      type="number"
                      min={0}
                      step="any"
                    />
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <TextField
                      label={"Win Points "}
                      name={"winPoint"}
                      placeholder={"Enter Win Points"}
                      type="number"
                      min={0}
                      step="any"
                    />
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <TextField
                      label={"Game Timer (Room Time) "}
                      name={"gameTime"}
                      placeholder={"Enter Game Time in minutes"}
                      type="number"
                      min={1}
                      max={60}
                      step="any"
                    />
                  </div>

                  <div className="col-sm-6 col-lg-4 mt-2 d-flex justify-content-between">
                     <label>{"Is Entry by Code Valid"} <sup className="text-danger">*</sup></label>
                     <div className=""> 
                       <IOSSwitch name="isCodeEntryValid" onChange={(e)=> props.setFieldValue("isCodeEntryValid",e.target.checked)} checked={props.values.isCodeEntryValid} />
                        <span className="error d-block"><ErrorMessage name={"isCodeEntryValid"} /></span>
                     </div>
                  </div>
                 

                  {/* <div className="col-md-6 col-lg-4">
                    <TextField
                      label={"Start Time "}
                      // name={"startTime"}
                      placeholder={"Enter starting time"}
                      type="time"
                    />{" "}
                  </div> */}
                </div>
              </div>
            </div>

            <div className="mt-4">
              <h3 className="h5">Rewards</h3>
              <div className="card">
                <div className="card-body">
                  <fieldset className="fieldset mb-5 mt-2">
                    <legend>Rank 1</legend>
                    <div className="row">
                      <div className="col-md-4">
                        <TextField
                          label={"mDollar"}
                          name={"mDollar1"}
                          type="number"
                          min={0}
                          placeholder={"Enter mDollar amount"}
                          step="any"
                        />
                      </div>
                      <div className="col-md-4">
                        <TextField
                          label={"mTrophy"}
                          name={"mTrophy1"}
                          type="number"
                          min={0}
                          placeholder={"Enter mDollar amount"}
                          step="any"
                        />{" "}
                      </div>
                      <div className="col-md-4">
                        <TextField
                          label={"USD amount"}
                          name={"usd1"}
                          type="number"
                          min={0}
                          placeholder={"Enter USD  amount"}
                          step="any"
                        />{" "}
                      </div>
                    </div>
                  </fieldset>

                  <fieldset className="fieldset mb-5">
                    <legend>Rank 2</legend>
                    <div className="row">
                      <div className="col-md-4">
                        <TextField
                          label={"mDollar"}
                          name={"mDollar2"}
                          type="number"
                          min={0}
                          placeholder={"Enter mDollar amount"}
                          step="any"
                        />{" "}
                      </div>
                      <div className="col-md-4">
                        <TextField
                          label={"mTrophy"}
                          name={"mTrophy2"}
                          type="number"
                          min={0}
                          placeholder={"Enter mDollar amount"}
                          step="any"
                        />{" "}
                      </div>
                      <div className="col-md-4">
                        <TextField
                          label={"USD amount"}
                          name={"usd2"}
                          type="number"
                          min={0}
                          placeholder={"Enter USD  amount"}
                          step="any"
                        />{" "}
                      </div>
                    </div>
                  </fieldset>

                  <fieldset className="fieldset mb-5">
                    <legend>Rank 3</legend>
                    <div className="row">
                      <div className="col-md-4">
                        <TextField
                          label={"mDollar"}
                          name={"mDollar3"}
                          type="number"
                          min={0}
                          placeholder={"Enter mDollar amount"}
                          step="any"
                        />{" "}
                      </div>
                      <div className="col-md-4">
                        <TextField
                          label={"mTrophy"}
                          name={"mTrophy3"}
                          type="number"
                          min={0}
                          placeholder={"Enter mDollar amount"}
                          step="any"
                        />{" "}
                      </div>
                      <div className="col-md-4">
                        <TextField
                          label={"USD amount"}
                          name={"usd3"}
                          type="number"
                          min={0}
                          placeholder={"Enter USD  amount"}
                          step="any"
                        />{" "}
                      </div>
                    </div>
                  </fieldset>

                  <div className="text-center">
                    <Button className="btn btn-md btn-secondary" type="submit">
                      Add Data
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
            
        </>
    )
}

export default React.memo(withSnackbar(EditModal)) ;
