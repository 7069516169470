import React from 'react'
import logoHeader from "assets/images/logoIcon.png";
import './style.scss'
import { useDispatch } from 'react-redux';
import { myLogout } from 'Redux/Actions/PrintTableActions';
import { Link } from 'react-router-dom';
import { DEFAULT_AUTHENTICATED_ROUTE } from 'Routes/RootRouter';


const closeMenu = () => {
    document.getElementById('root').classList.toggle('active_menu');
}

const Header = ({ heading }) => {
    const dispatch = useDispatch()


    const logoutHandler = () => {
        dispatch(myLogout());
      };
    
    return (
        <header className='header'>
            <Link className="logo" to={DEFAULT_AUTHENTICATED_ROUTE}>
                <img src={logoHeader} width="200" className="img-fluid" alt="Meta Rivals" />
            </Link >
            <div className='ms-auto'>
                <button
                    className="navbar-toggler"
                    onClick={closeMenu}
                    type="button"
                >
                    <svg viewBox="0 0 100 80" width="40" height="40">
                        <rect width="100" height="14"></rect>
                        <rect y="30" width="100" height="14"></rect>
                        <rect y="60" width="100" height="14"></rect>
                    </svg>
                </button>

                <a type="button" className="logout ms-4"
                    onClick={()=>logoutHandler()}
                    title='Logout'
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="25.613" height="25.613" viewBox="0 0 25.613 25.613">
                        <g id="logout" transform="translate(0.225 0.225)">
                            <path id="Path" d="M12.339-29.9H3.085a1.039,1.039,0,0,0-1.028,1.048V-9.983A1.039,1.039,0,0,0,3.085-8.934h9.255a1.038,1.038,0,0,1,1.028,1.048,1.038,1.038,0,0,1-1.028,1.049H3.085A3.119,3.119,0,0,1,0-9.983V-28.855A3.119,3.119,0,0,1,3.085-32h9.255a1.038,1.038,0,0,1,1.028,1.048A1.038,1.038,0,0,1,12.339-29.9Z" transform="translate(0 32)" fill="#fff" stroke="#fff" strokeWidth="0.45" fillRule="evenodd" />
                            <path id="Path-2" data-name="Path" d="M27.207-17.2l-6.244,6.066a1.038,1.038,0,0,1-1.452-.01,1,1,0,0,1,.01-1.43l4.462-4.335H12.027A1.018,1.018,0,0,1,11-17.923a1.018,1.018,0,0,1,1.027-1.011H23.983l-4.462-4.335a1,1,0,0,1-.01-1.43,1.035,1.035,0,0,1,.731-.3,1.03,1.03,0,0,1,.721.291l6.244,6.066a1,1,0,0,1,.306.72A1,1,0,0,1,27.207-17.2Z" transform="translate(-2.35 30.504)" fill="#fff" stroke="#fff" strokeWidth="0.45" fillRule="evenodd" />
                        </g>
                    </svg>
                </a>
            </div>
        </header>
    )
}

export default Header
