// libs
import React, { useEffect, useState } from 'react';
// hooks
import { useDispatch } from 'react-redux';
// components
import ViewTeamsDetailModal from './Components/ViewTeamsDetailModal';
import { CustomTable } from 'Components';
// utils
import viewIcon from 'assets/images/icons/eye_icon.svg';
import { indexHistory } from 'Services/Api/Constants';
import { TEAM_HEADINGS } from './Helpers';
// actions
import { getTeamList } from 'Redux/Actions/Team';
import { LABELS } from 'Shared';

// constants
const limit = 100;

function Teams() {
  // hooks
  const dispatch = useDispatch();

  // states
  const [isModalOpen, setIsModalOpen] = useState('');
  const [listData, setListData] = useState([]);

  useEffect(() => {
    fetchListData();
  }, []);

  const fetchListData = () => {
    const payload = {
      limit: limit,
      skip: 0,
    };
    dispatch(
      getTeamList(payload, (data) => {
        setListData(data?.data);
      })
    );
  };

  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <div className="card mt-4">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-sm-4 mb-3">
                <h4 className="h6 mb-0">{LABELS.TEAMS}</h4>
              </div>
            </div>

            <div className="row">
              {listData?.length ? (
                <div className="col-md-12">
                  <CustomTable headings={TEAM_HEADINGS}>
                    {listData?.map((item, index) => {
                      return (
                        <tr key={item?.teamId}>
                          {/* <td>{indexHistory(0) + index}</td> */}
                          <td>{item?.teamName || ''}</td>
                          <td>{item?.rank || ''}</td>
                          <td>{item?.totalGamePlayed || 0}</td>
                          <td>{item?.totalTeamWin || 0}</td>
                          <td>{item?.totalGold || 0}</td>
                          {/* <td>{item?.isTournamentStarted ? 'Started' : 'Not Started'}</td> */}
                          <td>
                            <button
                              className="btn btn-sm btn-primary mx-1"
                              onClick={() => setIsModalOpen(item?.teamId)}
                            >
                              <img src={viewIcon} alt="view" width="20" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </CustomTable>
                </div>
              ) : (
                <p className='d-flex justify-content-center'>{LABELS.NO_TEAMS_FOUND}</p>
              )}
            </div>
          </div>
        </div>

        <ViewTeamsDetailModal
          isOpen={!!isModalOpen}
          handleModalClose={() => setIsModalOpen('')}
          currentTeamId={isModalOpen}
        />
      </div>
    </>
  );
}

export default Teams;
