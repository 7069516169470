export const NFT_UPDATE = "NFT_UPDATE";
export const CREATE_REFERRAL = "CREATE_REFERRAL";
export const DELETE_REFERRAL = "DELETE_REFERRAL";
export const EDIT_REFERRAL = "EDIT_REFERRAL";
export const GET_REFERRAL = "GET_REFERRAL";

export const getReferrals = ( payload,callback) => {
  return {
    type: GET_REFERRAL,
    payload,
    callback,
  };
};

export const createReferral = (payload,callback) => {
    return {
     type: CREATE_REFERRAL,
     payload,
     callback
   };
 };

export const deleteReferral = (payload,callback) => {
    return {
     type: DELETE_REFERRAL,
     payload,
     callback
   };
 };

export const editReferral = (payload,callback) => {
    return {
     type: EDIT_REFERRAL,
     payload,
     callback
   };
 };
