import { GET_TEAMS, GET_TEAM_DETAILS, GET_TEAM_RULES, GET_TEAM_TOURNAMENT_BY_ID, GET_TEAM_TOURNAMENT_WINNERS, SEND_TEAM_WINNERS_REWARDS, UPDATE_TEAM_RULES } from './ActionTypes';

export const getTeamList = (payload, callback) => {
  return {
    type: GET_TEAMS,
    payload,
    callback,
  };
};

export const getTeamDetails = (payload, callback) => {
  return {
    type: GET_TEAM_DETAILS,
    payload,
    callback,
  };
};

export const getTeamTournamentWinners = (payload, callback) => {
  return {
    type: GET_TEAM_TOURNAMENT_WINNERS,
    payload,
    callback,
  };
};

export const getTeamTournamentWinnerById = (payload, callback) => {
  return {
    type: GET_TEAM_TOURNAMENT_BY_ID,
    payload,
    callback,
  };
};

export const sendTeamWinnerRewards = (payload, callback) => {
  return {
    type: SEND_TEAM_WINNERS_REWARDS,
    payload,
    callback,
  };
};

export const getTeamRules = (payload, callback) => {
  return {
    type: GET_TEAM_RULES,
    payload,
    callback,
  };
};

export const updateTeamRules = (payload, callback) => {
  return {
    type: UPDATE_TEAM_RULES,
    payload,
    callback,
  };
};
