const TableHeading = () => {
  return (
    <>
      <thead className="bg-dark text-white align-content-center">
        <tr>
          <th>S.no</th>
          <th>Category</th>
          <th>Meta Key</th>
          <th>GroupId</th>
          <th>Snake Name</th>
          <th>Descripiton</th>
          <th>Damage</th>
          <th>Speed</th>
          <th>Health</th>
          <th>Magnet</th>
          <th>FireBall</th>
          <th>Bomb</th>
          <th>Star</th>
          <th>Nft Token</th>
          <th>Image URl</th>
          <th>Burn Status</th>
          <th>Action</th>
        </tr>
      </thead>
    </>
  );
};

export default TableHeading;
