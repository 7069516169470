import React from "react";
import { JackpotType } from "Components";
import { JACKPOT_FIELDS } from "Views/Jackpot/helpers";

const BigMediumSmallJackpot = ({  handleChange = () => {}, values = {}, errors = {} }) => {
  return (
    <>
      {" "}
      <JackpotType
        subHeading={JACKPOT_FIELDS.BIG_JACKPOT.label}
        //count
        countHeading={JACKPOT_FIELDS.BIG_JACKPOT.COUNT.label}
        initialCount={values?.[JACKPOT_FIELDS.BIG_JACKPOT.COUNT.name]}
        countName={JACKPOT_FIELDS.BIG_JACKPOT.COUNT.name}
        countError={errors?.[JACKPOT_FIELDS.BIG_JACKPOT.COUNT.name]}
        // value
        valueHeading={JACKPOT_FIELDS.BIG_JACKPOT.VALUE.label}
        initialValue={values?.[JACKPOT_FIELDS.BIG_JACKPOT.VALUE.name]}
        valueName={JACKPOT_FIELDS.BIG_JACKPOT.VALUE.name}
        valueError={errors?.[JACKPOT_FIELDS.BIG_JACKPOT.VALUE.name]}

        handleChange={handleChange}
      />

      <JackpotType
        subHeading={JACKPOT_FIELDS.MEDIUM_JACKPOT.label}
        //count
        countHeading={JACKPOT_FIELDS.MEDIUM_JACKPOT.COUNT.label}
        initialCount={values?.[JACKPOT_FIELDS.MEDIUM_JACKPOT.COUNT.name]}
        countName={JACKPOT_FIELDS.MEDIUM_JACKPOT.COUNT.name}
        countError={errors?.[JACKPOT_FIELDS.MEDIUM_JACKPOT.COUNT.name]}
        // value
        valueHeading={JACKPOT_FIELDS.MEDIUM_JACKPOT.VALUE.label}
        initialValue={values?.[JACKPOT_FIELDS.MEDIUM_JACKPOT.VALUE.name]}
        valueName={JACKPOT_FIELDS.MEDIUM_JACKPOT.VALUE.name}
        valueError={errors?.[JACKPOT_FIELDS.MEDIUM_JACKPOT.VALUE.name]}

        handleChange={handleChange}
      />

      <JackpotType
        subHeading={JACKPOT_FIELDS.SMALL_JACKPOT.label}
        //count
        countHeading={JACKPOT_FIELDS.SMALL_JACKPOT.COUNT.label}
        initialCount={values?.[JACKPOT_FIELDS.SMALL_JACKPOT.COUNT.name]}
        countName={JACKPOT_FIELDS.SMALL_JACKPOT.COUNT.name}
        countError={errors?.[JACKPOT_FIELDS.SMALL_JACKPOT.COUNT.name]}
        // value
        valueHeading={JACKPOT_FIELDS.SMALL_JACKPOT.VALUE.label}
        initialValue={values?.[JACKPOT_FIELDS.SMALL_JACKPOT.VALUE.name]}
        valueName={JACKPOT_FIELDS.SMALL_JACKPOT.VALUE.name}
        valueError={errors?.[JACKPOT_FIELDS.SMALL_JACKPOT.VALUE.name]}

        handleChange={handleChange}
      />
    </>
  );
};

export default BigMediumSmallJackpot;
