// libs
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import moment from "moment";
import { useSnackbar } from "notistack";
// components
import { CustomTable } from "Components";
// actions
import {
  getRedeemRequests,
  putReddeemRequest,
} from "Redux/Actions/PrintTableActions";
// utils
import { GetDefaultValue, indexHistory, Status } from "Services/Api/Constants";
import { LABELS, REDEEM_TYPE } from "Shared";

const CashPointRedeem = ({ type = "", heading = "" }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [requestsData, setRequestsData] = useState([]);
  const [search, setSearch] = useState("");
  const divref = useRef(null);
  const limit = 10;

  const updateReddemRequest = (selected, id) => {
    if (selected.value == 1) {
      return;
    }
    const payload = id;

    dispatch(
      putReddeemRequest(payload, (data) => {
        enqueueSnackbar(data.data.message, {
          variant: data.status ? "success" : "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        const payload = {
          type: type,
          skip: 0,
          limit: 10,
          searchKey: "",
        };
        dispatch(
          getRedeemRequests(payload, (data) => {
            setRequestsData(data?.data);
          })
        );
      })
    );
  };

  const handlePageClick = (pageData) => {
    let currentPage = pageData.selected;
    setCurrentIndex(currentPage);
    const payload = {
      type: type,
      skip: currentPage * limit,
      limit: 10,
      searchKey: search,
    };
    dispatch(
      getRedeemRequests(payload, (data) => {
        setRequestsData(data?.data);
      })
    );
  };

  useEffect(() => {
    const payload = {
      type: type,
      skip: 0,
      limit: 10,
      searchKey: search,
    };
    dispatch(
      getRedeemRequests(payload, (data) => {
        let tempPageCount = Math.ceil(data?.count / 10);
        setPageCount(tempPageCount);
        setRequestsData(data?.data);
      })
    );
  }, []);

  const OnSubmit = () => {
    const payload = {
      limit: 10,
      skip: 0,
      type: type,
      searchKey: search,
    };
    dispatch(
      getRedeemRequests(payload, (data) => {
        let tempPageCount = Math.ceil(data?.count / 10);
        setRequestsData(data?.data);
        setPageCount(tempPageCount);
        setCurrentIndex(0);
      })
    );
  };

  // Table Height
  const getHeight = () => {
    let divElement = document.querySelector(".table-responsive");
    let height = divElement.offsetHeight;
    divElement.style.height = height + 82 + "px";
  };

  const getActiveCurrency = (type) => {
    switch (type) {
      case REDEEM_TYPE.CASH_FROM_TROPHY:
        return "USD";
      case REDEEM_TYPE.BITCOIN_FROM_TROPHY:
        return "BTC";
      case REDEEM_TYPE.HKD_FROM_TROPHY:
        return "HKD";
      default:
        return "";
    }
  };

  const tableHeadings = useMemo(() => {
    return [
      "S.no",
      "Creation Date",
      "Name",
      "Phone Number",
      "Email",
      "Country",
      getActiveCurrency(type),
      "UID",
      "Paypal Id / Wallet Address",
      "Status",
    ];
  },[type] );

  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-md-4">
                <h4 className="h6 mb-3">{heading}</h4>
              </div>
              <div className="col-md-8 mb-3">
                <div className="search_bar input-group ms-auto">
                  <input
                    type="text"
                    placeholder="Enter email/name"
                    className="form-control"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  <div className="input-group-prepend">
                    <button
                      className="btn btn-md btn-primary"
                      onClick={() => OnSubmit()}
                    >
                      {LABELS.SUBMIT}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <CustomTable
              ref={divref}
              headings={tableHeadings}
              pageCount={pageCount}
              marginPagesDisplayed={3}
              currentIndex={currentIndex}
              handlePageClick={handlePageClick}
              isPagination
            >
              {requestsData?.map((item, index) => {
                return (
                  <tr key={item?.id}>
                    <td>{indexHistory(currentIndex) + index}</td>
                    <td>{moment(item.createdAt).format("L")}</td>
                    <td>{item?.userName}</td>
                    <td>{item?.mobileNumber || "--"}</td>
                    <td>{item?.email}</td>
                    <td>{item?.country || "--"}</td>
                    <td>{item?.amount}</td>
                    <td>{item?.userId}</td>
                    <td>{item?.paypalId}</td>
                    <td>
                      <Select
                        // isDisabled={true}
                        isSearchable={false}
                        defaultValue={() => GetDefaultValue(item?.status)}
                        name={"groupId"}
                        onMenuOpen={(e) => {
                          if (
                            index == requestsData.length - 1 ||
                            index == requestsData.length - 2
                          ) {
                            getHeight();
                          }
                        }}
                        onMenuClose={(e) => {
                          if (
                            index == requestsData.length - 1 ||
                            index == requestsData.length - 2
                          ) {
                            divref.current.style.height = null;
                          }
                        }}
                        onChange={(e) => {
                          updateReddemRequest(e, item?.id);
                        }}
                        isDisabled={item?.status == 2}
                        placeholder={"Select Status"}
                        options={Status}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        // menuIsOpen={true}
                      />
                    </td>
                  </tr>
                );
              })}
            </CustomTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default CashPointRedeem;
