// libs
import React, { useEffect, useState } from "react";
// hooks
import { useDispatch } from "react-redux";
// components
import { CustomTable } from "Components";
// utils
import viewIcon from "assets/images/icons/eye_icon.svg";
import { indexHistory } from "Services/Api/Constants";
import { USER_LOGS_HEADINGS } from "./Helpers";
import { LABELS, UTILITIES } from "Shared";
// actions
import { getRegisteredUserLogs } from "Redux/Actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "Routes/Constants";

// constants
const limit = 10;

function UserLogs() {
  // hooks
  const dispatch = useDispatch();
  const history = useHistory();

  // states
  const [listData, setListData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    fetchListData();
  }, []);

  const fetchListData = (pageNo = 0) => {
    const payload = {
      limit: limit,
      skip: pageNo * limit,
    };
    dispatch(
      getRegisteredUserLogs(payload, (data) => {
        setListData(data?.data);
        setPageCount(Math.ceil(data?.count / limit));
        if (typeof pageNo === "number") {
          setCurrentIndex(pageNo);
        }
      })
    );
  };

  const handlePageClick = (pageData) => {
    let currentPage = pageData.selected;
    fetchListData(currentPage);
  };

  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <div className="card mt-4">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-sm-4 mb-3">
                <h4 className="h6 mb-0">{LABELS.USER_LOGS}</h4>
              </div>
            </div>

            <div className="row">
              {listData?.length ? (
                <div className="col-md-12">
                  <CustomTable
                    headings={USER_LOGS_HEADINGS}
                    pageCount={pageCount}
                    currentIndex={currentIndex}
                    handlePageClick={handlePageClick}
                    isPagination
                  >
                    {listData?.map((item, index) => {
                      return (
                        <tr key={item?.id}>
                          <td>{indexHistory(0) + index}</td>
                          <td>
                            {item?.date
                              ? UTILITIES.renderDate(item?.date)
                              : "--"}
                          </td>
                          <td>{item?.usersCount || 0}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-primary mx-1"
                              onClick={() =>
                                history.push(`${ROUTES.USER_LOGS}/${item?.id}`)
                              }
                            >
                              <img src={viewIcon} alt="view" width="20" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </CustomTable>
                </div>
              ) : (
                <p className="d-flex justify-content-center">
                  {LABELS.NO_RECORDS_FOUND}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserLogs;
