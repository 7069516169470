export const NFT_UPDATE = "NFT_UPDATE";
export const RESTRICTION_RANGE = "RESTRICTION_RANGE";
export const PREVIOUS_RANGE = "PREVIOUS_RANGE";

export const previousRange = ( callback) => {
  console.log("inside the nft range")

  return {
    type: PREVIOUS_RANGE,
    callback,
  };
};

export const nftRange = (payload,callback) => {
    return {
     type: RESTRICTION_RANGE,
     payload,
     callback
   };
 };


export const nftUpdate = (payload,callback) => {
   return {
    type: NFT_UPDATE,
    payload,
    callback
  };
};






