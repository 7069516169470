export const CREATE_GAMES = "CREATE_GAMES";
export const GAMES_LISTING = "GAMES_LISTING";
export const EDIT_GAMES = "EDIT_GAMES";
export const DELETE_GAMES = "DELETE_GAMES";
export const GET_GAME_DATA = "GET_GAME_DATA";
export const MODIFY_LIST = "MODIFY_LIST";
export const LIST_STATUS = "LIST_STATUS";
export const LIST_SKIP = "LIST_SKIP";
export const PAGE_COUNT = "PAGE_COUNT";
export const USER_LIST = "USER_LIST";
export const USER_DETAIL = "USER_DETAIL";


export const userDetails = (payload,callback) => {
  return {
    type: USER_DETAIL,
    payload,
    callback
  };
};

export const userList = (payload,callback) => {
  return {
    type: USER_LIST,
    payload,
    callback
  };
};

export const pageCount = (payload) => {
  return {
    type: PAGE_COUNT,
    payload
  };
};

export const modifyList = (payload) => {
  return {
    type: MODIFY_LIST,
    payload
  };
};

export const listStatus = (payload) => {
  return {
    type: LIST_STATUS,
    payload
  };
}

export const listSkip = (payload) => {
  return {
    type: LIST_SKIP,
    payload
  };
}
export const getGameData = (payload,callback) => {
  return {
    type: GET_GAME_DATA,
    payload,
    callback
  };
};

export const deleteGame = (payload,callback) => {
  return {
    type: DELETE_GAMES,
    payload,
    callback
  };
};

export const createGame = (payload,callback) => {
  return {
    type: CREATE_GAMES,
    payload,
    callback
  };
};

export const editGame = (payload,callback) => {
    return {
      type: EDIT_GAMES,
      payload,
      callback
    };
  };


export const listGame = (payload,callback) => {
    return {
      type: GAMES_LISTING,
      payload,
      callback
    };
  };



