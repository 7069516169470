import { takeLatest, all, put } from "redux-saga/effects";
import { STATUS_CODE } from "../../Shared/Constants";
import {
  GET_FORM_DATA,
  POST_FORM_DATA,
  MY_LOGOUT,
  UPLOAD_FILE,
  GET_GROUP_ID,
  GET_LISTING_DETAILS,
  PUT_REDDEEM_REQUEST,
  UPDATE_FORM_DATA,
  GET_DOWNLOADS,
  FILE_UPLOAD,
  GET_AIRDROP_DETAILS,
} from "Redux/Actions/ActionTypes";
import {
  getRequest,
  postRequest,
  putRequest,
  postRequestNoAuth,
} from "Shared/Axios";
import { MY_LOGIN } from "Redux/Actions/Auth";
import {
  setAuthToken,
  setFormData,
  removeToken,
  setGroupIdsData,
  setListingDetails,
  CATEGORY,
  GET_ROYALTY,
  USER_STATS,
  GET_REDEEM_REQUESTS,
} from "Redux/Actions/PrintTableActions";
import { startLoading, stopLoading } from "Redux/Actions/LoaderAction";
import { API_URLS } from "Services/Api/Constants";

function* postFormData({ payload, successCallBack }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({
      API: `${API_URLS.POST_FORM_DATA}`,
      DATA: payload,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      // yield put(logoutUser());
    } else if (response.status !== STATUS_CODE.successful) {
      successCallBack(response?.data?.message);
    } else {
      successCallBack(response?.data?.message);
    }
  } catch (err) {
  } finally {
    yield put(stopLoading());

}
}

function* myLogin({ payload ,callback= () => {} }) {
  try {
    yield put(startLoading());
    const response = yield postRequestNoAuth({
      API: `${API_URLS.LOGIN}`,
      DATA: payload,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      // yield put(logoutUser());
    } else if (response.status !== STATUS_CODE.successful) {
      callback(response.data);
    } else {
      callback(response.data);
      yield put(setAuthToken(response?.data?.token));
    }
  } catch (err) {
    callback(err.data);
    console.log(err,"error");
  } finally {
    yield put(stopLoading());
  }
}

function* getFormData({ skip, limit, startIndex, endIndex, minted }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: `${API_URLS.GET_FORM_DATA}?skip=${skip}&limit=${limit}${
        startIndex !== "" ? "&start=" + startIndex : ""
      }${endIndex !== "" ? "&end=" + endIndex : ""}${
        minted !== "" ? "&status=" + minted : ""
      }`,
    });
    if (response.status == STATUS_CODE.successful) {
      yield put(setFormData(response?.data));
    }
  } catch (err) {
  } finally {
    yield put(stopLoading());
  }
}

function* updateFormData({ payload, successCallBack }) {
  try {
    yield put(startLoading());
    const response = yield putRequest({
      API: `${API_URLS.UPDATE_FORM_DATA}?tokenId=${payload.tokenId}`,
      DATA: payload,
    });
    if (response.status == STATUS_CODE.successful) {
      successCallBack(response?.data?.message);
    }
  } catch (err) {
  } finally {
    yield put(stopLoading());
  }
}

function* uploadImages({ payload, setImageCallBack }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({
      API: `${API_URLS.UPLOAD_IMAGE}`,
      DATA: payload,
    });
    if (response.status == STATUS_CODE.successful) {
      setImageCallBack(response.data.fileUrl);
    }
  } catch (err) {
  } finally {
    yield put(stopLoading());
  }
}

function* getGroupIdsData({ payload }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: `${API_URLS.GET_GROUP_IDS}?categoryId=${payload}`,
    });
    if (response.status == STATUS_CODE.successful) {
      yield put(setGroupIdsData(response?.data.dropDownData));
    }
  } catch (err) {
  } finally {
    yield put(stopLoading());
  }
}

function* getAirdropDetails({ skip, limit }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: `${API_URLS.GET_REEDEM_REQUEST}?reedemType=6&skip=${skip}&limit=${limit}`,
    });
    if (response.status == STATUS_CODE.successful) {
      yield put(setListingDetails(response?.data));
    }
  } catch (err) {
  } finally {
    yield put(stopLoading());
  }
}

function* putReddeemRequestData({ payload, callBackFn }) {
  try {
    yield put(startLoading());
    const response = yield putRequest({
      API: `${API_URLS.PUT_REEDEM_REQUEST}?requestId=${payload}`,
    });

    if (response.status == STATUS_CODE.successful) {
      callBackFn(response);
    }
  } catch (err) {
  } finally {
    yield put(stopLoading());
  }
}

function* getDownloadFn({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: `${API_URLS.GET_DOWNLOADS}?startIndex=${payload.startIndex}&endIndex=${payload.endIndex}`,
    });
    if (response.status == STATUS_CODE.successful) {
      callback(response?.data?.data);
    }
  } catch (err) {
  } finally {
    yield put(stopLoading());
  }
}

function* fileUpload({ payload, successCallBack }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({
      API: `${API_URLS.POST_FILEUPLOAD}`,
      DATA: payload,
    });
    if (response.status == STATUS_CODE.successful) {
      successCallBack(response.data.message);
    }
  } catch (err) {
  } finally {
    yield put(stopLoading());
  }
}

function* logoutFn() {
  const emptyString = "";
  yield put(removeToken(emptyString));
}

function* get_snake_category({ callback }) {
  try {
    yield put(startLoading());
  
    const { data = {} } = yield getRequest({
      API: `${API_URLS.CATEGORY}`,
    });
    callback(data);
  } catch (error) {
    callback(JSON.stringify(error));
  } finally {
    yield put(stopLoading());
  }
}

function* get_royality_data({ payload, callback }) {
  try {
    yield put(startLoading());
    const { data = {} } = yield getRequest({
      API: `${API_URLS.GET_REEDEM_REQUEST}?reedemType=${payload.type}&skip=${payload.skip}&limit=${payload.limit}&searchKey=${payload.searchKey}`,
    });
    callback(data);
  } catch (error) {
    callback(JSON.stringify(error));
  } finally {
     yield put(stopLoading());
  }
}

function* get_user_stats({ callback }) {
  try {
    yield put(startLoading());
    const { data = {} } = yield getRequest({
      API: API_URLS.GET_OVERALL_STATS,
    });
    callback(data);
  } catch (error) {
    callback(JSON.stringify(error));
  } finally {
     yield put(stopLoading());
  }
}

function* get_redeem_requests({ payload, callback }) {
  try {
    yield put(startLoading());
    const { data = {} } = yield getRequest({
      API: `${API_URLS.GET_REEDEM_REQUEST}?reedemType=${payload.type}&skip=${payload.skip}&limit=${payload.limit}&searchKey=${payload.searchKey}`,
    });
    console.log('data: ', data);
    callback(data);
  } catch (error) {
    callback(JSON.stringify(error));
  } finally {
     yield put(stopLoading());
  }
}

function* getListingDetails({ skip, limit }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: `${API_URLS.GET_REEDEM_REQUEST}?reedemType=1&skip=${skip}&limit=${limit}`,
    });
    if (response.status == STATUS_CODE.successful) {
      yield put(setListingDetails(response?.data));
    }
  } catch (err) {
  } finally {
    yield put(stopLoading());
  }
}

 function* PrintTable() {
  yield all([
    takeLatest(POST_FORM_DATA, postFormData),
    takeLatest(MY_LOGIN, myLogin),
    takeLatest(GET_FORM_DATA, getFormData),
    takeLatest(UPDATE_FORM_DATA, updateFormData),
    takeLatest(MY_LOGOUT, logoutFn),
    takeLatest(UPLOAD_FILE, uploadImages),
    takeLatest(GET_GROUP_ID, getGroupIdsData),
    takeLatest(GET_LISTING_DETAILS, getListingDetails),
    takeLatest(GET_AIRDROP_DETAILS, getAirdropDetails),
    takeLatest(PUT_REDDEEM_REQUEST, putReddeemRequestData),
    takeLatest(GET_DOWNLOADS, getDownloadFn),
    takeLatest(FILE_UPLOAD, fileUpload),
    takeLatest(CATEGORY, get_snake_category),
    takeLatest(GET_ROYALTY, get_royality_data),
    takeLatest(USER_STATS, get_user_stats),
    takeLatest(GET_REDEEM_REQUESTS,get_redeem_requests)


  ]);
}

export  default PrintTable;