import React, { useState } from 'react';
import "./style.scss";
import { LABELS } from 'Shared';

const ReadMore = ({ children, maxCharacterCount = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpansion = () => setIsExpanded(!isExpanded);

  const text = children;

  if (text.length <= maxCharacterCount) {
    // If the text length is less than or equal to the maxCharacterCount, display the full text
    return text;
  }

  return (
    <div className='d-flex align-items-center flex-wrap'>
      {isExpanded ? text : `${text.substring(0, maxCharacterCount)}...`}
      <span className="cursor-pointer text-primary d-flex align-items-center" onClick={toggleExpansion}>
        {isExpanded ? LABELS.SHOW_LESS : LABELS.SHOW_MORE}
      </span>
    </div>
  );
};

export default ReadMore;
