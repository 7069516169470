import { LOGIN } from "../Actions/Auth";
import { ACTION_STATES } from "../Actions/ActionStates";
import { ACTIVE_SCREEN } from "Redux/Actions/Admin";

const initialState = {
  token: null,
  activeScreen:1,

};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN + ACTION_STATES.SUCCESS: {
      return {
        ...state,
        token: action.token,
      };
    }
    case ACTIVE_SCREEN:
      return {
        ...state,
        activeScreen :action.payload
      };
    default: {
      return state;
    }
  }
};

export default authReducer;
