import {
  POST_FORM_DATA,
  SET_AUTH_TOKEN,
  MY_LOGIN,
  GET_FORM_DATA,
  SET_TABLE_DATA,
  DELETE_FORM_DATA,
  MY_LOGOUT,
  REMOVE_TOKEN,
  UPLOAD_FILE,
  GET_GROUP_ID,
  SET_GROUP_IDS,
  GET_LISTING_DETAILS,
  SET_LISTING_DETAILS,
  PUT_REDDEEM_REQUEST,
  UPDATE_FORM_DATA,
  GET_DOWNLOADS,
  FILE_UPLOAD,
  GET_AIRDROP_DETAILS,
} from "./ActionTypes";

export const CATEGORY = "CATEGORY";
export const GET_ROYALTY = "GET_ROYALTY";
export const USER_STATS = "USER_STATS";
export const GET_REDEEM_REQUESTS = "GET_REDEEM_REQUESTS";


export const user_stats = (callback) => {
  return {
    type: USER_STATS,
    callback,
  };
};

export const royality_Data = (payload, callback) => {
  return {
    type: GET_ROYALTY,
    payload,
    callback,
  };
};
export const postFormData = (payload, successCallBack) => {
  return {
    type: POST_FORM_DATA,
    payload: payload,
    successCallBack: successCallBack,
  };
};

//update

export const putFormData = (payload, successCallBack) => {
  return {
    type: UPDATE_FORM_DATA,
    payload: payload,
    successCallBack: successCallBack,
  };
};

export const setAuthToken = (payload) => {
  return {
    type: SET_AUTH_TOKEN,
    payload: payload,
  };
};

export const myLogin = (payload,callback) => {
  return {
    type: MY_LOGIN,
    payload: payload,
    callback
  };
};

export const myLogout = () => {
  return {
    type: MY_LOGOUT,
  };
};

export const getFormData = (skip, limit, startIndex, endIndex, minted) => {
  return {
    type: GET_FORM_DATA,
    skip: skip,
    limit: limit,
    startIndex: startIndex,
    endIndex: endIndex,
    minted: minted,
  };
};

export const setFormData = (data) => {
  return {
    type: SET_TABLE_DATA,
    payload: data,
  };
};

export const deleteFormData = (data, successCallBack) => {
  return {
    type: DELETE_FORM_DATA,
    payload: data,
    successCallBack: successCallBack,
  };
};

export const removeToken = (val) => {
  return {
    type: REMOVE_TOKEN,
    payload: val,
  };
};

export const uploadFileImage = (payload, setImageCallBack) => {
  return {
    type: UPLOAD_FILE,
    payload: payload,
    setImageCallBack: setImageCallBack,
  };
};

export const uploadFile = (payload, successCallBack) => {
  return {
    type: FILE_UPLOAD,
    payload: payload,
    successCallBack: successCallBack,
  };
};

export const getGroupIds = (payload) => {
  return {
    type: GET_GROUP_ID,
    payload: payload,
  };
};

export const setGroupIdsData = (data) => {
  return {
    type: SET_GROUP_IDS,
    payload: data,
  };
};

export const getListingDetails = (skip, limit) => {
  return {
    type: GET_LISTING_DETAILS,
    skip: skip,
    limit: limit,
  };
};
export const getAirdropDetails = (skip, limit) => {
  return {
    type: GET_AIRDROP_DETAILS,
    skip: skip,
    limit: limit,
  };
};

export const setListingDetails = (data) => {
  return {
    type: SET_LISTING_DETAILS,
    payload: data,
  };
};

export const putReddeemRequest = (payload, callBackFunction) => {
  return {
    type: PUT_REDDEEM_REQUEST,
    payload: payload,
    callBackFn: callBackFunction,
  };
};

export const GetDownloadsFiles = (payload, callback) => {
  return {
    type: GET_DOWNLOADS,
    payload: payload,
    callback: callback,
  };
};

export const setDownloadFileUrl = (payload) => {
  return {
    type: "SET_DOWNLOAD_URL",
    payload: payload,
  };
};

export const snakeCategory = (callback) => {
  return {
    type: CATEGORY,
    callback,
  };
};

//redeemCashFromTrophy
export const getRedeemRequests = (payload, callback) => {
  return {
    type: GET_REDEEM_REQUESTS,
    payload,
    callback,
  };
};

