
//PrintTAble 
export const POST_FORM_DATA = "POST_FORM_DATA";
export const SET_TABLE_DATA = "SET_TABLE_DATA";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const MY_LOGIN = "MY_LOGIN";
export const GET_FORM_DATA = "GET_FORM_DATA";
export const DELETE_FORM_DATA = "DELETE_FORM_DATA";

export const UPDATE_FORM_DATA = "UPDATE_FORM_DATA"
export const MY_LOGOUT = "MY_LOGOUT";
export const REMOVE_TOKEN = "REMOVE_TOKEN"
export const UPLOAD_FILE = "UPLOAD_FILE"
export const FILE_UPLOAD = "FILE_UPLOAD"
export const GET_GROUP_ID = "GET_GROUP_ID"
export const SET_GROUP_IDS = "SET_GROUP_IDS"
export const GET_LISTING_DETAILS = "GET_LISTING_DETAILS"
export const SET_LISTING_DETAILS = "SET_LISTING_DETAILS"
export const PUT_REDDEEM_REQUEST = "PUT_REDDEEM_REQUEST"
export const GET_DOWNLOADS = "GET_DOWNLOADS"
export const SET_DOWNLOAD_URL = "SET_DOWNLOAD_URL"
export const GET_AIRDROP_DETAILS = "GET_AIRDROP_DETAILS"

export const GET_TEAMS = "GET_TEAMS";
export const GET_TEAM_DETAILS = "GET_TEAM_DETAILS";

export const GET_MONTHLY_LOGS = "GET_MONTHLY_LOGS";
export const GET_SUBSCRIPTION__FREE_TRIALS = "GET_SUBSCRIPTION__FREE_TRIALS";

export const GET_TEAM_TOURNAMENT_WINNERS = "GET_TEAM_TOURNAMENT_WINNERS";
export const GET_TEAM_TOURNAMENT_BY_ID = "GET_TEAM_TOURNAMENT_BY_ID";
export const SEND_TEAM_WINNERS_REWARDS = "SEND_TEAM_WINNERS_REWARDS";
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const GET_SUBSCRIPTIONS_USER_ACTIVITIES = "GET_SUBSCRIPTIONS_USER_ACTIVITIES";

export const GET_TEAM_RULES = "GET_TEAM_RULES";
export const UPDATE_TEAM_RULES = "UPDATE_TEAM_RULES";

export const GET_JACKPOT_DATA = "GET_JACKPOT_DATA";
export const UPDATE_JACKPOT_DATA = "UPDATE_JACKPOT_DATA";
export const GET_CUSTOM_JACKPOT_DATA = "GET_CUSTOM_JACKPOT_DATA";
export const UPDATE_CUSTOM_JACKPOT_DATA = "UPDATE_CUSTOM_JACKPOT_DATA";

//User Logs
export const GET_REGISTERED_USER_LOGS = "GET_REGISTERED_USER_LOGS";
export const GET_REGISTERED_LOGS_BY_ID = "GET_REGISTERED_LOGS_BY_ID";

