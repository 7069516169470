const { SET_TABLE_DATA , SET_LOADER , SET_GROUP_IDS, SET_LISTING_DETAILS, SET_DOWNLOAD_URL} = require("Redux/Actions/ActionTypes");

const intialState = {
  tableData: [],
  loader:false,
  listingData: [],
  groupId : {},
  downloadURL : "",
  pageCountNft:"",
  pageCountListing:""
  
};

const PrintTableReducer = (state = intialState, {type, payload}) => {
    
  
  switch (type) {
    case SET_TABLE_DATA:
      
      return {
        ...state,
        tableData: [...payload.data],
        pageCountNft: Math.ceil(payload.count/10)
      };

    case SET_LOADER:
      return {
        ...state,
        loader:payload
      }  

      case SET_GROUP_IDS:

        return{
          ...state,
          groupId : {...payload}
        }


        case SET_LISTING_DETAILS:
          return {
            ...state,
            listingData : [...payload.data],
            pageCountListing :payload.count/10
          }  


          case SET_DOWNLOAD_URL : {
            return  {
              ...state,
              downloadURL:payload
            }
          }
        

    default:
      return state;
  }
};

export default PrintTableReducer;
