import Select from "react-select";
import { useField, ErrorMessage } from "formik";
import './style.scss';

const DropDown = ({
  isMulti = false,
  label,
  isClearable = false,
  defaultValue,
  isSearchable = false,
  placeholder,
  options,
  value,
  handleChange = () => {},
  className = "",
  classNamePrefix = "",
  id= "",
  ...props
}) => {
  const [field ] = useField({ ...props, name: props?.name ?? "" });
  
  return (
  
    <div className="form-group">
      <label className="form-control-label">
        {label}
        <sup className="text-danger">*</sup>
      </label>
      <Select
        value={value}
        name={props.name}
        defaultValue={defaultValue}
        placeholder={placeholder}
        options={options}
        onChange={handleChange}
        className={className}
        classNamePrefix={classNamePrefix}
        isSearchable={isSearchable}
        isMulti={isMulti}
        isClearable={isClearable}
        key={id}
        // menuIsOpen={true}
      />
      <div className="error">
        <ErrorMessage name={field.name} />
      </div>
    </div>
  );
};

export default DropDown;
