// libs
import { useEffect, useState } from "react";
// hooks
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
// components
import CheckClickOutside from "./checkOutsideClick";
import { Header } from "Components/atoms";

// utils
import { myLogout } from "Redux/Actions/PrintTableActions";
import Logo from "logo.svg";
import { activeScreen } from "Redux/Actions/Admin";
import { ROUTES } from "Routes/Constants";
import { LABELS } from "Shared";
import { DEFAULT_AUTHENTICATED_ROUTE } from "Routes/RootRouter";

// styles
import "./style.scss";

// constant
const NAV_ITEMS = [
  { path: ROUTES.BLACKLIST_NFT, text: LABELS.BLACKLIST_NFT },
  { path: ROUTES.CREATE_NFT, text: LABELS.CREATE_NFT },
  { path: ROUTES.REDEEM_NFT, text: LABELS.REDEEM_NFT },
  { path: ROUTES.REDEEM_AIRDROP, text: LABELS.REDEEM_AIRDROP },
  { path: ROUTES.REDEEM_CONTROL, text: LABELS.REDEEM_CONTROL },
  { path: ROUTES.ROYALTY, text: LABELS.ROYALTY },
  { path: ROUTES.JACKPOT, text: LABELS.JACKPOT },
  { path: ROUTES.PRIVATE_GAMES, text: LABELS.PRIVATE_GAMES },
  { path: ROUTES.USER_LIST, text: LABELS.USER_LIST },
  { path: ROUTES.CASH_LIST, text: LABELS.REDEEM_CASH_LIST },
  { path: ROUTES.ALLOW_BOTS, text: LABELS.ALLOW_BOTS },
  { path: ROUTES.REDEEM_USD, text: LABELS.CASH_POINT_REDEEM_USD },
  { path: ROUTES.REDEEM_BTC, text: LABELS.CASH_POINT_REDEEM_BTC },
  { path: ROUTES.REDEEM_HKD, text: LABELS.CASH_POINT_REDEEM_HKD },
  { path: ROUTES.REFERRAL, text: LABELS.REFERRAL },
  { path: ROUTES.TRANSACTION_HISTORY, text: LABELS.TRANSACTION_HISTORY },
  { path: ROUTES.TOURNAMENT_MAINTENANCE, text: LABELS.TOURNAMENT_MAINTENANCE },
  { path: ROUTES.TOURNAMENT_LOGS, text: LABELS.TOURNAMENT_LOGS },
  { path: ROUTES.USER_REWARDS_HISTORY, text: LABELS.USER_REWARDS_HISTORY },
  { path: ROUTES.TEAMS, text: LABELS.TEAMS },
  { path: ROUTES.CASH_POINT_LOGS, text: LABELS.CASH_POINT_LOGS },
  { path: ROUTES.FREE_TRIALS, text: LABELS.FREE_TRIALS },
  { path: ROUTES.TEAM_TOURNAMENT_WINNERS, text: LABELS.TEAM_TOURNAMENT_WINNERS },
  { path: ROUTES.TEAM_TOURNAMENT_RULES, text: LABELS.TEAM_TOURNAMENT_RULES },
  { path: ROUTES.SUBSCRIPTIONS, text: LABELS.SUBSCRIPTIONS },
  { path: ROUTES.USER_LOGS, text: LABELS.USER_LOGS },
];

const PrivateLayout = ({ children }) => {
  // hooks
  const dispatch = useDispatch();
  const location = useLocation();

  // redux states
  const preActiveScreen = useSelector(state => state.auth.activeScreen);
   
  // local states
  const [active, setActive] = useState(preActiveScreen);

  // side effects
  useEffect(() => {
    setActive(preActiveScreen);
  }, [preActiveScreen]);

  useEffect(()=>{
    setActive(getActive(location?.pathname));
 },[location]);

  const menuClose = () => {
    document.getElementById("root").classList.remove("active_menu");
  };
 
  const logoutHandler = () => {
    dispatch(myLogout());
  };

  const _activeScreen = (index) => {
    setActive(index)
    dispatch(activeScreen(index))
  }


  const getActive = (value) => {
    const matchingNavItem = NAV_ITEMS.find((item) => item.path === value);
    return matchingNavItem ? NAV_ITEMS.indexOf(matchingNavItem) + 1 : 1;
  };

  return (
    <>
      <Header />
      <CheckClickOutside
        onClick={() => {
          menuClose()
        }}
      >
        <div className="sidebar" id="sidebar" >
          <Link className="logo_main" to={DEFAULT_AUTHENTICATED_ROUTE}>
            <img
              src={Logo}
              width="200"
              className="img-fluid"
              alt="Meta Rivals"
            />
          </Link>

          <button className="close_menu" onClick={menuClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="71.943"
              height="71.949"
              viewBox="0 0 11.943 11.949"
            >
              <path
                id="Path_1510"
                data-name="Path 1510"
                d="M23.951,22.266l-3.928-3.928,3.928-3.928a1.191,1.191,0,1,0-1.684-1.684l-3.928,3.928-3.928-3.928a1.191,1.191,0,0,0-1.684,1.684l3.928,3.928-3.928,3.928a1.152,1.152,0,0,0,0,1.684,1.183,1.183,0,0,0,1.684,0l3.928-3.928,3.928,3.928a1.2,1.2,0,0,0,1.684,0A1.183,1.183,0,0,0,23.951,22.266Z"
                transform="translate(-12.359 -12.354)"
                fill="#fff"
              />
            </svg>
          </button>

          <nav className="navbar">
            <ul className="navbar-nav flex-column">
            {NAV_ITEMS.map((item, index) => (
                <li
                  key={item.path}
                  className={`nav-item ${active === index + 1 && "active"}`}
                  onClick={() => _activeScreen(index + 1)}
                >
                  <Link to={item.path} onClick={menuClose}>
                    {item.text}
                  </Link>
                </li>
            ))}
            </ul>
          </nav>

          <a type="button" className="logout_menu" onClick={logoutHandler}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25.613"
              height="25.613"
              viewBox="0 0 25.613 25.613"
            >
              <g id="logout" transform="translate(0.225 0.225)">
                <path
                  id="Path"
                  d="M12.339-29.9H3.085a1.039,1.039,0,0,0-1.028,1.048V-9.983A1.039,1.039,0,0,0,3.085-8.934h9.255a1.038,1.038,0,0,1,1.028,1.048,1.038,1.038,0,0,1-1.028,1.049H3.085A3.119,3.119,0,0,1,0-9.983V-28.855A3.119,3.119,0,0,1,3.085-32h9.255a1.038,1.038,0,0,1,1.028,1.048A1.038,1.038,0,0,1,12.339-29.9Z"
                  transform="translate(0 32)"
                  fill="#fff"
                  stroke="#fff"
                  strokeWidth="0.45"
                  fillRule="evenodd"
                />
                <path
                  id="Path-2"
                  data-name="Path"
                  d="M27.207-17.2l-6.244,6.066a1.038,1.038,0,0,1-1.452-.01,1,1,0,0,1,.01-1.43l4.462-4.335H12.027A1.018,1.018,0,0,1,11-17.923a1.018,1.018,0,0,1,1.027-1.011H23.983l-4.462-4.335a1,1,0,0,1-.01-1.43,1.035,1.035,0,0,1,.731-.3,1.03,1.03,0,0,1,.721.291l6.244,6.066a1,1,0,0,1,.306.72A1,1,0,0,1,27.207-17.2Z"
                  transform="translate(-2.35 30.504)"
                  fill="#fff"
                  stroke="#fff"
                  strokeWidth="0.45"
                  fillRule="evenodd"
                />
              </g>
            </svg>
            Logout
          </a>
        </div>
      </CheckClickOutside>

      {children}
    </>
  );
};

export default PrivateLayout;
