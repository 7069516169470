import {IOSSwitch} from 'Components';
import {fetchRedeemControl, updateRedeemControl} from 'Redux/Actions/Maintenace';
import {LABELS} from 'Shared';
import {withSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux';

const RedeemControl = ({enqueueSnackbar}) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState({
    reedem: false,
    airdrop: false
  });

  useEffect(() => {
    dispatch(
      fetchRedeemControl((data) => {
        setChecked({
          reedem: data?.data?.responseData?.allowReedem || false,
          airdrop: data?.data?.responseData?.allowAirdrop || false,
        });
      })
    );
  }, []);

  const updateStatus = (type, event) => {
    let checkedValue = event.target.checked;
    let payload = {};

    if (type == 1) {
      payload = {
        type: "allowReedem",
        isActive: event.target.checked,
      };
    } else if (type == 2) {
      payload = {
        type: "allowAirdrop",
        isActive: event.target.checked,
      };
    }

    dispatch(
      updateRedeemControl(payload, (data) => {
        enqueueSnackbar(data.data.message, {
          variant: data.status === 200 ? "success" : "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        if(data.status == 200){
          setChecked(
            (prev) => ({
              ...prev,
              [event.target.name]: checkedValue
            })            
          );
        }
      })
    );
  };
  return (
    <div className="container-fluid page-body-wrapper">
      <h2 className="h4">{LABELS.REDEEM_CONTROL}</h2>
      <div className="row">
        <div className="col-md-6 col-lg-7">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group toggle-space-between">
                    <div className="">
                      <label>{LABELS.ALLOW_REDEEM_FOR_USERS}</label>
                    </div>
                    <div className="">
                      <IOSSwitch name="reedem" onChange={(e)=>updateStatus(1,e)} checked={checked.reedem} />
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-2">
                  <div className="form-group toggle-space-between">
                    <div className="">
                      <label>{LABELS.ALLOW_AIRDROP_FOR_USERS}</label>
                    </div>
                    <div className="">
                      <IOSSwitch name="airdrop" onChange={(e)=>updateStatus(2,e)} checked={checked.airdrop} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withSnackbar(RedeemControl);