import moment from "moment";

export const STRINGS = {
  SOMETHING_WENT_WRONG: "Sorry, something went wrong.",
  OFFLINE_MESSAGE:
    "You appear to be offline. Please check your internet connection.",
};

export const STATUS_CODE = {
  successful: 200,
  unAuthorized: 401,
  badRequest: 400,
};

export const USER_DETAILS = {
  USER_NAME: "admin.test@yopmail.com",
  PASSWORD: "12345678",
};

export const privateGames = (status) => {
  switch (status) {
    case 1:
      return "Upcoming";
    case  2:
      return "Ongoing";
    case  3:
      return "Completed";
  }
};
 export const endDate = () => {
  const currentDate = new Date();
  const formatedDate = moment(currentDate).add(1, "day").format("YYYY-MM-DD");
  return formatedDate;
};

export const startDate = () => {
  const currentDate = new Date();
  const formatedDate = moment(currentDate).format("YYYY-MM-DD");
  return formatedDate;
};

  export const privateGamesStatus =[
    { value: 1, label: "UPCOMING GAMES" },
    { value: 2, label: "ONGOING GAMES" },
    { value: 3, label: "COMPLETED GAMES" },
    { value: 4, label: "ALL GAMES" },

]

export const TOURNAMENT_TYPES = {
  DAILY_TOURNAMENT: 1,
  INSTANT_WIN: 2,
  PRIVATE: 3,
  GRAB_ALL: 4
}

export const FREE_TRIAL_STATUS = {
  PENDING: 1,
  ACTIVE: 2,
  EXPIRED: 3
};

export const TOURNAMENT_REWARD_STATUS = {
  PENDING: 1,
  COMPLETED: 2
};

export const purchaseType =[
  { value: 1, label: "ANDROID IAP" },
  { value: 2, label: "PAYPAL" },
  { value: 3, label: "IOS IAP" },
  { value: 4, label: "ALL TRANSACTIONS" },
]

export const deviceType =[
  { value: 1, label: "ANDROID" },
  { value: 2, label: "PAYPAL" },
  { value: 3, label: "IOS" },
  { value: 4, label: "ALL DEVICES" },
]

export const REDEEM_TYPE = {
  NFT: 1,
  ROYALITY: 2,
  CASH: 3,
  CASH_FROM_TROPHY: 4,
  BITCOIN_FROM_TROPHY: 5,
  AIRDROP: 6,
  HKD_FROM_TROPHY: 7
};