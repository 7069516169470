import { toast } from "react-toastify";
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const CRYPTO_KEY = process.env.REACT_APP_CRYPTO_PRIVATE_KEY

export const API_URLS = {
  LOGIN: API_BASE_URL + "/admin/login",
  CATEGORY: API_BASE_URL + "/nfts/category/fetch",
  DELETE_FORM_DATA : API_BASE_URL + "/nfts/delete?role=1",
  PUT_FORM_DATA : API_BASE_URL + "/nfts/update?role=1",
  POST_FORM_DATA :API_BASE_URL  + "/nfts/create", 
  UPDATE_FORM_DATA : API_BASE_URL + "/nfts/update",
  UPLOAD_IMAGE : API_BASE_URL + "/nfts/upload",
  GET_GROUP_IDS : API_BASE_URL + "/nfts/dropdown/",
  GET_REEDEM_REQUEST : API_BASE_URL + "/nfts/reedemRequest/list",
  PUT_REEDEM_REQUEST : API_BASE_URL + "/nfts/reedemRequest/updateStatus",
  GET_FORM_DATA : API_BASE_URL + "/nfts/list",
  GET_DOWNLOADS : API_BASE_URL + "/nfts/fetchNftsFile",
  POST_FILEUPLOAD : API_BASE_URL + "/nfts/mint",
  POST_UPDATE_AMMOUNT: API_BASE_URL + "/admin/updateRoyalityAmount",
  POST_RANGE_AMMOUNT: API_BASE_URL + "/admin/addRestrictionRange",
  GET_PREVIOUS_ROYALITY: API_BASE_URL + "/admin/blacklistNfts/list",
  GET_OVERALL_STATS: API_BASE_URL + "/players/overAllStats",
  POST_CREATE_GAMES: API_BASE_URL + "/tournaments/private/createGame",
  PUT_EDIT_GAMES: API_BASE_URL + "/tournaments/private/update?id=",
  GET_GAMES_LIST: API_BASE_URL + "/tournaments/private/list?status=",
  DELETE_GAME: API_BASE_URL + "/tournaments/private/delete?id=",
  GET_GAME: API_BASE_URL + "/tournaments/private/getGameById?id=",
  GET_USER_LIST: API_BASE_URL + "/admin/userList?skip=",
  GET_USER_DETAILS: API_BASE_URL + "/players/stats?userId=",
  ALLOW_BOTS : API_BASE_URL + "/admin/allowBots",
  FETCH_BOT_STATUS : API_BASE_URL + "/admin/fetchBotsStatus",
  CREATE_REFERRAL : API_BASE_URL + "/admin/createReferral",
  UPDATE_REFERRAL : API_BASE_URL + "/admin/updateReferral",
  DELETE_REFERRAL : API_BASE_URL + "/admin/deleteReferral",
  REFERRAL_LIST : API_BASE_URL + "/admin/referral/list",
  TRANSACTION_HISTORY : API_BASE_URL + "/admin/transaction/history",
  UPDATE_MAINTENCANCE_STATUS : API_BASE_URL + "/admin/setMaintenanceStatus",
  REDEEM_CONTROL : API_BASE_URL + "/players/getUserConfiguration",
  SET_REDEEM_CONTROL: API_BASE_URL + "/admin/setUserConfiguration",
  FETCH_MAINTENCANCE_STATUS : API_BASE_URL + "/tournaments/status",
  TOURNAMENT_LOGS : API_BASE_URL + "/tournaments/gameLogs",
  TOURNAMENT_GAME_USERS : API_BASE_URL + "/tournaments/game/users",
  USER_REWARD_DATA : "/admin/getRewardData",
  TEAM_LIST : "/team/list",
  TEAM_RULES_LIST : "/team/rules",
  TEAM_UPDATE_RULES_LIST : "/team/rules",
  GET_TEAM : "/team/",
  MONTHLY_LOGS : "/admin/monthlyLogsHistory",
  SUBSCRIPTION_FREE_TRIALS : "/admin/subscriptionFreeTrials",
  TEAM_TOURNAMENT_WINNERS : "/team//winnerHistory",
  TEAM_TOURNAMENT_WINNER_BY_ID : "/team/tournmanetWinnerById",
  SEND_TEAM_WINNERS_REWARDS : "/admin/sendTeamWinnerRewards",
  SUBSCRIPTIONS : "/admin/subscriptions",
  ACTIVITIES : "/admin/subscriptionById",
  JACKPOT_DATA : "/admin/jackpot",
  REGISTERED_USER_LOGS: "/admin/registeredUserLogs",
  REGISTERED_LOGS_BY_ID: "/admin/registeredLogsById",
  CUSTOM_JACKPOT: "/admin/customJackPot"
};

export const STATUS_CODES = {
  SUCCESS: 200,
  UNAUTHORIZED:401,
  BAD_REQUEST:400,
};

export const  successCallBack = (message)=>{
  
  toast.info(message, {
      position: "top-center",
      autoClose: 1000,
    });
  }

  export const Status = [
    {
      label: "Pending",
      value: 1,
    },
    {
      label: "Completed",
      value: 2,
    },
  ];
     

  export const indexHistory = (index) => {
    if(index==0)
    {
      return index+1
    }
    else{
      return index*10 +1

    }
    
};

export const GetDefaultValue = (statusVal) => {
  const defValue = Status.filter((item) => item.value == statusVal);
  return defValue;
};