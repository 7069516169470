export const CATEGORY = "CATEGORY";
export const ACTIVE_SCREEN = "ACTIVE_SCREEN";

export const activeScreen = (payload) => {
return {
  type: ACTIVE_SCREEN,
  payload
};
};


export const snakeCategory = (callback) => {
  return {
    type: CATEGORY,
    callback
  };
};
 