const TableHeading = ({isAirdrop}) => {
  return (
    <thead className="bg-dark text-white align-content-center">
      <tr>
        <th> S.no </th>
        <th> Name </th>
        <th> Creation Date </th>
        <th>Email</th>
        <th>{isAirdrop ? "User ID" : null}</th>
        <th>{!isAirdrop ? "Token Id" : null}</th>
        <th>{!isAirdrop ? "Snake Name" : null}</th>

        <th>Wallet Address</th>
        <th>
          Action
        </th>
      </tr>
    </thead>
  );
};

export default TableHeading;
