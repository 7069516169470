import React, { useState } from "react";
import { LABELS } from "Shared";

const RenderKeyValues = ({ values = {}, limit = 1 }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <>
      {Object.entries(values)?.map(([key, value], index) =>
        index < limit || showMore ? (
          <div key={index}>{`${key} :  ${
            Math.trunc(value * 100000) / 100000
          }`}</div>
        ) : null
      )}
      {Object.entries(values)?.length ? (
        <div
          className="cursor-pointer text-primary"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? LABELS.SHOW_LESS : LABELS.SHOW_MORE}
        </div>
      ) : null}
    </>
  );
};

export default RenderKeyValues;
