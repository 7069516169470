import { takeLatest, all, put } from "redux-saga/effects";
import { STATUS_CODE } from "../../Shared/Constants";
import { deleteRequest, getRequest, postRequest, putRequest } from "Shared/Axios";
import { startLoading, stopLoading } from "Redux/Actions/LoaderAction";
import { CREATE_REFERRAL, DELETE_REFERRAL, EDIT_REFERRAL, GET_REFERRAL } from "Redux/Actions/Referral";

const api = require(`../../Services/Api/Constants`);

function* createReferral({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({
      API: `${api.API_URLS.CREATE_REFERRAL}`,
      DATA: payload,
    });
    callback(response);
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* editReferral({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield putRequest({
      API: `${api.API_URLS.UPDATE_REFERRAL}`,
      DATA: payload,
    });
    callback(response);
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* deleteReferral({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield deleteRequest({
      API: `${api.API_URLS.DELETE_REFERRAL}?referralCode=${payload.referralCode}`,
    });
    callback(response);
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* get_referral_list({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: `${api.API_URLS.REFERRAL_LIST}?skip=${payload.skip}&limit=${payload.limit}`,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data);
    } else {
      callback(response?.data?.message);
    }
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* ReferralSaga() {
  yield all([takeLatest(CREATE_REFERRAL, createReferral), takeLatest(GET_REFERRAL, get_referral_list),takeLatest(EDIT_REFERRAL,editReferral),takeLatest(DELETE_REFERRAL,deleteReferral)]);
}

export default ReferralSaga;
