import { takeLatest, all, put } from "redux-saga/effects";
import { STATUS_CODE } from "../../Shared/Constants";
import {getRequest, postRequest} from "Shared/Axios";

import { startLoading, stopLoading } from "Redux/Actions/LoaderAction";
import { GET_CUSTOM_JACKPOT_DATA, GET_JACKPOT_DATA, UPDATE_CUSTOM_JACKPOT_DATA, UPDATE_JACKPOT_DATA } from "Redux/Actions/ActionTypes";

const api = require(`../../Services/Api/Constants`);

function* getAllJackpotsData({ callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: api.API_URLS.JACKPOT_DATA,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(null, response?.data?.message,);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data, response?.status);
    } else {
      callback(null, response?.data?.message);
    }
  } catch (err) {
    callback(err?.message || err, STATUS_CODE.badRequest);
  } finally {
    yield put(stopLoading());
  }
}

function* updateJackpotsData({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({
      API: api.API_URLS.JACKPOT_DATA,
      DATA:payload
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message,response?.status);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data,response?.status);
    } else {
      callback(response?.data?.message,response?.status);
    }
  } catch (err) {
    callback(err?.message || err, STATUS_CODE.badRequest);
  } finally {
    yield put(stopLoading());
  }
}

function* getCustomJackpots({ callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: api.API_URLS.CUSTOM_JACKPOT,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(null, response?.data?.message,);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data, response?.status);
    } else {
      callback(null, response?.data?.message);
    }
  } catch (err) {
    callback(err?.message || err, STATUS_CODE.badRequest);
  } finally {
    yield put(stopLoading());
  }
}

function* updateCustomJackpots({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({
      API: api.API_URLS.CUSTOM_JACKPOT,
      DATA:payload
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message,response?.status);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data,response?.status);
    } else {
      callback(response?.data?.message,response?.status);
    }
  } catch (err) {
    callback(err?.message || err, STATUS_CODE.badRequest);
  } finally {
    yield put(stopLoading());
  }
}

function* JackpotSaga() {
  yield all([
    takeLatest(GET_JACKPOT_DATA, getAllJackpotsData),
    takeLatest(UPDATE_JACKPOT_DATA, updateJackpotsData),
    takeLatest(GET_CUSTOM_JACKPOT_DATA, getCustomJackpots),
    takeLatest(UPDATE_CUSTOM_JACKPOT_DATA, updateCustomJackpots),
  ]);
}

export default JackpotSaga;