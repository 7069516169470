// libs
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
// components
import { Table } from "@mui/material";
import TableHeading from "./Component/TableHeading";
import { ViewUserDetailsModal } from "Components";
// utils
import { userList } from "Redux/Actions/GamesAction";
import { indexHistory } from "Services/Api/Constants";
import viewIcon from "assets/images/icons/eye_icon.svg";
import { LABELS } from "Shared";

function UserList() {
  const [pageCount, setPageCount] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userListData, setUserListData] = useState([]);
  const [currentID, setCurrentID] = useState(0);
  const [search, setSearch] = useState("");

  const limit = 10;

  const dispatch = useDispatch();

  const handlePageClick = (pageData) => {
    let currentPage = pageData.selected;
    setCurrentIndex(currentPage);
    const payload = {
      skip: currentPage * limit,
      limit: 10,
      searchKey:search
    };
    dispatch(
      userList(payload, (data) => {
        setUserListData(data?.data);
      })
    );
  };

  useEffect(() => {
    const payload = {
      limit: 10,
      skip: 0,
      searchKey:search
    };
    dispatch(
      userList(payload, (data) => {
        setUserListData(data?.data);
        setPageCount(data?.count);
      })
    );
  }, []);

  const modalShow = (id) => {
      setCurrentID(id);
      setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

 const OnSubmit = (e) => {
  const payload = {
        limit: 10,
        skip: 0,
        searchKey:search
      };
      dispatch(
        userList(payload, (data) => {
          setUserListData(data?.data);
          setPageCount(data?.count);
          setCurrentIndex(0);
        })
      );

  }

  return (
    <>
    <div className="container-fluid page-body-wrapper">
      <div className="card mt-4">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-sm-4 mb-3">
              <h4 className="h6 mb-0">{LABELS.USER_LIST}</h4>
            </div>

            <div className="col-sm-8 mb-3">
              <div className="search_bar input-group ms-auto">
                <input
                type="text"
                placeholder="Enter email/UID"
                className="form-control"
                onChange={(e)=>{
                  setSearch(e.target.value)
                }}

                />
                <div className="input-group-prepend">
                  <button className="btn btn-md btn-primary" onClick={()=>{OnSubmit()}}>{LABELS.SUBMIT}</button>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {userListData?.length ? (
              <div className="col-md-12">
                <div className="table-responsive">
                  <Table className="table table-bordered">
                    <TableHeading />
                    <tbody className="table-light table-bordered">
                      {userListData?.map((item, index) => {
                        return (
                          <tr key={item?.id}>
                            <td>{indexHistory(currentIndex) + index}</td>
                            <td>{item?.userName}</td>
                            <td>{item?.mobileNumber || "--" }</td>
                            <td>{item?.email}</td>
                            <td>{item?.country || "--"}</td>
                            <td>{item?.userId}</td>
                              <td>
                              {" "}
                              <button
                                className="btn btn-sm btn-primary mx-1"
                                onClick={() => modalShow(item?.userId)}
                              >
                                <img src={viewIcon} alt="view" width="20" />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>

                <ReactPaginate
                  previousLabel=""
                  nextLabel=""
                  breakLabel="..."
                  pageCount={Math.ceil(pageCount / 10)}
                  marginPagesDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName="pagination justify-content-center mt-4"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLinkClassName="page-link"
                  breakClassName="page-item"
                  activeClassName="active"
                  forcePage={currentIndex}
                />
              </div>
            ) : (
              <p>No User Found </p>
            )}
          </div>
        </div>
      </div>

      <ViewUserDetailsModal
        isOpen={isModalOpen}
        handleToggle={() => handleModalClose()}
        currentID={currentID}
      />
    </div>
    </>
  );
}

export default UserList;
