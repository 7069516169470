const { LABELS } = require('Shared');

export const TRANSACTIONS_HEADINGS = [
  LABELS.S_NO,
  LABELS.USER_NAME,
  LABELS.EMAIL,
  LABELS.TRANSACTION_TYPE,
  LABELS.TRANSACTION_DATE,
  LABELS.TRANSACTION_ID,
  LABELS.UID,
  LABELS.CURRENCY_TYPE,
  LABELS.AMOUNT,
];
