import Button from '../../Components/atoms/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withSnackbar } from 'notistack';
import { createReferral, deleteReferral, getReferrals } from 'Redux/Actions/Referral';
import { indexHistory } from 'Services/Api/Constants';
import moment from 'moment';
import { CustomTable } from 'Components';
import EditModal from './EditModal';
import { LABELS } from 'Shared';
import { REFERRAL_HEADINGS } from './Helpers';

const Referral = ({ enqueueSnackbar }) => {
  const [mDollar, setMDollar] = useState(0);
  const [expiry, setExpiry] = useState(0);
  const [list, setList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const limit = 10;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editData, setEditData] = useState({});

  const dispatch = useDispatch();

  const fetchList = () => {
    const payload = {
      skip: 0,
      limit: limit,
    };
    dispatch(
      getReferrals(payload, (data) => {
        setList(data?.data);
        let tempPageCount = Math.ceil(data?.count / 10);
        setPageCount(tempPageCount);
        setCurrentIndex(0);
      })
    );
  };

  const handleCreate = () => {
    const payload = {
      mDollar: mDollar,
      expiryTime: expiry,
    };
    dispatch(
      createReferral(payload, (data) => {
        enqueueSnackbar(data.data.message, {
          variant: data.status === 200 ? 'success' : 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        if (data.status === 200) {
          fetchList();
        }
      })
    );
  };
  useEffect(() => {
    fetchList();
  }, []);

  const handlePageClick = (pageData) => {
    let currentPage = pageData.selected;
    setCurrentIndex(currentPage);
    const payload = {
      skip: currentPage * limit,
      limit: 10,
    };
    dispatch(
      getReferrals(payload, (data) => {
        setList(data?.data);
        let tempPageCount = Math.ceil(data?.count / 10);
        setPageCount(tempPageCount);
      })
    );
  };

  const handleEdit = (data) => {
    setIsModalOpen(true);
    setEditData(data);
  };

  const handleDelete = (data) => {
    const payload = {
      referralCode: data.referralCode,
    };
    dispatch(
      deleteReferral(payload, (data) => {
        enqueueSnackbar(data.data.message, {
          variant: data.status === 200 ? 'success' : 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        if (data.status === 200) {
          fetchList();
        }
      })
    );
  };

  return (
    <div className="container-fluid page-body-wrapper">
      <h2 className="h4">{LABELS.REFERRAL}</h2>
      <div className="row">
        <div className="col-md-6 col-lg-7">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>{LABELS.ENTER_M_DOLLAR}</label>
                    <input
                      type={'number'}
                      onChange={(e) => setMDollar(e.target.value)}
                      value={mDollar}
                      placeholder={LABELS.MDOLLAR}
                      className="form-control"
                      min={0}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>{LABELS.ENTER_EXPIRY_TIME}</label>
                    <input
                      type={'text'}
                      pattern="[0-9]"
                      onChange={(e) => {
                        setExpiry(e.target.value.replace(/\D/g, ''));
                      }}
                      value={expiry}
                      placeholder={LABELS.EXPIRY_TIME}
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-12 text-center">
                  <Button className="btn btn-md btn-secondary" onClick={handleCreate}>
                    {LABELS.CREATE}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-md-4">
              <h3 className="h6 mb-4 text-captialize">{LABELS.REFERRAL_LIST}</h3>
            </div>
          </div>
          <div className="row">
            {list?.length ? (
              <div className="col-md-12">
                <CustomTable
                  isPagination
                  headings={REFERRAL_HEADINGS}
                  pageCount={pageCount}
                  currentIndex={currentIndex}
                  handlePageClick={handlePageClick}
                >
                  {list?.map((item, index) => {
                    return (
                      <tr key={item?.id}>
                        <td>{indexHistory(currentIndex) + index}</td>
                        <td>{item?.referralCode}</td>
                        <td>{moment(item.creationDate).format('L')}</td>
                        <td>{moment(item.expiryDate).format('L')}</td>

                        <td>{item?.claimedCount}</td>
                        <td>{item?.mDollar}</td>
                        <td>{item?.isExpired ? 'Expired' : 'Active'}</td>
                        <td className="">
                          <button
                            onClick={() => handleEdit(item)}
                            className="btn btn-sm btn-secondary"
                            style={{
                              marginRight: '10px',
                            }}
                          >
                            {LABELS.EDIT}
                          </button>
                          <button
                            onClick={() => handleDelete(item)}
                            className="btn btn-sm btn-secondary"
                          >
                            {LABELS.DELETE}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </CustomTable>
              </div>
            ) : (
              <p className="d-flex justify-content-center">{LABELS.NO_RECORDS_FOUND}</p>
            )}
          </div>
        </div>
      </div>
      <EditModal
        isModalOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        fetchList={fetchList}
        editData={editData}
      />
    </div>
  );
};

export default withSnackbar(Referral);
