// libs
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { ToastContainer } from "react-toastify";
import { withSnackbar } from "notistack";
// components
import TableHeading from "./TableHeading";
import TableBody from "./TableBody";
import Table from "./Table"
// utils
import { getAirdropDetails, putReddeemRequest, user_stats } from "Redux/Actions/PrintTableActions";


const Airdrop = ({enqueueSnackbar}) => {


  const dispatch = useDispatch();
  const pageCount = useSelector((state) => state.printTable.pageCountListing);
  const [currentIndex, setCurrentIndex] = useState(0);


  const limit = 10;
  const [skip, setSkip] = useState(0)
  const [usersInfo, setUserInfo] = useState("")



  const updateReddemRequest = (selected, id) => {
    if(selected?.value == 1){
    return;
    }
    const payload = id

    dispatch(putReddeemRequest(payload, (data) => {
      enqueueSnackbar(data.data.message, {
        variant: data.status ? "success" : "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      dispatch(getAirdropDetails(skip, limit))
    }))
  }


  const handlePageClick = (pageData) => {
    let currentPage = pageData.selected;
    setCurrentIndex(currentPage)
    setSkip(currentPage * limit)
    dispatch(getAirdropDetails(currentPage * limit, limit));
  };

  useEffect(() => {

    dispatch(user_stats((data) => {
      console.log("data", data)
      setUserInfo(data)
      dispatch(getAirdropDetails(skip, limit))

    }))

    //this is used to get all reddem request from user 

  }, [])


  return <>

    <div className="container-fluid page-body-wrapper"> 
      {/* <div className="row">
        <div className="col-sm-6 col-md-4 mb-3">
          <div className="card count_blocks">
            <div className="card-body">
              <span>User Count</span>
              <h4 className="h2 mb-0">{usersInfo?.usersCount}</h4>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 mb-3">
          <div className="card count_blocks">
            <div className="card-body">
              <span>Total Air Drops</span>
              <h4 className="h2 mb-0">{usersInfo?.totalAirDrops}</h4>
            </div>
          </div>
        </div>
        
      </div> */}

      <div className="card">
        <div className="card-body">
        <div className="col">
              <h4 className="h6 mb-3">Airdrop Redeem  List</h4>
            </div>
          <div className="table-responsive">
            <Table className="table table-bordered">
              <TableHeading isAirdrop={true} />
              <TableBody updateReddemRequest={updateReddemRequest} currentIndex={currentIndex} airDropData={true}/>
            </Table>
          </div>
          <ReactPaginate
            previousLabel=""
            nextLabel=""
            breakLabel="..."
            pageCount={pageCount}
            marginPagesDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName="pagination justify-content-center mt-4"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLinkClassName="page-link"
            breakClassName="page-item"
            activeClassName="active"
          />
        </div>
      </div>
    </div>
    <ToastContainer />
  </>
}

export default withSnackbar(Airdrop);