import { combineReducers } from "redux";

import authReducer from "./Auth";
import { LOGOUT } from "../Actions/Auth";
import { ACTION_STATES } from "../Actions/ActionStates";
import PrintTableReducer from "./PrintTableReducer";
import PrintTableAuth from "./PrintTableAuth.js"
import  LoaderReducer  from "./Loader";
import PrivateGameReducer from "./PrivateGameReducer";

const appReducer = combineReducers({
  auth: authReducer,
  loader:LoaderReducer,
  printTable: PrintTableReducer,
  printTableAuth:PrintTableAuth,
  PrivateGame:PrivateGameReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT + ACTION_STATES.SUCCESS) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
