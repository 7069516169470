import { useSelector } from "react-redux";
import { indexHistory } from "Services/Api/Constants";

const TableBody = ({currentIndex, data, OpenModal = () => {} }) => {

   const loader = useSelector(state=>state.printTable.loader)

  return (
    <tbody>
       
      {data.length ?  data.map((item, index) => {
        
        return (
          <tr key={index}>
           <td>{indexHistory(currentIndex) + index}</td>
            <td>{item.category}</td>
            <td>{item["Meta-Key"]==0?"" :item["Meta-Key"]}</td>
            <td>{item.groupId}</td>
            <td>{item.snakeName}</td>
            <td>{item.description}</td>
            <td>{item.damage}</td>
            <td>{item.speed}</td>
            <td>{item.health}</td>
            <td>{item.magnet}</td>
            <td>{item.fireball}</td>
            <td>{item.bomb}</td>
            <td>{item.star}</td>
            <td>{item.nftToken}</td>
            <td>
              {
                <img
                  style={{ height: "100px", width: "100px" }}
                  src={item.imageUrl} 
                  alt="No Preview"
                />
              }
            </td>
            <td >
              {item.status==3? "True": "False"}
            </td>
            <td>
              <button disabled={item.status!=1} onClick={() => OpenModal(item)} className="btn btn-sm btn-secondary">
                Update
              </button>
            </td>
          </tr>
        );
      }): <tr><td colSpan="16">{ !loader && <p className="no_result">No data found</p>}</td></tr>}
    </tbody>
  );
};

export default TableBody;
