// libs
import React from "react";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import moment from "moment";
import { withSnackbar } from "notistack";
// components
import { Button, TextField, IOSSwitch } from "../../atoms";
// utils
import { endDate, startDate } from "Shared/Constants";
import { createGame, listGame } from "Redux/Actions/GamesAction";
import { STATUS_CODES } from "Services/Api/Constants";
import { LABELS } from "Shared";

const CreateGames = ({enqueueSnackbar}) => {
  const dispatch = useDispatch();
  const validate = Yup.object({
    startTime: Yup.number().required("Enter the Start Time"),
    minPlayer: Yup.number().required("Enter Minimum no Player"),
    mDollar: Yup.number().required("Enter mDollar "),
    rounds: Yup.number().required("Enter no of Rounds to be Played"),
    startDate: Yup.string().required("Enter the Start Day"),
    endDate: Yup.string().required("Enter the End Day"),
    bonusPoint: Yup.number().required("Enter Bonus Point "),
    winPoint: Yup.number().required("Enter Win Points "),
    gameTime: Yup.number().required("Enter Game Timer"),
    isCodeEntryValid : Yup.bool().oneOf([true,false], 'Please select is entry by code valid'),
    
    mDollar1: Yup.number().required("Enter mDollar Amount for Position 1"),
    mDollar2: Yup.number().required("Enter mDollar Amount for Position 2"),
    mDollar3: Yup.number().required("Enter mDollar Amount for Position 3"),
    mTrophy1: Yup.number().required("Enter mTrophy Amount for Position 1"),
    mTrophy2: Yup.number().required("Enter mTrophy Amount for Position 2"),
    mTrophy3: Yup.number().required("Enter mTrophy Amount for Position 3"),
    usd1: Yup.number().required("Enter USD Amount for Position 1"),
    usd2: Yup.number().required("Enter USD Amount for Position 2"),
    usd3: Yup.number().required("Enter USD Amount for Position 3"),
  });

  const createGamesAction = (value,resetForm) => {
    const payload = {
      startDate: moment.utc(value?.startDate).format(),
      endDate: moment.utc(value?.endDate).format(),
      minPlayers: value.minPlayer,
      creationTime: `${value.startTime}:00`,
      mDollar: value.mDollar,
      isCodeEntryValid : value.isCodeEntryValid,
      rewards: [
        {
          rank: 1,
          mDollar: value.mDollar1,
          mTrophy: value.mTrophy1,
          USD: value.usd1,
        },
        {
          rank: 2,
          mDollar: value.mDollar2,
          mTrophy: value.mTrophy2,
          USD: value.usd2,
        },
        {
          rank: 3,
          mDollar: value.mDollar3,
          mTrophy: value.mTrophy3,
          USD: value.usd3,
        },
      ],
      rounds: value.rounds,
      bonusPoint : value.bonusPoint,
      winPoint : value.winPoint,
      gameTime : value.gameTime
    };

    dispatch(
      createGame(payload, (data) => { 
        
        enqueueSnackbar(data.data.message, {
        variant: data.status === STATUS_CODES.SUCCESS ? "success" : "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      if(data.status === STATUS_CODES.SUCCESS){    
        const payload = {
          status: 4,
          limit: 10,
          skip: 0,
        };
        dispatch(listGame(payload, () => {resetForm()}));
      }
      })
    );
  };

  return (
    <Formik
      onSubmit={(value, { resetForm }) => {
        createGamesAction(value, resetForm);
      }}
      enableReinitialize
      validationSchema={validate}
      initialValues={{
        minPlayer: 1,
        startDate: "",
        endDate: "",
        mDollar: "",
        rounds: 1,
        startTime: "",
        mDollar1: "",
        mDollar2: "",
        mDollar3: "",
        mTrophy1: "",
        mTrophy2: "",
        mTrophy3: "",
        usd1: "",
        usd2: "",
        usd3: "",
        bonusPoint: "",
        winPoint: "",
        gameTime: "",
        isCodeEntryValid: false,
      }}
    >
      {(props) => (
        <>
          <Form>
            <h2 className="h4 mb-4 text-capitalize">{LABELS.PRIVATE_GAMES}</h2>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6 col-lg-4">
                    <TextField label={"Min Player"} name={"minPlayer"} type="number" min={1} step="any" />
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <TextField
                      label={"mDollar"}
                      name={"mDollar"}
                      type="number"
                      min={0}
                      step="any"
                      placeholder={"Enter mDollar"}
                    />
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <TextField label={"No of Rounds"} name={"rounds"} type="number" min={1} step="any" />
                  </div>

                  <div className="col-sm-6 col-lg-4">
                    <TextField
                      label={"Start Date"}
                      name={"startDate"}
                      placeholder={"Enter start Date"}
                      min={startDate()}
                      type="date"
                      step="any"
                    />{" "}
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <TextField
                      label={"End Date"}
                      name={"endDate"}
                      placeholder={"Enter end Date"}
                      min={endDate()}
                      type="date"
                      step="any"
                    />{" "}
                  </div>

                  <div className="col-sm-6 col-lg-4">
                        <TextField
                          label={"Start Time (1 to 24 only)"}
                          name={"startTime"}
                          placeholder={"Enter starting time"}
                          type="number"
                          step="any"
                          min={1}
                          max={24}
                        />
                  </div>

                  <div className="col-sm-6 col-lg-4">
                    <TextField
                      label={"Bonus Point "}
                      name={"bonusPoint"}
                      placeholder={"Enter Bonus Point"}
                      type="number"
                      min={0}
                      step="any"
                    />
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <TextField
                      label={"Win Points "}
                      name={"winPoint"}
                      placeholder={"Enter Win Points"}
                      type="number"
                      min={0}
                      step="any"
                    />
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <TextField
                      label={"Game Timer (Room Time) "}
                      name={"gameTime"}
                      placeholder={"Enter Game Time in minutes"}
                      type="number"
                      min={1}
                      max={60}
                      step="any"
                    />
                  </div>
                  <div className="col-sm-6 col-lg-4 mt-2 d-flex justify-content-between">
                    <label>
                      {"Is Entry by Code Valid"} <sup className="text-danger">*</sup>
                    </label>
                    <div className="">
                      <IOSSwitch
                        name="isCodeEntryValid"
                        onChange={(e) => props.setFieldValue("isCodeEntryValid", e.target.checked)}
                        checked={props.values.isCodeEntryValid}
                      />
                      <span className="error d-block">
                        <ErrorMessage name={"isCodeEntryValid"} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <h3 className="h5">Rewards</h3>
              <div className="card">
                <div className="card-body">
                  <fieldset className="fieldset mb-5 mt-2">
                    <legend>Rank 1</legend>
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <TextField
                          label={"mDollar"}
                          name={"mDollar1"}
                          type="number"
                          min={0}
                          step="any"
                          placeholder={"Enter mDollar amount"}
                        />{" "}
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <TextField
                          label={"mTrophy"}
                          name={"mTrophy1"}
                          type="number"
                          min={0}
                          step="any"
                          placeholder={"Enter mDollar amount"}
                        />{" "}
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <TextField
                          label={"USD amount"}
                          name={"usd1"}
                          type="number"
                          min={0}
                          step="any"
                          placeholder={"Enter USD  amount"}
                        />{" "}
                      </div>
                    </div>
                  </fieldset>

                  <fieldset className="fieldset mb-5">
                    <legend>Rank 2</legend>
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <TextField
                          label={"mDollar"}
                          name={"mDollar2"}
                          type="number"
                          min={0}
                          step="any"
                          placeholder={"Enter mDollar amount"}
                        />{" "}
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <TextField
                          label={"mTrophy"}
                          name={"mTrophy2"}
                          type="number"
                          min={0}
                          step="any"
                          placeholder={"Enter mDollar amount"}
                        />{" "}
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <TextField
                          label={"USD amount"}
                          name={"usd2"}
                          type="number"
                          min={0}
                          step="any"
                          placeholder={"Enter USD  amount"}
                        />{" "}
                      </div>
                    </div>
                  </fieldset>

                  <fieldset className="fieldset mb-5">
                    <legend>Rank 3</legend>
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <TextField
                          label={"mDollar"}
                          name={"mDollar3"}
                          type="number"
                          min={0}
                          step="any"
                          placeholder={"Enter mDollar amount"}
                        />{" "}
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <TextField
                          label={"mTrophy"}
                          name={"mTrophy3"}
                          type="number"
                          min={0}
                          step="any"
                          placeholder={"Enter mDollar amount"}
                        />{" "}
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <TextField
                          label={"USD amount"}
                          name={"usd3"}
                          type="number"
                          min={0}
                          step="any"
                          placeholder={"Enter USD  amount"}
                        />{" "}
                      </div>
                    </div>
                  </fieldset>

                  <div className="text-center">
                    <Button className="btn btn-md btn-secondary" type="submit">
                      Add Data
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};
export default withSnackbar(CreateGames);
