// libs
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

// components
import { Table } from "Components";
import GameUserListModal from "./GameUserListModal";
import CustomModal from "Components/atoms/CustomModal";

// utils
import { getTournamentLogs } from "Redux/Actions/Common";
import { indexHistory } from "Services/Api/Constants";
import { TOURNAMENT_TYPES } from "Shared/Constants";
import { LABELS, UTILITIES } from "Shared";

const TournamentLogs = () => {
  const dispatch = useDispatch();
  const limit = 10;
  const [pageCount, setPageCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [listData, setListData] = useState([]);
  const [search, setSearch] = useState("");

  const [tournamentDetails, setTournamentDetails] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handlePageClick = (pageData) => {
    let currentPage = pageData.selected;
    setCurrentIndex(currentPage);
    const payload = {
      skip: currentPage * limit,
      limit: 10,
      searchKey: search,
    };
    dispatch(
      getTournamentLogs(payload, (data) => {
        setListData(data?.data);
      })
    );
  };
  useEffect(() => {
    const payload = {
      limit: 10,
      skip: 0,
      searchKey: search,
    };
    dispatch(
      getTournamentLogs(payload, (data) => {
        setListData(data?.data || []);
        setPageCount(data?.count);
      })
    );
  }, []);

  const OnSubmit = (e) => {
    const payload = {
      limit: 10,
      skip: 0,
      searchKey: search,
    };
    dispatch(
      getTournamentLogs(payload, (data) => {
        setListData(data?.data);
        setPageCount(data?.count);
        setCurrentIndex(0);
      })
    );
  };

  const handleView = (item) => {
    setTournamentDetails(item);
    setModalOpen(true);
  };

  const showGameType = (val) => {
    switch (val) {
      case TOURNAMENT_TYPES.DAILY_TOURNAMENT:
        return "TROPHY";
      case TOURNAMENT_TYPES.INSTANT_WIN:
        return "INSTANT";
      case TOURNAMENT_TYPES.PRIVATE:
        return "PRIVATE";
      case TOURNAMENT_TYPES.GRAB_ALL:
        return "GRAB ALL";
      default:
        return "DAILY";
    }
  };

  return (
    <div className="container-fluid page-body-wrapper">
      <div className="card mt-4">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-sm-4 mb-3">
              <h4 className="h6 mb-0">{LABELS.TOURNAMENT_LOGS}</h4>
            </div>

            <div className="col-sm-8 mb-3">
              <div className="search_bar input-group ms-auto">
                <input
                  type="text"
                  placeholder="Enter UID"
                  className="form-control"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <div className="input-group-prepend">
                  <button
                    className="btn btn-md btn-primary"
                    onClick={() => {
                      OnSubmit();
                    }}
                  >
                    {LABELS.SUBMIT}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {listData?.length ? (
              <div className="col-md-12">
                <div className="table-responsive">
                  <Table className="table table-bordered">
                    <thead className="bg-dark text-white align-content-center">
                      <tr>
                        <th> S.no </th>
                        <th> DATE </th>
                        <th> RoomId </th>
                        <th> Room Type </th>
                        <th> Gold Grab By Bots </th>
                        <th> Gold Grab By Player </th>
                        <th> EntryFee </th>
                        <th> Action </th>
                      </tr>
                    </thead>{" "}
                    <tbody className="table-light table-bordered table-padding">
                      {listData?.map((item, index) => 
                          <tr key={item?.id}>
                            <td>{indexHistory(currentIndex) + index}</td>
                            <td>{UTILITIES.renderDate(item?.gameTime)}</td>
                            <td>{item?.id}</td>
                            <td>{showGameType(item?.gameType)}</td>
                            <td>{item?.goldGrabByBots || 0}</td>
                            <td>{item?.goldGrabByPlayer || 0}</td>
                            <td>
                              {typeof item?.entryFee === 'object'
                                ? Object.entries(item.entryFee).map(([key, value]) => (
                                    <div key={value}>{`${key} :  ${Math.trunc(value * 100) / 100}`}</div>
                                  ))
                                : 'NA'}
                            </td>
                            <td>
                              <button
                                onClick={() => handleView(item)}
                                className="btn btn-sm btn-secondary"
                              >
                                View
                              </button>
                            </td>
                          </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                <ReactPaginate
                  previousLabel=""
                  nextLabel=""
                  breakLabel="..."
                  pageCount={Math.ceil(pageCount / 10)}
                  marginPagesDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName="pagination justify-content-center mt-4"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLinkClassName="page-link"
                  breakClassName="page-item"
                  activeClassName="active"
                  forcePage={currentIndex}
                />
              </div>
            ) : (
              <p className="text-center h5">No Tournaments Found </p>
            )}
          </div>
        </div>
      </div>
      <CustomModal
        handleToggle={() => {
          handleModalClose();
        }}
        isOpen={isModalOpen}
        extralarge={true}
      >
        <GameUserListModal tournamentDetails={tournamentDetails} />
      </CustomModal>
    </div>
  );
};

export default TournamentLogs;
