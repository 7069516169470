// libs
import Select from "react-select";
import { useSelector } from "react-redux";
import moment from "moment";
// utils
import { indexHistory, Status } from "Services/Api/Constants";

const TableBody = ({updateReddemRequest,currentIndex, airDropData}) => {

  const ReddemRequestData = useSelector(state => state.printTable.listingData);

  const GetDefaultValue = (statusVal)=>{
      const defValue = Status.filter(item=>item.value==statusVal)
      return defValue;
  }



  return (
    <tbody className="table-light table-bordered">
      {ReddemRequestData.map((item,index) => {
        return (
          <tr key={item.id}>
            <td>{indexHistory(currentIndex) + index}</td>
            <td>{item.userName}</td>
            <td>{moment(item.createdAt).format('L') }</td>
            <td>{item.email}</td>
            <td>{airDropData ? item.id : null}</td>
            <td>{item.groupId}</td>
            <td>{item.snakeName}</td>
            <td>{item.walletAddress}</td>
            <td>
              <Select
                defaultValue={()=>GetDefaultValue(item.status)}
                name={"groupId"}
                onChange={(e)=>{
                  updateReddemRequest(e ,item.id)
                }}
                isDisabled={item.status==2}
                isSearchable={false}
                placeholder={"Select Status"}
                options={Status}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </td>
          </tr> 
        );
      })}
    </tbody>
  );
};

export default TableBody;
