// libs
import React, { useEffect, useState } from 'react';
// components
import { CustomTable } from 'Components';
import CustomModal from 'Components/atoms/CustomModal';
// hooks
import { useDispatch } from 'react-redux';
// utils
import { getTeamDetails } from 'Redux/Actions/Team';
import { PLAYER_TABLE_HEADINGS } from 'Views/Teams/Helpers';

function ViewTeamsDetailModal({ isOpen = false, handleModalClose = () => {}, currentTeamId = '' }) {
  const dispatch = useDispatch();
  const [teamDetails, setTeamDetails] = useState([]);

  useEffect(() => {
    if(isOpen){
      fetchTeamDetails();
    };
  }, [isOpen]);

  const fetchTeamDetails = () => {
    dispatch(
      getTeamDetails({ teamId: currentTeamId}, (data) => {
        console.log('data: ', data);
        setTeamDetails(data?.data || {});
      })
    );
  };

  return (
    <CustomModal handleToggle={handleModalClose} isOpen={isOpen} extralarge>
      <h2 className="h4 mb-3">Team Details</h2>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="mb-2">
                <label> Name:</label>
                <span className="d-inline-block ms-2">
                  {teamDetails?.teamName  || ""}
                </span>
              </div>
            </div>

            <div className="col-md-12 col-lg-12">
              <div className="mb-2">
                <label>Description:</label>
                <span className="d-inline-block ms-2">
                  {teamDetails?.description || ""}
                </span>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label> Game played: </label>
                <span className="d-inline-block ms-2">
                  {teamDetails?.totalGamePlayed || 0}
                </span>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label>Tournament Won:</label>
                <span className="d-inline-block ms-2">
                  {teamDetails?.totalTeamWin || 0}
                </span>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label>Gold:</label>
                <span className="d-inline-block ms-2">
                  {teamDetails?.totalGold || 0}
                </span>
              </div>
            </div>
            {/* <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label>Tournament Status:</label>
                <span className="d-inline-block ms-2">
                  {teamDetails?.isTournamentStarted ? 'Started' : 'Not Started'}
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="mt-4">
        <h3 className="h5">Team Members</h3>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12">

          <CustomTable headings={PLAYER_TABLE_HEADINGS}>
            {teamDetails?.players?.length ? (
              teamDetails?.players?.map((item) => (
                <tr key={item.uid}>
                  <td>
                    {item?.userName}
                    {item?.isTeamLeader ? <span className="ml-1">{'(Leader)'}</span> : ''}
                  </td>
                  {/* <td>{item?.rewardAllocationPercentage}</td> */}
                  <td>{item?.gamePlayed}</td>
                  <td>{item?.gold}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={PLAYER_TABLE_HEADINGS.length}>
                  <p className="no_data">No records found.</p>
                </td>
              </tr>
            )}
          </CustomTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}

export default ViewTeamsDetailModal;
