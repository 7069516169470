import { JACKPOT_FIELDS } from "./constants";


export const transformJackpotApiToLocal = (data, isCustomJackpot ) => ({
    ...(isCustomJackpot ? {
        [JACKPOT_FIELDS.ACTIVE.name] : data?.active || false,
        [JACKPOT_FIELDS.START_TIME.name] : data?.startTime || "",
        [JACKPOT_FIELDS.END_TIME.name] : data?.endTime || "",
    } : {}),
    [JACKPOT_FIELDS.BIG_JACKPOT.COUNT.name] : data?.bigJackPot?.count || 0,
    [JACKPOT_FIELDS.BIG_JACKPOT.VALUE.name] : data?.bigJackPot?.value || 0,
    [JACKPOT_FIELDS.BIG_JACKPOT.TYPE.name] : data?.bigJackPot?.type || 0,

    [JACKPOT_FIELDS.MEDIUM_JACKPOT.COUNT.name] : data?.mediumJackPot?.count || 0,
    [JACKPOT_FIELDS.MEDIUM_JACKPOT.VALUE.name] : data?.mediumJackPot?.value || 0,
    [JACKPOT_FIELDS.MEDIUM_JACKPOT.TYPE.name] : data?.mediumJackPot?.type || 0,

    [JACKPOT_FIELDS.SMALL_JACKPOT.COUNT.name] : data?.smallJackPot?.count || 0,
    [JACKPOT_FIELDS.SMALL_JACKPOT.VALUE.name] : data?.smallJackPot?.value || 0,
    [JACKPOT_FIELDS.SMALL_JACKPOT.TYPE.name] : data?.smallJackPot?.type || 0,
})

export const transformJackpotLocalToApi = (data, isCustomJackpot) => ({
    ...(isCustomJackpot ? {
        active : data?.[JACKPOT_FIELDS.ACTIVE.name] || false,
        startTime : data?.[JACKPOT_FIELDS.START_TIME.name] || "",
        endTime : data?.[JACKPOT_FIELDS.END_TIME.name] || "",
    } : {}),
    bigJackPot:{
        type: data?.[JACKPOT_FIELDS.BIG_JACKPOT.TYPE.name] || 0,
        count : data?.[JACKPOT_FIELDS.BIG_JACKPOT.COUNT.name] || 0,
        value : data?.[JACKPOT_FIELDS.BIG_JACKPOT.VALUE.name] || 0,  
    },
    mediumJackPot:{
        type: data?.[JACKPOT_FIELDS.MEDIUM_JACKPOT.TYPE.name] || 0,
        count : data?.[JACKPOT_FIELDS.MEDIUM_JACKPOT.COUNT.name] || 0,
        value : data?.[JACKPOT_FIELDS.MEDIUM_JACKPOT.VALUE.name] || 0,  
    },
    smallJackPot:{
        type: data?.[JACKPOT_FIELDS.SMALL_JACKPOT.TYPE.name] || 0,
        count : data?.[JACKPOT_FIELDS.SMALL_JACKPOT.COUNT.name] || 0,
        value : data?.[JACKPOT_FIELDS.SMALL_JACKPOT.VALUE.name] || 0,  
    }
})