import ReactIdleTimer from "react-idle-timer";
import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { myLogout } from "Redux/Actions/PrintTableActions";

const IdleTimer = () => {
  const dispatch = useDispatch();
  const idleTimeRef = useRef(null);

  const onIdleHandler = () => {
    dispatch(myLogout());
  };

  return (
    <>
      <ReactIdleTimer
        ref={idleTimeRef}
        timeout={1000 * 60 * 5}
        onIdle={onIdleHandler}
      ></ReactIdleTimer>
    </>
  );
};

export default IdleTimer;
