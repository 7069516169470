// libs
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from "react-select";

// components
import { CustomTable } from 'Components';
// utils
import { indexHistory } from 'Services/Api/Constants';
import { getTransactions } from 'Redux/Actions/Common';
import { LABELS, UTILITIES, purchaseType } from 'Shared';
import { TRANSACTIONS_HEADINGS } from './Helpers/indedx';
import "./style.scss"
const TransactionHistory = () => {
  const dispatch = useDispatch();
  const limit = 10;
  const [pageCount, setPageCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [listData, setListData] = useState([]);
  const [search, setSearch] = useState('');
  const [dataStatus, setDataStatus] = useState(purchaseType[3]);
  const skipMountref = useRef(false);

  const handlePageClick = (pageData) => {
    let currentPage = pageData.selected;
    setCurrentIndex(currentPage);
    const payload = {
      skip: currentPage * limit,
      limit: 10,
      type: dataStatus.value,
      searchKey: search,
    };
    dispatch(
      getTransactions(payload, (data) => {
        setListData(data?.data);
      })
    );
  };

  useEffect(()=>{
    if(skipMountref.current) OnSubmit()
    else skipMountref.current = true
  },[dataStatus])

  useEffect(() => {
    const payload = {
      limit: 10,
      skip: 0,
      type: dataStatus.value,
      searchKey: search,
    };
    dispatch(
      getTransactions(payload, (data) => {
        setListData(data?.data);
        setPageCount(data?.count);
      })
    );
  }, []);

  const OnSubmit = (e) => {
    const payload = {
      limit: 10,
      skip: 0,
      type: dataStatus.value,
      searchKey: search,
    };
    dispatch(
      getTransactions(payload, (data) => {
        setListData(data?.data);
        setPageCount(data?.count);
        setCurrentIndex(0);
      })
    );
  };

  const updateTransactionType = (item) => {
    switch (item.type) {
      case 1:
        return "Android IAP"
      case 2:
        return "Paypal" 
      case 3:
        return "IOS IAP" 

      default:
        return null
    }
        
  }

  return (
    <div className="container-fluid page-body-wrapper">
      <div className="card mt-4">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-sm-4 mb-3">
              <h4 className="h6 mb-0">{LABELS.TRANSACTION_HISTORY}</h4>
            </div>

            <div className="col-sm-8 mb-3">
            <div className="search_bar transaction-search input-group ms-auto">
                <Select
                  options={purchaseType}
                  placeholder={"Select Transaction Type"}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    setDataStatus(e)
                    
                  }}
                  value={dataStatus}
                />

                <input
                  type="text"
                  placeholder="Enter email / UID / Username"
                  className="form-control"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <div className="input-group-prepend">
                  <button
                    className="btn btn-md btn-primary"
                    onClick={() => {
                      OnSubmit();
                    }}
                  >
                    {LABELS.SUBMIT}
                  </button>
                </div>
              
              </div>
            </div>
          </div>

          <div className="row">
            {listData?.length ? (
              <div className="col-md-12">
                <CustomTable
                  isPagination
                  headings={TRANSACTIONS_HEADINGS}
                  pageCount={Math.ceil(pageCount / limit)}
                  currentIndex={currentIndex}
                  handlePageClick={handlePageClick}
                >
                  {listData?.map((item, index) => {
                    return (
                      <tr key={item?.id}>
                        <td>{indexHistory(currentIndex) + index}</td>
                        <td>{item?.userName}</td>
                        <td>{item?.email}</td>
                        <td>{updateTransactionType(item)}</td>
                        <td>{UTILITIES.renderDate(item?.txnTime)}</td>
                        <td>{item?.transactionId || "N/A"}</td>
                        <td>{item?.uid}</td>
                        <td>{item?.itemType}</td>
                        <td>{Math.trunc(item?.value * 100) / 100}</td>
                      </tr>
                    );
                  })}
                </CustomTable>
              </div>
            ) : (
              <p className="d-flex justify-content-center">{LABELS.NO_RECORDS_FOUND}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
