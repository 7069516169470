// libs
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
// components
import { Button, DropDown, TextField } from "../../atoms";
// actions
import { snakeCategory } from "Redux/Actions/PrintTableActions";

const AdminForm = ({ handleSubmit, /* setCategory *//*  category */validate, loader,groupId,groupIdsDataRedux, setGroupId, callGroupId = () => {}}) => {
  const optionsGroupIdsKeys = [];
  const [optionsGroupsIds, setOptionsGroupIds] = useState([]);
  const [snakeNameBasedOnGroupId, setSnakeNameBasedOnGroupId] = useState("");
  const [category, setCategory] = useState([])
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  //just for managing button text while submititng Data 
  const [trial, setTrial] = useState(false)

  //setting GroupIds on the bases of category
  const setGroupIdsOptions = () => {
    for (const key of Object.keys(groupIdsDataRedux)) {
      optionsGroupIdsKeys.push(groupIdsDataRedux[key]);
    }

    let optionsGroup = optionsGroupIdsKeys.map((item) => {
      return {
        label: item.GROUP,
        value: item.SNAKE_NAME,
      };
    });
    //setting groupId Dropdown option using state variable
    setOptionsGroupIds(optionsGroup);
  };

  //setting SnakeName in Textbox using GroudId Dropdwon values
  const setSnakeNameAccordingToGroupId = (e) => {
    setSnakeNameBasedOnGroupId(e.value);
  };

  const TrialMethod = ()=>{
     setTrial(true)
  }

  useEffect(() => {
    setGroupIdsOptions();
  }, [groupIdsDataRedux]);

  //pasting new code

  useEffect(()=>{
    // console.log("inside the useEffect")
    dispatch(snakeCategory((data)=>{
      // console.log(data)
      setCategory(data.categories)
    }))
  },[])

  return (
    <Formik
      onSubmit={(value, { resetForm }) => {
        handleSubmit(
          value,
          resetForm,
          snakeNameBasedOnGroupId 
        );
        setSnakeNameBasedOnGroupId("");
      }}
      enableReinitialize
      validationSchema={validate}
      initialValues={{
        category: "",
        metaKey: "",
        groupId: " ",
        snakeName: "",
        description: "",
        damage: "",
        speed: "",
        health: "",
        magnet: "",
        fireball: "",
        bomb: "",
        star: "",
        startIndex: "",
        nftCount: "",
        imageURL: "",
      }}
    >
      {(props) => (
        <>
          <Form> 
            <div className="row">
              <div className="col-md-6 col-lg-4 col-xl-3">
                <DropDown
                  id={groupIdsDataRedux}
                  value={category}
                  name={"category"}
                  label={"Category"}
                  placeholder={"Select Category"}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={category} 
                  handleChange={(e) => {
                    if (e.value == 2 || e.value == 1) {
                      setShow(true);
                    } else {
                      setShow(false);
                    }

                    setCategory(e);
                    setGroupId(""); //whiel change in categroy we made dropdwon value empty
                    setSnakeNameBasedOnGroupId(""); // same like groupId it makes textbox empty when change in categroy
                    callGroupId(e.value);
                    props.setFieldValue("category", e);
                  }} />  
                </div>

              {show && (<div className="col-md-6 col-lg-4 col-xl-3">
                  <TextField
                    placeholder="Enter value"
                    label={"Meta Key"}
                    name={"metaKey"}
                    type="number"
                  /> </div>)}

              <div className="col-md-6 col-lg-4 col-xl-3">
                <DropDown
                  id={optionsGroupsIds}
                  value={groupId}
                  name={"groupId"}
                  label={"Group Id"}
                  placeholder={"Select Category"}
                  handleChange={(e) => {
                    setGroupId(e);
                    setSnakeNameAccordingToGroupId(e);
                    props.setFieldValue("groupId", e);
                  }}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={optionsGroupsIds}
                /> </div>

              <div className="col-md-6 col-lg-4 col-xl-3">
                <TextField
                  value={snakeNameBasedOnGroupId}
                  disabled="true "
                  label={"Snake name"}
                  name={"snakeName"}
                  type="text"
                /> </div>

              <div className="col-md-6 col-lg-4 col-xl-3">
                <TextField
                  label={"Description"}
                  name={"description"}
                  type="text"
                  textAria="true"
                />
              </div>

              <div className="col-md-6 col-lg-4 col-xl-3">
                <TextField
                  label={"Damage"}
                  name={"damage"}
                  placeholder={"Enter damage"}
                  type="number"
                  step={"0.01"}
                  min={1}
                /> </div>

              <div className="col-md-6 col-lg-4 col-xl-3">
                <TextField
                  label={"Speed"}
                  name={"speed"}
                  placeholder={"Enter speed"}
                  type="number"
                  step={"0.01"}
                  min={1}
                /> </div>

              <div className="col-md-6 col-lg-4 col-xl-3">
                <TextField
                  label={"Health"}
                  name={"health"}
                  placeholder={"Enter health"}
                  type="number"
                  step={"0.01"}
                  min={1}
                /> </div>

              <div className="col-md-6 col-lg-4 col-xl-3">
                <TextField
                  label={"Magnet"}
                  name={"magnet"}
                  placeholder={"Enter magnet"}
                  type="number"
                  min={1}
                  step={"0.01"}
                /> </div>

              <div className="col-md-6 col-lg-4 col-xl-3">
                <TextField
                  label={"Fireball"}
                  name={"fireball"}
                  placeholder={"Enter fireball"}
                  type="number"
                  min={1}
                  step={"0.01"}
                /> </div>

              <div className="col-md-6 col-lg-4 col-xl-3">
                <TextField
                  label={"Bomb"}
                  name={"bomb"}
                  placeholder={"Enter bomb"}
                  type="number"
                  min={1}
                  step={"0.01"}
                /> </div>

              <div className="col-md-6 col-lg-4 col-xl-3">
                <TextField
                  label={"star"}
                  name={"star"}
                  placeholder={"Enter star"}
                  type="number"
                  min={0}
                  step={"0.01"}
                /> </div>

              <div className="col-md-6 col-lg-4 col-xl-3">
                <TextField
                  label={"Start Index"}
                  name={"startIndex"}
                  placeholder={"Enter start index"}
                  type="number"
                  min={1}
                /> </div>

              <div className="col-md-6 col-lg-4 col-xl-3">
                <TextField
                  label={"NFT count"}
                  name={"nftCount"}
                  placeholder={"Enter count"}
                  type="number"
                  min={1}
                /> </div>

              <div className="col-md-6 col-lg-4 col-xl-3">
                <TextField
                  label={"Image Url"}
                  name={"imageURL"}
                  placeholder={"Enter url"}
                  type="text"
                /> </div>

              <div className="col-md-6 col-lg-4 col-xl-3">
                <label className="d-none d-lg-block"> &nbsp;</label>
                <Button onClick={TrialMethod}  className="btn btn-md btn-secondary" type="submit">
                  {(trial && loader) ? "Posting.." : "Add Data"}
                </Button>
              </div>
            </div>
          </Form>
        </> )}
    </Formik>
  );};
export default AdminForm;
