// libs
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { SnackbarProvider } from "notistack";
// components
import RootRouter from "./Routes/RootRouter";
import { Loader } from "Components";
// utils
import { store, persistor } from "./Redux/Store";

function App() {
 
  return (
    <SnackbarProvider maxSnack={3}>
     
      <Provider store={store}>
        <Loader />
        <PersistGate persistor={persistor}>
          <RootRouter />
        </PersistGate>
      </Provider>
    </SnackbarProvider>
  );
}

export default App;
