import { LABELS } from 'Shared';
import { TOURNAMENT_REWARD_STATUS } from 'Shared/Constants';

export const TEAM_TOURNAMENT_HEADINGS = [LABELS.S_NO, LABELS.TOURNAMENT, LABELS.STATUS, LABELS.ACTION];
export const WINNER_TEAM_TABLE_HEADINGS = [
  LABELS.TEAM_NAME,
  LABELS.RANK,
  LABELS.GAME_PLAYED,
  LABELS.GOLD,
  LABELS.STATUS,
  LABELS.ACTION,
];

export const handleShowTeamStatus = (value) => {
  switch (value) {
    case TOURNAMENT_REWARD_STATUS.PENDING:
      return LABELS.PENDING;
    case TOURNAMENT_REWARD_STATUS.COMPLETED:
      return LABELS.COMPLETED;
    default:
      return '';
  }
};
