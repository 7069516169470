// libs
import React, { useEffect, useState } from 'react';
// hooks
import { useDispatch } from 'react-redux';
// components
import { ViewTournamentDetails } from './Components';
import { CustomTable } from 'Components';
// utils
import viewIcon from 'assets/images/icons/eye_icon.svg';
import { indexHistory } from 'Services/Api/Constants';
// helpers
import { TEAM_TOURNAMENT_HEADINGS } from './Helpers';
// actions
import { getTeamTournamentWinners } from 'Redux/Actions/Team';
import { LABELS, TOURNAMENT_REWARD_STATUS } from 'Shared';


function TeamTournamentWinners() {
  // hooks
  const dispatch = useDispatch();

  // states
  const [isModalOpen, setIsModalOpen] = useState('');
  const [listData, setListData] = useState([]);

  useEffect(() => {
    fetchListData();
  }, []);

  const fetchListData = () => {
    dispatch(
      getTeamTournamentWinners({}, (data) => {
        setListData(data?.data);
      })
    );
  };

  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <div className="card mt-4">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-sm-4 mb-3">
                <h4 className="h6 mb-0">{LABELS.TEAM_TOURNAMENT_WINNERS}</h4>
              </div>
            </div>

            <div className="row">
              {listData?.length ? (
                <div className="col-md-12">
                  <CustomTable headings={TEAM_TOURNAMENT_HEADINGS}>
                    {listData?.map((item, index) => {
                      return (
                        <tr key={item?.name}>
                          <td>{indexHistory(0) + index}</td>
                          <td>{item?.name || ''}</td>
                          <td>{item?.status === TOURNAMENT_REWARD_STATUS.COMPLETED ? LABELS.COMPLETED : LABELS.PENDING}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-primary mx-1"
                              onClick={() => setIsModalOpen(item?.name)}
                            >
                              <img src={viewIcon} alt="view" width="20" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </CustomTable>
                </div>
              ) : (
                <p className='d-flex justify-content-center'>{LABELS.NO_TEAMS_FOUND}</p>
              )}
            </div>
          </div>
        </div>

        <ViewTournamentDetails
          isOpen={!!isModalOpen}
          handleModalClose={() => setIsModalOpen('')}
          currentId={isModalOpen}
        />
      </div>
    </>
  );
}

export default TeamTournamentWinners;
