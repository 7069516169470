// libs
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import { withSnackbar } from "notistack";
// components
import TableHeading from "./Screen/TableHeading";

// actions
import { putReddeemRequest, royality_Data } from "Redux/Actions/PrintTableActions";
import { GetDefaultValue, indexHistory, Status } from "Services/Api/Constants";
import { LABELS } from "Shared";

const CashList = ({ enqueueSnackbar }) => {


  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [cashData, setCashData] = useState([]);
  const [search, setSearch] = useState("");
  const divref = useRef(null);

  



  const limit = 10;
  
  const updateReddemRequest = (selected, id) => {
    if(selected.value == 1){
      return ;
    }
    const payload = id;

    dispatch(
      putReddeemRequest(payload, (data) => {
        enqueueSnackbar(data.data.message, {
          variant: data.status ? "success" : "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        const payload = {
          type:3,
          skip: 0,
          limit: 10,
          searchKey : ""
        };
        dispatch(
          royality_Data(payload, (data) => {
            setCashData(data?.data);
          })
        );
      })
    );
  };

  const handlePageClick = (pageData) => {
    let currentPage = pageData.selected;
    setCurrentIndex(currentPage);
    const payload = {
      type:3,
      skip: currentPage * limit,
      limit: 10,
      searchKey:search
    };
    dispatch(
      royality_Data(payload, (data) => {
        setCashData(data?.data);
      })
    );
  };

  useEffect(() => {
    const payload = {
      type:3,
      skip: 0,
      limit: 10,
      searchKey:search
    };
    dispatch(
      royality_Data(payload, (data) => {
        let tempPageCount = Math.ceil(data?.count / 10);
        setPageCount(tempPageCount);
        setCashData(data?.data);
      })
    );
  }, [])

  const OnSubmit = () => {
    const payload = {
          limit: 10,
          skip: 0,
          type:3,
          searchKey:search
        };
        dispatch(
          royality_Data(payload, (data) => {
            let tempPageCount = Math.ceil(data?.count / 10);
            setCashData(data?.data);
            setPageCount(tempPageCount);
            setCurrentIndex(0);
          })
        );
  
    }


  return <>

    <div className="container-fluid page-body-wrapper"> 
      
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-md-4">
              <h4 className="h6 mb-3">{LABELS.REDEEM_CASH_LIST}</h4>
            </div>
            <div className="col-md-8 mb-3">
              <div className="search_bar input-group ms-auto">
                <input
                type="text"
                placeholder="Enter email/name"
                className="form-control"
                onChange={(e)=>{
                  setSearch(e.target.value)
                }}

                />
                <div className="input-group-prepend">
                  <button className="btn btn-md btn-primary" onClick={()=>OnSubmit()}>{LABELS.SUBMIT}</button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive" ref={divref}>
            {/* <Table className="table table-bordered"> */}
            <table className={"table table-bordered"}>
              <TableHeading />
              <tbody className="table-light table-bordered">
                      {cashData.map((item,index) => {
                        return (
                          <tr key={item?.id}>
                            <td>{indexHistory(currentIndex) + index}</td>
                            <td>{moment(item.createdAt).format("L")}</td>
                            <td>{item?.userName}</td>
                            <td>{item?.email}</td>
                            <td>{item?.amount}</td>
                            {/* <td>{item?.amountInUSD}</td> */}
                            <td>{item?.userId}</td>
                           
                            {/* <td>{item?.walletAddress}</td> */}
                            <td>
                              <Select
                              // isDisabled={true}
                              isSearchable={false}
                                defaultValue={() =>
                                  GetDefaultValue(item?.status)
                                }
                                name={"groupId"}
                                onChange={(e) => {
                                  updateReddemRequest(e, item?.id);
                                }}
                                onMenuOpen={(e)=>{
                                  if(index == cashData.length - 1  || index == cashData.length - 2){
                                    let divElement = document.querySelector(".table-responsive");
                                    let height = divElement.offsetHeight ;
                                    divElement.style.height = height + 82 + "px";                              
                                  }
                                }}
                                onMenuClose={(e)=>{
                                  if(index == cashData.length - 1  || index == cashData.length - 2){
                                    divref.current.style.height = null;                            
                                  }
                                }}
                                isDisabled={item?.status == 2}
                                placeholder={"Select Status"}
                                options={Status}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
              {/* <TableBody cashData={cashData} currentIndex={currentIndex} /> */}
            </table>
          </div>
          <ReactPaginate
            previousLabel=""
            nextLabel=""
            breakLabel="..."
            pageCount={pageCount}
            marginPagesDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName="pagination justify-content-center mt-4"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLinkClassName="page-link"
            breakClassName="page-item"
            activeClassName="active"
            forcePage={currentIndex}
          />
        </div>
      </div>
    </div>
  </>
}

export default withSnackbar(CashList);