import React, { useEffect, useState } from "react";
import { Table } from "Components";
import { useDispatch } from "react-redux";
import { getTournamentGameUsers } from "Redux/Actions/Common";
import { indexHistory } from "Services/Api/Constants";
import ReactPaginate from "react-paginate";

const GameUserListModal = ({tournamentDetails = {} }) => {
    const dispatch = useDispatch();
  const limit = 10;
  const [pageCount, setPageCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [listData, setListData] = useState([]);
//   const [search, setSearch] = useState("");

  const handlePageClick = (pageData) => {
    let currentPage = pageData.selected;
    setCurrentIndex(currentPage);
    const payload = {
      skip: currentPage * limit,
      limit: 10,
      roomId: tournamentDetails.id,
    };
    dispatch(
      getTournamentGameUsers(payload, (data) => {
        setListData(data?.data);
      })
    );
  };
  useEffect(() => {
    if(tournamentDetails?.id){
        const payload = {
          limit: 10,
          skip: 0,
          roomId: tournamentDetails.id,
        };
        dispatch(
            getTournamentGameUsers(payload, (data) => {
            setListData(data?.data);
            setPageCount(data?.count);
          })
        );
    }
    return ()=>{
      setListData([]);
    }
  }, []);

  return (
       <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-4 mb-3">
              <h4 className="h6 mb-0">Game User List</h4>
            </div>
          </div>

          <div className="row">
            {listData?.length ? (
              <div className="col-md-12">
                <div className="table-responsive">
                  <Table className="table table-bordered">
                    <thead className="bg-dark text-white align-content-center">
                      <tr>
                        <th> S.no </th>
                        <th> Username </th>
                        <th> Email </th>
                        <th>UID</th>
                        <th>Respawn Count</th>
                        <th>Rank </th>
                        <th> Cash Point </th>
                        <th> Score </th>
                      </tr>
                    </thead>{" "}
                    <tbody className="table-light table-bordered table-padding">
                      {listData?.map((item, index) => {
                        return (
                          <tr key={item?.id}>
                            <td>{indexHistory(currentIndex) + index}</td>
                            <td>{item?.UserName}</td>
                            <td>{item?.Email}</td>
                            <td>{item?.uid}</td>
                            <td>{item?.respawnCount ||  0}</td>
                            <td>{item?.Rank}</td>
                            <td>{item?.cashPoint || 0}</td>
                            <td>{item?.Gold}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>

                <ReactPaginate
                  previousLabel=""
                  nextLabel=""
                  breakLabel="..."
                  pageCount={Math.ceil(pageCount / 10)}
                  marginPagesDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName="pagination justify-content-center mt-4"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLinkClassName="page-link"
                  breakClassName="page-item"
                  activeClassName="active"
                />
              </div>
            ) : (
              <p className="text-center h5">No Users Found </p>
            )}
          </div>
    </div>
  );
};

export default GameUserListModal;
