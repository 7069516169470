// libs
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withSnackbar } from "notistack";
// components
import Button from "../../Components/atoms/Button";
import { IOSSwitch } from "Components";
// utils
import { allowBots, fetchBotStatus } from "Redux/Actions/AllowBot";
import { LABELS } from "Shared";

const AllowBots = ({enqueueSnackbar}) => {
  const [checked, setChecked] = useState(false);

  const dispatch = useDispatch();

  const updateAllowBots = () => {
    const payload = {
      isBotAllowed : checked
    };
    dispatch(
      allowBots(payload, (data) => {
        enqueueSnackbar(data.data.message, {
          variant: data.status===200 ? "success" : "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      })
    );
  };
  useEffect(() => {
    dispatch(
      fetchBotStatus((data) => {
        setChecked(data?.data?.isBotsAllowed || false);
      })
    );
  }, []);

  const handleChange = (e)=>{
      setChecked(e.target.checked);
  }
  return (
    <div className="container-fluid page-body-wrapper">
      <h2 className="h4">{LABELS.ALLOW_BOTS}</h2>
      <div className="row">
        <div className="col-md-6 col-lg-7">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group toggle-space-between">
                    <div className="">
                      <label>{LABELS.IS_BOTS_ALLOWED}</label>
                    </div>
                    <div className="">
                      <IOSSwitch onChange={handleChange} checked={checked}/>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <Button
                    className="btn btn-md btn-secondary"
                    onClick={() => {
                      updateAllowBots();
                    }}
                  >
                    {LABELS.UPDATE}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSnackbar(AllowBots);