import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import BigMediumSmallJackpot from "../BigMediumSmallJackpot";
import { getJackpotData, updateJackpotData } from "Redux/Actions";
import { LABELS, VAL_MESSAGES } from "Shared";
import {
  customJackPotValidations,
  transformJackpotApiToLocal,
  transformJackpotLocalToApi,
} from "Views/Jackpot/helpers";
import { Button } from "Components";

const DefaultJackpot = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [formValues, setFormValues] = useState({});
  //   const [errors, setErrors] = useState({});

  const fetchJackpotData = () => {
    dispatch(
      getJackpotData((data, status) => {
        if (status == 200) {
          setFormValues(transformJackpotApiToLocal(data?.data));
        } else {
          enqueueSnackbar(data?.data?.message || LABELS.SOMETHING_WENT_WRONG, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })
    );
  };

  useEffect(() => {
    fetchJackpotData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    // let tempErrors = customJackPotValidations(formValues);
    // if(Object.keys(tempErrors)?.length) {
    //     setErrors(tempErrors)
    //     return
    // }

    dispatch(
      updateJackpotData(
        transformJackpotLocalToApi(formValues),
        (data, status) => {
          enqueueSnackbar(status == 200 ? data?.message : data?.data?.message, {
            variant: status == 200 ? "success" : "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      )
    );
  };

  const handleChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    // setErrors({ ...errors, [name]: value ? "" : VAL_MESSAGES.REQUIRED });
  };

  return (
    <form>
      <BigMediumSmallJackpot
        values={formValues}
        // errors={errors}
        handleChange={handleChange}
      />

      <div className="d-flex justify-content-center">
        <Button
          type="submit"
          className="btn btn-md btn-secondary text-center"
          onClick={handleSubmit}
        >
          {LABELS.SAVE}
        </Button>
      </div>
    </form>
  );
};

export default DefaultJackpot;
