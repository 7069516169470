// libs
import React, { forwardRef } from 'react';
import ReactPaginate from 'react-paginate';

const CustomTable = forwardRef(({
  headings = [],
  marginPagesDisplayed = 3,
  pageCount = 0,
  handlePageClick = () => {},
  currentIndex = '',
  children,
  isPagination = false,
},ref) => {
  return (
    <>
      <div className="table-responsive" ref={ref} >
        <table className={'table table-lg table-bordered'}>
          <thead className="bg-dark text-white align-content-center">
            <tr>
              {headings.map((item) => (
                <th key={item}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody className="table-light table-bordered">{children}</tbody>
        </table>
      </div>
      {isPagination ? (
        <ReactPaginate
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          pageCount={pageCount}
          marginPagesDisplayed={marginPagesDisplayed}
          onPageChange={handlePageClick}
          containerClassName="pagination justify-content-center mt-4"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLinkClassName="page-link"
          breakClassName="page-item"
          activeClassName="active"
          forcePage={currentIndex}
        />
      ) : null}
    </>
  );
});

export default CustomTable;
