
//hooks
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

//utils
import { getUsersRewardsList } from 'Redux/Actions';
import { indexHistory } from 'Services/Api/Constants';
import { CONSTANTS } from './Utils';
import { LABELS, UTILITIES } from 'Shared';
import { CustomTable, ReadMore } from 'Components';
import { RenderKeyValues } from './components';

const UserRewardsHistory = () => {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [listData, setListData] = useState([]);
  const [search, setSearch] = useState('');
  const limit = 10;

  const handlePageClick = (pageData) => {
    let currentPage = pageData.selected;
    setCurrentIndex(currentPage);
    const payload = {
      skip: currentPage * limit,
      limit: limit,
      searchKey: search,
    };
    dispatch(
      getUsersRewardsList(payload, (data) => {
        let tempPageCount = Math.ceil(data?.count / limit);
        setPageCount(tempPageCount);
        setListData(data?.data);
      })
    );
  };

  useEffect(() => {
    const payload = {
      skip: 0,
      limit: limit,
      searchKey: search,
    };
    dispatch(
      getUsersRewardsList(payload, (data) => {
        let tempPageCount = Math.ceil(data?.count / limit);
        setPageCount(tempPageCount);
        setListData(data?.data);
      })
    );
  }, []);

  const OnSubmit = () => {
    const payload = {
      limit: limit,
      skip: 0,
      searchKey: search,
    };
    dispatch(
      getUsersRewardsList(payload, (data) => {
        let tempPageCount = Math.ceil(data?.count / limit);
        setListData(data?.data);
        setPageCount(tempPageCount);
        setCurrentIndex(0);
      })
    );
  };

  const renderResult = (result) => {
    if (result === true) {
      return <span className="badge bg-success">{LABELS.WON}</span>;
    } else if (result === false) {
      return <span className="badge bg-danger">{LABELS.LOST}</span>;
    }else{
      return "N/A";
    }
  };

  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-md-4">
                <h4 className="h6 mb-3">{LABELS.USER_REWARDS_HISTORY}</h4>
              </div>
              <div className="col-md-8 mb-3">
                <div className="search_bar input-group ms-auto">
                  <input
                    type="text"
                    placeholder="Enter UID/Username/Email"
                    className="form-control"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  <div className="input-group-prepend">
                    <button className="btn btn-md btn-primary" onClick={() => OnSubmit()}>
                      {LABELS.SUBMIT}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {listData?.length ? (
              <div className="col-md-12">
                <CustomTable
                  isPagination
                  headings={CONSTANTS.TABLE_HEADINGS}
                  pageCount={pageCount}
                  currentIndex={currentIndex}
                  handlePageClick={handlePageClick}
                >
                  {listData?.map((item, index) => (
                    <tr key={'user' + index}>
                      <td>{indexHistory(currentIndex) + index}</td>
                      <td>{UTILITIES.renderDate(item.date)}</td>
                      <td>{item?.userName}</td>
                      <td>
                        <ReadMore maxCharacterCount={10}>
                          {item?.uid || ""}
                        </ReadMore>
                      </td>
                      <td>
                        <ReadMore maxCharacterCount={10}>
                          {item?.roomId || "--"}
                        </ReadMore>
                      </td>
                      <td>{item?.tournamentType}</td>
                      <td>{item?.score || '--'}</td>
                      <td>{item?.cashpoints ? Math.trunc(item?.cashpoints * 100000) / 100000 : '--'}</td>
                      <td>{item?.rewardBalance || '--'}</td>
                      <td>
                        <RenderKeyValues values={item?.itemsBeforeReward} />
                      </td>

                      <td>{item?.mProtectLoss || 0}</td>
                      <td>{item?.cashPointLoss || 0}</td>

                      <td>
                        <RenderKeyValues values={item?.reward} />
                      </td>
                      <td>
                        <RenderKeyValues values={item?.itemsAfterReward} />
                      </td>
                      <td>{renderResult(item?.isWin)}</td>
                    </tr>
                  ))}
                </CustomTable>
              </div>
            ) : (
              <p className="d-flex justify-content-center">{LABELS.NO_RECORDS_FOUND}</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserRewardsHistory;
