const TableHeading = () => {
    return (
      <thead className="bg-dark text-white align-content-center">
        <tr>
        <th> S.no </th>
          <th> Name </th>
          <th> Game Code </th>

          <th> Date</th>
          <th> Entry Fee (mDollar)</th>
          <th> Entry Fee (winPoint)</th>
          <th>Game ID</th>
        
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
    );
  };
  
  export default TableHeading;