import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Button, IOSSwitch, InputField } from "Components";
import { getCustomJackpotData, updateCustomJackpotData } from "Redux/Actions";
import { LABELS, VAL_MESSAGES } from "Shared";
import moment from "moment";
import BigMediumSmallJackpot from "../BigMediumSmallJackpot";
import {
  JACKPOT_FIELDS,
  customJackPotValidations,
  transformJackpotApiToLocal,
  transformJackpotLocalToApi,
} from "Views/Jackpot/helpers";
import { STATUS_CODES } from "Services/Api/Constants";

const CustomJackpot = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [formValues, setFormValues] = useState({});
  //   const [errors, setErrors] = useState({});

  const fetchJackpotData = () => {
    dispatch(
      getCustomJackpotData((data, status) => {
        if (status == STATUS_CODES.SUCCESS) {
          setFormValues(transformJackpotApiToLocal(data?.data, true));
        } else {
          enqueueSnackbar(data?.data?.message || LABELS.SOMETHING_WENT_WRONG, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })
    );
  };

  useEffect(() => {
    fetchJackpotData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      moment(formValues?.[JACKPOT_FIELDS.START_TIME.name], "HH:mm").isAfter(
        moment(formValues?.[JACKPOT_FIELDS.END_TIME.name], "HH:mm")
      )
    ) {
      enqueueSnackbar(LABELS.START_TIME_GREATER_THAN_END_TIME, {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }

    // let tempErrors = customJackPotValidations(formValues);
    // if(Object.keys(tempErrors?.length)) {
    //     setErrors(tempErrors)
    //     return
    // }

    dispatch(
      updateCustomJackpotData(
        transformJackpotLocalToApi(formValues, true),
        (data, status) => {
          enqueueSnackbar(status == 200 ? data?.message : data?.data?.message, {
            variant: status == 200 ? "success" : "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      )
    );
  };

  const handleChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    // setErrors({ ...errors, [name]: value ? "" : VAL_MESSAGES.REQUIRED });
  };

  return (
    <form>
        <div className="form-group mt-3">
            <label className="me-5" >{JACKPOT_FIELDS.ACTIVE.label}</label>
            <IOSSwitch
            name={JACKPOT_FIELDS.ACTIVE.name}
            onChange={(e) => handleChange(e.target.name, e.target.checked)}
            checked={formValues?.[JACKPOT_FIELDS.ACTIVE.name]}
            />
        </div>
      <div className="row mb-3">
        <div className="col-md-6 col-lg-6">
          <InputField
            className="form-control"
            label={JACKPOT_FIELDS.START_TIME.label}
            name={JACKPOT_FIELDS.START_TIME.name}
            type="time"
            value={formValues?.[JACKPOT_FIELDS.START_TIME.name]}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </div>
        <div className="col-md-6 col-lg-6">
          <InputField
            className="form-control"
            label={JACKPOT_FIELDS.END_TIME.label}
            name={JACKPOT_FIELDS.END_TIME.name}
            type="time"
            value={formValues?.[JACKPOT_FIELDS.END_TIME.name]}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </div>
      </div>

      <BigMediumSmallJackpot
        values={formValues}
        // errors={errors}
        handleChange={handleChange}
      />

      <div className="d-flex justify-content-center">
        <Button
          type="submit"
          className="btn btn-md btn-secondary text-center"
          onClick={handleSubmit}
        >
          {LABELS.SAVE}
        </Button>
      </div>
    </form>
  );
};

export default CustomJackpot;
