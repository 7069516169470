// libs
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
// components
import AdminForm from "./AdminForm";
import IdleTimer from "./IdelTimer";
import { DisplayNftData } from "Components";
// actions
import { getFormData, getGroupIds, postFormData, putFormData, } from "Redux/Actions/PrintTableActions";
// utils
import LoaderImage from "../../atoms/Images/Loader.gif";
import { successCallBack } from "Services/Api/Constants";
// styles
import "react-toastify/dist/ReactToastify.css";
import "./style.scss";
import { LABELS } from "Shared";

const CreateNFT = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const [category, setCategory] = useState("");
  const [groupId, setGroupId] = useState("");
  const limit = 10;
  const [skip, setSkip] = useState(0)
  const [indexForSearch, setIndexForSearch] = useState({
    start: "",
    end: ""
  })

  const [minStatus, setMintStatus] = useState("")
  //maing react select presnet in dipalynftdaya empty using this state
  const [minted] = useState()

  const loader = useSelector((state) => state.printTable.loader);
  const groupIdsDataRedux = useSelector((state) => state.printTable.groupId);
  const dispatch = useDispatch();
  const nftFormData = useSelector((state) => state.printTable.tableData);
  const pageCount = useSelector((state) => state.printTable.pageCountNft);
  const validate = Yup.object({
    category: Yup.object().required("Required").nullable(),
    metaKey: Yup.number().when("category", {
      is: "Yes",
      then: Yup.number().min(1, "mininum amount will be 1").required("Required")
    }),
    groupId: Yup.object().required("Required").nullable(),
    // snakeName: Yup.string().required("Required"),
    description: Yup.string()
      .trim()
      .min(1, "Cant enter white spaces in front of text")
      .max(6000, "Nice try, nobody has a title that long")
      .required("Required"),
    damage: Yup.number().min(1, "mininum level will be 1").required("Required"),
    speed: Yup.string().min(1, "mininum level will be 1").required("Required"),
    health: Yup.number().min(1, "mininum level will be 1").required("Required"),
    magnet: Yup.number().min(1, "mininum level will be 1").required("Required"),
    fireball: Yup.number()
      .min(1, "mininum level will be 1")
      // .max(11, "max level will be 11")
      .required("Required"),
    bomb: Yup.number().min(1, "mininum level will be 1").required("Required"),
    imageURL: Yup.string()
      .trim()
      .min(1, "Cant enter white spaces in front of text")
      .required("Required"),
    star: Yup.number().min(0, "mininum level will be 0").required("Required"),
    startIndex: Yup.number()
      .min(1, "mininum level will be 1")
      //  .max(11, "max level will be 11")
      .required("Required"),
    nftCount: Yup.number()
      .min(1, "mininum level will be 1")
      .required("Required"),
  });

  const handleSubmit = (value, resetForm, snakeNameBasedOnGroupId) => {

    const payload = {
      category: value.category.value,
      metaKey: value.metaKey || 0,
      groupId: value.groupId.label,
      snakeName: snakeNameBasedOnGroupId,
      description: value.description,
      damage: value.damage,
      speed: value.speed,
      health: value.health,
      magnet: value.magnet,
      fireball: value.fireball,
      bomb: value.bomb,
      star: value.star,
      startIndex: value.startIndex,
      nftCount: value.nftCount,
      imageUrl: value.imageURL,
    };

    dispatch(
      postFormData(payload, (responseMessage) => {
        successCallBack(responseMessage);
        RefreshDisplayNFTData();// to refresh list using this funciton
        // setImageEmtpyCallBackFn();
      })
    );
    //reseting form values
    resetForm({ value: "" });
    setCategory("");
    setGroupId(""); //this funciton is called to make input type file empty after form submittion presenet in AdminForm
  };

  // Geting Group Data based on Categroy  select in dropdwon
  const getGroupIdData = (id) => {
    const payload = id;
    dispatch(getGroupIds(payload));
  };

  const GetNftFormData = () => {
    dispatch(getFormData(skip, limit, "", "", ""));
  };

  const handleUpdatedData = (payload) => {
    dispatch(putFormData(payload, (responseMessage) => {
      successCallBack(responseMessage)
      RefreshDisplayNFTData();
    }))
  };

  const RefreshDisplayNFTData = () => {
    GetNftFormData();
  }

  const handlePageClick = (pageData) => {
    let currentPage = pageData.selected;
    setCurrentIndex(currentPage)
    setSkip(currentPage * limit)
    dispatch(getFormData(currentPage * limit, limit, indexForSearch.start, indexForSearch.end, minStatus));
  };

  //Fitler Serach Code start....
  const hitApiForSearch = (e, dataType) => {
    if (dataType == "indexWise") {
      //in case indexed are providied this will be done
      setIndexForSearch({ start: e.start, end: e.end })
      dispatch(getFormData(0, 10, e.start, e.end, minStatus))
    } else {
      //in Case all data button is hitted this will hapeedn
      setIndexForSearch({ start: "", end: "" })
      dispatch(getFormData(0, 10, "", "", minStatus));
    }

  };

  const setFilter = (e) => {
    setMintStatus(e.value)
    dispatch(getFormData(0, 10, indexForSearch.start, indexForSearch.end, e.value))

  }
  //useEffectsss 
  useEffect(() => {
    GetNftFormData();
  }, []);

  return (
    <>
      <IdleTimer />

      {loader && (
        <div className="loader">
          <img className="" src={LoaderImage} />
        </div>
      )}
      <div className="container-fluid page-body-wrapper">
        <h2 className='h4'>{LABELS.CREATE_NFT}</h2>
        <div className="row py-2">
          <div className="col-md-12"> 
            <div className="card">
              <div className="card-body">
                <AdminForm 
                  loader={loader}
                  handleSubmit={handleSubmit}
                  validate={validate}
                  category={category}
                  setCategory={setCategory}
                  groupId={groupId}
                  setGroupId={setGroupId}
                  callGroupId={getGroupIdData}
                  groupIdsDataRedux={groupIdsDataRedux}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 my-4">
            <h3 className='h4'>{LABELS.NFT_LISTING}</h3>
            <div className="card">
              <div className="card-body">
                <DisplayNftData
                currentIndex={currentIndex}
                  validate={validate}
                  data={nftFormData}
                  handleUpdatedData={handleUpdatedData}
                  searchStrg={hitApiForSearch}
                  setFilter={setFilter}
                  minted={minted}
                />

                <ReactPaginate
                  previousLabel=""
                  nextLabel=""
                  breakLabel="..."
                  pageCount={pageCount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName="pagination justify-content-center mt-4"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLinkClassName="page-link"
                  breakClassName="page-item"
                  activeClassName="active"
                />  
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default CreateNFT;

