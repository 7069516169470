// libs
import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
// components
import { Button, TextField } from "Components";
// utils
import { myLogin } from "Redux/Actions/PrintTableActions";
import Logo from 'logo.svg';
// styles
import "react-toastify/dist/ReactToastify.css";
import "./style.scss";

const Login = () => {
  const loader = useSelector((state) => state.printTable.loader);
  const dispatch = useDispatch();

  const validate = Yup.object({
    userName: Yup.string()
      .trim()
      .min(6, "Cant enter white spaces in front of text")
      .max(60, "Nice try, nobody has a title that long")
      .required("Required"),
    password: Yup.string()
      .trim()
      .min(1, "Cant enter white spaces in front of text")
      .max(60, "Nice try, nobody has a title that long")
      .required("Required"),
  });

  const handleSubmit = (value) => {
      const data = {
        email: value.userName,
        password: value.password,
      };

      dispatch(myLogin(data,(response)=>{
        if(response.message == "Login Successfully. "){
          toast.success(response.message, { position: "top-center" });
        }else{
          toast.error(response.message, { position: "top-center" });
        }
      }));
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100 form_front min-vh-100">
      <div className="logo_login mb-5 text-center"><img src={Logo} alt="Meta Rivals" width="150" className="img-fluid"/></div>
      <div className="form_card text-start">
          <Formik
            onSubmit={handleSubmit}
            validationSchema={validate}
            //enableReinitialize
            initialValues={{
              userName: "",
              password: "",
            }}
          >
            {(props) => (
              <>
                <Form>
                  <h2 className="h2 mb-5 text-center">Login</h2>
                  <div className="row">
                    <div className="col-md-12">
                      <TextField
                        label={"User Name"}
                        name={"userName"}
                        placeholder={"Enter User name"}
                        type="text"
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <TextField
                        label={"Enter Password"}
                        name={"password"}
                        placeholder={"Enter Password"}
                        type="password"
                        required
                      />
                    </div>

                    <div className="text-center w-100 mt-2">
                      <Button
                        className="btn btn-lg btn-primary"
                        type="submit"
                        disabled={loader}
                      >
                        {loader ? "Checking..." : "Login"}
                      </Button>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
       
        <ToastContainer />
      </div>
    </div>
  );
};

export default Login;
