import { takeLatest, all, put } from 'redux-saga/effects';
import { CATEGORY } from 'Redux/Actions/Admin';
import { startLoading, stopLoading } from 'Redux/Actions/LoaderAction';
import { API_URLS } from 'Services/Api/Constants';
import { getRequest } from 'Shared/Axios';

function* get_snake_category({ callback }) {
  try {
    yield put(startLoading());
    const { data = {} } = yield getRequest({
      API: API_URLS.CATEGORY,
    });
    callback(data);
  } catch (error) {
    callback(JSON.stringify(error));
  } finally {
    yield put(stopLoading());
  }
}

export default function* root() {
  yield all([takeLatest(CATEGORY, get_snake_category)]);
}
