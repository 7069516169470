import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import React, { useEffect, useState } from "react";
import { useDispatch} from "react-redux";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
import { editReferral } from "Redux/Actions/Referral";
import { useSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const EditModal = ({ isModalOpen, selectedData, handleClose = () => { }, fetchList = () => { } ,editData = {}}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [mDollar, setMDollar] = useState(0);
  const [expiry, setExpiry] = useState(0);

  const dispatch = useDispatch();

  useEffect(()=>{
    setMDollar(editData?.mDollar);
    setExpiry(editData?.expiryTime);
  },[editData]);

  const handleUpdate = () => {
    const payload = {
      mDollar: mDollar,
      expiryTime: expiry,
      referralCode : editData.referralCode
    };
    dispatch(
      editReferral(payload, (data) => {
        enqueueSnackbar(data.data.message, {
          variant: data.status === 200 ? "success" : "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        if(data.status === 200){
          fetchList();
          handleClose();
        }
      })
    );
  };

  return (
    <>
      <Dialog
        open={isModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        aria-describedby="alert-dialog-slide-description"
        className={"custom_modal"}
      >

        <DialogActions>
          <Button className="btn btn-dark" onClick={() => handleClose()} >Close</Button>
        </DialogActions>
        <DialogTitle className="text-center h4">EDIT REFERRAL </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">

          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Enter the MDollar value</label>
                    <input
                      type={"number"}
                      onChange={(e) => setMDollar(e.target.value)}
                      value={mDollar}
                      placeholder={"MDollar"}
                      className="form-control"
                      min={0}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>{"Enter the Expiry time (in days)"}</label>
                    <input
                      type={"text"}
                      pattern="[0-9]"
                      onChange={(e) => {
                        setExpiry(e.target.value.replace(/\D/g, ""));
                      }}
                      value={expiry}
                      placeholder={"Expiry Time"}
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-12 text-center">
                  <Button
                    className="btn btn-md btn-secondary"
                    onClick={handleUpdate}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </div>

          </DialogContentText>
        </DialogContent>

      </Dialog>

      <ToastContainer />
    </>
  );
};

export default EditModal;