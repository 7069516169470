// libs
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withSnackbar } from "notistack";
// utils
import { deleteGame, listGame } from "Redux/Actions/GamesAction";
import TrashbinIcon from "assets/images/icons/trashbin_icon.svg";

function DeleteModal({ currentID, name, handleModalClose, enqueueSnackbar  }) {
  const tempSkip = useSelector((state) => state.PrivateGame.skip);
  const count = useSelector((state) => state.PrivateGame.pageCount);

  const skipCheck = () => {
    if (count % 11 == 0) {
      return tempSkip - 10;
    } else {
      return tempSkip;
    }
  };

  const dispatch = useDispatch();
  return (
    <>
      <div className="confirmation_modal text-center">
        <em className="conf_icon">
          <img src={TrashbinIcon} width="50" alt="" />
        </em>
        <h2 className="h4 mt-4 mb-0 text-center">
          Are you sure want to delete {name}{" "}
        </h2>
        <div className="btn_group text-center mt-4">
          <button
            className="btn btn-md btn-grey m-2"
            onClick={() => handleModalClose()}
          >
            Cancel
          </button>
          <button
            className="btn btn-md btn-primary m-2"
            onClick={() => {
              dispatch(
                deleteGame(currentID, (
                  data
                ) => {
                  enqueueSnackbar(data.data.message, {
                    variant: data.status ? "success" : "error",
                    autoHideDuration: 3000,
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  });
                  console.log("data in delete",data)
                  const payload = {
                    status: 4,
                    limit: 10,
                    skip: skipCheck(),
                  };
                  dispatch(
                    listGame(payload, () => {})
                  );
                  handleModalClose();
                })
              );
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </>
  );
}

export default React.memo(withSnackbar(DeleteModal));
