import { takeLatest, all, put } from "redux-saga/effects";
import { STATUS_CODE } from "../../Shared/Constants";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "Shared/Axios";
import { startLoading, stopLoading } from "Redux/Actions/LoaderAction";
import {
  CREATE_GAMES,
  EDIT_GAMES,
  GAMES_LISTING,
  DELETE_GAMES,
  GET_GAME_DATA,
  modifyList,
  pageCount,
  USER_LIST,
  USER_DETAIL,
} from "Redux/Actions/GamesAction";

const api = require(`../../Services/Api/Constants`);

function* post_create_games({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({
      API: api.API_URLS.POST_CREATE_GAMES,
      DATA: payload,
    });
    callback(response);
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* post_edit_games({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield putRequest({
      API: `${api.API_URLS.PUT_EDIT_GAMES}` + `${payload.currentID}`,
      DATA: payload.body,
    });
    callback(response);
  } catch (err) {
    callback(err?.data?.message);
  } finally {
    yield put(stopLoading());
  }
}

function* get_list_games({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API:
        `${api.API_URLS.GET_GAMES_LIST}` +
        `${payload.status}` +
        `${"&skip="}` +
        `${payload.skip}` +
        `${"&limit="}` +
        `${payload.limit}`,

      DATA: payload,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      yield put(modifyList(response?.data?.data));
      yield put(pageCount(response?.data?.count));

      callback(response?.data);
    } else {
      callback(response?.data?.message);
    }
  } catch (err) {
    callback(err?.data?.message);
  } finally {
    yield put(stopLoading());
  }
}

function* delete_game({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield deleteRequest({
      API: `${api.API_URLS.DELETE_GAME}${payload}`,
    });
    callback(response);
  } catch (err) {
    callback(err?.data?.message);
  } finally {
    yield put(stopLoading());
  }
}

function* get_game_data({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: `${api.API_URLS.GET_GAME}${payload}`,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data);
    } else {
      callback(response?.data?.message);
    }
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* get_user_list({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API:
        `${api.API_URLS.GET_USER_LIST}` +
        `${payload.skip}` +
        `${"&limit="}` +
        `${payload.limit}` +  `${"&searchKey="}`+`${payload.searchKey}`,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data);
    } else {
      callback(response?.data?.message);
    }
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* get_user_details({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: `${api.API_URLS.GET_USER_DETAILS}` + `${payload}`,
    });

    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data);
    } else {
      callback(response?.data?.message);
    }
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* GamesSaga() {
  yield all([
    takeLatest(CREATE_GAMES, post_create_games),
    takeLatest(EDIT_GAMES, post_edit_games),
    takeLatest(GAMES_LISTING, get_list_games),
    takeLatest(DELETE_GAMES, delete_game),
    takeLatest(GET_GAME_DATA, get_game_data),
    takeLatest(USER_LIST, get_user_list),
    takeLatest(USER_DETAIL, get_user_details),
  ]);
}

export default GamesSaga;
