import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withSnackbar } from "notistack";
import { IOSSwitch } from "Components";
import {
  fetchTournamentMaintenance,
  updateTournamentMaintenance,
} from "Redux/Actions/Maintenace";
import { LABELS } from "Shared";
import { STATUS_CODES } from "Services/Api/Constants";

const TYPES = [
  {
    name: "GoldRush",
    label: "Is Trophy Tournament in maintenance?",
  },
  {
    name: "PrivateRush",
    label: "Is Private Tournament in maintenance?",
  },
  {
    name: "InstantWin",
    label: "Is Instant Win in maintenance?",
  },
  {
    name: "GrabAll",
    label: "Grab All ?",
  },
  {
    name: "GrabAllCron",
    label: "Grab All Hourly Tournament in maintenance?",
  },
  {
    name: "TeamTournament",
    label: "Is Team Tournament in maintenance?",
  },
];

const TournamentMaintenance = ({ enqueueSnackbar }) => {
  const dispatch = useDispatch();

  const [checked, setChecked] = useState({
    ...TYPES.reduce((obj, item) => ({ ...obj, [item.name]: false }), {}),
  });

  const updateStatus = (event) => {
    const checkedValue = event.target.checked;
    const name = event.target.name;
    let payload = {
      tournamentType: name,
      isActive: checkedValue || false,
    };
    dispatch(
      updateTournamentMaintenance(payload, (data) => {
        enqueueSnackbar(data.data.message, {
          variant: data.status === STATUS_CODES.SUCCESS ? "success" : "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        if (data.status == STATUS_CODES.SUCCESS) {
          setChecked((prev) => {
            return { ...prev, [name]: checkedValue };
          });
        }
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchTournamentMaintenance((data) => {
        setChecked({
          ...TYPES.reduce(
            (obj, item) => ({
              ...obj,
              [item.name]: data?.data?.data?.[item.name]?.isActive || false,
            }),
            {}
          ),
        });
      })
    );
  }, []);

  return (
    <div className="container-fluid page-body-wrapper">
      <h2 className="h4">{LABELS.TOURNAMENT_MAINTENANCE}</h2>
      <div className="row">
        <div className="col-md-6 col-lg-7">
          <div className="card">
            <div className="card-body">
              <div className="row">
                {TYPES.map((item) => (
                  <div className="col-12" key={item.name}>
                    <div className="form-group toggle-space-between">
                      <div className="">
                        <label>{item.label}</label>
                      </div>
                      <div className="">
                        <IOSSwitch
                          name={item.name}
                          onChange={(e) => updateStatus(e)}
                          checked={checked[item.name]}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSnackbar(TournamentMaintenance);
