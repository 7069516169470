import CustomModal from "Components/atoms/CustomModal";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { userDetails } from "Redux/Actions/GamesAction";

function ViewUserDetailsModal({
  isOpen = false,
  handleToggle = () => {},
  currentID,
}) {
  const dispatch = useDispatch();
  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    if (isOpen && currentID) {
      dispatch(
        userDetails(currentID, (data) => {
          setTempData(data.stats);
        })
      );
    }
  }, [isOpen, currentID]);

  return (
    <CustomModal handleToggle={handleToggle} isOpen={isOpen} extralarge>
      <h2 className="h4 mb-3">Item List</h2>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <InfoCard
                label="Magnet"
                value={tempData?.magnet || 0}
                isTruncate
              />
            </div>

            <div className="col-md-6 col-lg-6">
              <InfoCard
                label="FireBalls"
                value={tempData?.fireballs || 0}
                isTruncate
              />
            </div>

            <div className="col-md-6 col-lg-6">
              <InfoCard label="Bombs" value={tempData?.bombs || 0} isTruncate />
            </div>

            <div className="col-md-6 col-lg-6">
              <InfoCard
                label="mDollar"
                value={tempData?.mDollar || 0}
                isTruncate
              />
            </div>

            <div className="col-md-6 col-lg-6">
              <InfoCard
                label="Trophy Point"
                value={tempData?.mTrophy || 0}
                isTruncate
              />
            </div>

            <div className="col-md-6 col-lg-6">
              <InfoCard
                label="Life"
                value={tempData?.life || 0}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <h3 className="h5">Rewards</h3>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <fieldset className="fieldset mb-2 h-100">
                  <legend>Trophy Scores </legend>

                  <InfoCard
                    label="Total Trophy Loss"
                    value={tempData?.totalDailyLoss || 0}
                  />

                  <InfoCard
                    label="Total Trophy Played"
                    value={tempData?.totalDailyPlayed || 0}
                  />

                  <InfoCard
                    label="Total Trophy Win"
                    value={tempData?.totalDailyWin || 0}
                  />
                </fieldset>
              </div>

              <div className="col-12 mt-5">
                <fieldset className="fieldset mb-2 h-100">
                  <legend>Grab All </legend>

                  <InfoCard
                    label="Total Grab All Loss"
                    value={tempData?.grabAll?.totalLoss || 0}
                  />

                  <InfoCard
                    label="Total Grab All Played"
                    value={tempData?.grabAll?.totalPlayed || 0}
                  />

                  <InfoCard
                    label="Total Grab All Win"
                    value={tempData?.grabAll?.totalWin || 0}
                  />
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}

const InfoCard = ({ label = "", value = 0, isTruncate = false }) => {
  const renderValue = useMemo(() => {
    if (isTruncate) {
      return value ? Math.trunc(value * 100) / 100 : 0;
    }
    return value || 0;
  }, [value, isTruncate]);

  return (
    <div className="mb-2">
      <label>{label}: </label>
      <span className="d-inline-block ms-2">{renderValue}</span>
    </div>
  );
};

export default React.memo(ViewUserDetailsModal);
