import { all, fork } from "redux-saga/effects";

import watchAuth from "./Auth";
import  PrintTable  from "./PrintTable"
import  Admin  from "./Admin"
import NftSaga from "./NftSaga";
import GamesSaga from "./GamesSaga";
import AllowBotsSaga from "./AllowBot";
import ReferralSaga from "./Referral";
import CommonSaga from "./Common";
import MaintenanceSaga from "./Maintenance";
import UserRewardsSaga from "./UserRewardsHistory";
import TeamSaga from "./Team";
import JackpotSaga from "./Jackpot";


function* rootSaga() {
  yield all([fork(watchAuth)]);
  yield all([fork(PrintTable)])
  yield all([fork(Admin)])
  yield all([fork(NftSaga)])
  yield all([fork(GamesSaga)])
  yield all([fork(AllowBotsSaga)])
  yield all([fork(ReferralSaga)])
  yield all([fork(CommonSaga)])
  yield all([fork(MaintenanceSaga)])
  yield all([fork(UserRewardsSaga)])
  yield all([fork(TeamSaga)])
  yield all([fork(JackpotSaga)])



}

export default rootSaga;
