import { LIST_SKIP, MODIFY_LIST ,PAGE_COUNT} from "Redux/Actions/GamesAction";


const intialState = {
    PrivateGameList: [],
    skip:0,
    pageCount:1
};

const PrivateGameReducer = (state = intialState, action ) => {

    
  switch (action.type) {

    case MODIFY_LIST:
      return {
        ...state,
        PrivateGameList :action.payload
      };
      case LIST_SKIP:
      return {
        ...state,
        skip :action.payload
      };
      case PAGE_COUNT:
        return {
          ...state,
          pageCount :action.payload
        };

    default:
      return state;
  }
};

export default PrivateGameReducer;
