// libs
import React, { useState } from "react";
import Select from "react-select";
// components
import TableHeading from "./TableHeading.js";
import TableBody from "./TableBody.js";
import Modaal from "../../atoms/Modal/index.js";
// utils
import { MintOptions } from "../CreateNFT/SelectOptions.js";

const DisplayNftData = ({
  data,
  validate,
  minted,
  handleUpdatedData = () => { },
  searchStrg = () => { },
  setFilter = () => { },
  currentIndex,
}) => {
  const [isModalOpen, setisModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});


  const [index, settIndex] = useState({
    start: "",
    end: "",
  });

  const OpenModal = (SelecetedObject) => {
    setSelectedData({ ...SelecetedObject });
    setisModalOpen(true);
  };

  const CloseModal = () => {
    setisModalOpen(false);
  };

  function getCategoryValue(category) {
    if (category === "Mari Series") {
      return 3;
    } else if (category === "Robot Series") {
      return 2;
    } else {
      return 1;
    }
  }

  const handleSubmit = (e) => {
    const payload = {
      tokenId: e.nftCount,
      groupId: e.groupId,
      category: getCategoryValue(e.category),
      speed: e.speed,
      health: e.health,
      damage: e.damage,
      fireball: e.fireball,
      magnet: e.magnet,
      bomb: e.bomb,
      snakeName: e.snakeName,
      description: e.description,
      star: e.star,
      imageUrl: e.imageURL,
      metaKey: e.metaKey,
    };

    handleUpdatedData(payload);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    settIndex({ ...index, [name]: value });

  };


  return (
    <>
      {isModalOpen && (
        <Modaal
          validate={validate}
          selectedData={selectedData}
          isModalOpen={isModalOpen}
          handleClose={CloseModal}
          handleSubmit={handleSubmit}
        />
      )}

      <div className="row">
        <div className="col-md-4 col-lg-4 col-xl-3 text-end">
          <div className="form-group">
            <input
              className="form-control"
              value={index.start}
              name="start"
              type="number"
              onChange={changeHandler}
              placeholder="Enter start index"
            />
          </div>
        </div>
        <div className="col-md-4 col-lg-4 col-xl-3">
          <div className="form-group">
            <input
              value={index.end}
              className="mx-2 form-control"
              type="number"
              name="end"
              onChange={changeHandler}
              placeholder="Enter last index"
            />
          </div>
        </div>
        <div className="col-md-4 col-lg-4 col-xl-3">
          <div className="form-group">
            <button
              className="btn btn-md btn-secondary"
              onClick={() => {
                searchStrg(index, "indexWise");
                settIndex({ start: "", end: "" });
              }}
            >
              Search
            </button>
          </div>
        </div>

        {/* <div className="col-md-4 col-lg-4 col-xl-3">
          <div className="form-group">
            <button
              className="btn btn-md btn-secondary"
              onClick={() => searchStrg(index, "all")}
            >
              All Data
            </button>
          </div>
        </div> */}
        
        <div className="col-md-4 col-lg-4 col-xl-3">
          <div className="form-group">
            <Select
              value={minted}
              options={MintOptions}
              placeholder={"Select mint or burn status"}
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={(e) => {
                setFilter(e)
              }}
            />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered">
          <TableHeading />
          <TableBody data={data} OpenModal={OpenModal} currentIndex={currentIndex} />
        </table>
      </div>
    </>
  );
};

export default DisplayNftData;
