import { takeLatest, all, put} from "redux-saga/effects";
import {
  getRequest,
   postRequest,
} from "Shared/Axios";

import { startLoading, stopLoading } from "Redux/Actions/LoaderAction";
import {  FETCH_MAINTENANCE_STATUS, FETCH_REDEEM_CONTROL, UPDATE_MAINTENANCE_STATUS, UPDATE_REDEEM_CONTROL,  } from "Redux/Actions/Maintenace";

const api = require(`../../Services/Api/Constants`);

function* updateTournamentMaintenance({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield postRequest({
      API: `${api.API_URLS.UPDATE_MAINTENCANCE_STATUS}`,
      DATA: payload,
    });
    callback(response);
  } catch (err) {
    callback(err)
  } finally {
    yield put(stopLoading());
  }
}

function* fetchTournamentMaintenance({ callback }) {
    try {
      yield put(startLoading());
      const response = yield getRequest({
        API: api.API_URLS.FETCH_MAINTENCANCE_STATUS,
      });
      callback(response);
    } catch (error) {
      callback(error);
    } finally {
      yield put(stopLoading());
    }
  }

  function* updateRedeemControl({ payload, callback }) {
    try {
      yield put(startLoading());
      const response = yield postRequest({
        API: `${api.API_URLS.SET_REDEEM_CONTROL}`,
        DATA: payload,
      });
      callback(response);
    } catch (err) {
      callback(err)
    } finally {
      yield put(stopLoading());
    }
  }

  function* fetchRedeemControl({ callback }) {
    try {
      yield put(startLoading());
      const response = yield getRequest({
        API: api.API_URLS.REDEEM_CONTROL,
      });
      callback(response);
    } catch (error) {
      callback(error);
    } finally {
      yield put(stopLoading());
    }
  }

function* MaintenanceSaga() {
    yield all([
      takeLatest(UPDATE_MAINTENANCE_STATUS, updateTournamentMaintenance),
      takeLatest(FETCH_MAINTENANCE_STATUS, fetchTournamentMaintenance),
      takeLatest(UPDATE_REDEEM_CONTROL, updateRedeemControl),
      takeLatest(FETCH_REDEEM_CONTROL, fetchRedeemControl),      
    ]);
  }
  
  export default MaintenanceSaga;