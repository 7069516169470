import { takeLatest, all, put } from "redux-saga/effects";
import { STATUS_CODE, STRINGS } from "../../Shared/Constants";
import {getRequest, postRequest} from "Shared/Axios";

import { startLoading, stopLoading } from "Redux/Actions/LoaderAction";
import { GET_TOURNAMENTS_GAME_USERS, GET_TOURNAMENTS_LOGS, GET_TRANSACTIONS } from "Redux/Actions/Common";
import { GET_MONTHLY_LOGS, GET_SUBSCRIPTIONS, GET_SUBSCRIPTION__FREE_TRIALS, GET_SUBSCRIPTIONS_USER_ACTIVITIES, GET_REGISTERED_USER_LOGS, GET_REGISTERED_LOGS_BY_ID } from "Redux/Actions/ActionTypes";

const api = require(`../../Services/Api/Constants`);

function* get_transaction_history_list({ payload, callback }) {
  try {
    yield put(startLoading());
    let apiUrl = `${api.API_URLS.TRANSACTION_HISTORY}?skip=${payload.skip}&limit=${payload.limit}&type=${payload.type??''}`;
    if(payload?.searchKey){
      apiUrl = `${apiUrl}&searchKey=${payload?.searchKey}`;
    }
    const response = yield getRequest({
      API: apiUrl,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data);
    } else {
      callback(response?.data?.message);
    }
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* getTournamentLogs({ payload, callback }) {
  try {
    yield put(startLoading());
    let apiUrl = `${api.API_URLS.TOURNAMENT_LOGS}?skip=${payload.skip}&limit=${payload.limit}`;
    if(payload?.searchKey){
      apiUrl = `${apiUrl}&searchKey=${payload?.searchKey}`;
    }
    const response = yield getRequest({
      API: apiUrl,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data);
    } else {
      callback(response?.data?.message);
    }
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* getTournamentGameUsers({ payload, callback }) {
  try {
    yield put(startLoading());
    let apiUrl = `${api.API_URLS.TOURNAMENT_GAME_USERS}?skip=${payload.skip}&limit=${payload.limit}`;
    if(payload?.roomId){
      apiUrl = `${apiUrl}&roomId=${payload?.roomId}`;
    }
    const response = yield getRequest({
      API: apiUrl,
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data);
    } else {
      callback(response?.data?.message);
    }
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* getMonthlyLogs({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: api.API_URLS.MONTHLY_LOGS,
      params: payload
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data);
    } else {
      callback(response?.data?.message);
    }
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* getSubscriptionFreeTrials({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: api.API_URLS.SUBSCRIPTION_FREE_TRIALS,
      params: payload
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data);
    } else {
      callback(response?.data?.message);
    }
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* getSubscriptions({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: api.API_URLS.SUBSCRIPTIONS,
      params: payload
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data);
    } else {
      callback(response?.data?.message);
    }
  } catch (err) {
    callback(err);
  } finally {
    yield put(stopLoading());
  }
}

function* getUserActivities({ payload, callback }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: api.API_URLS.ACTIVITIES,
      params: {
        userId: payload
      }
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(null, response?.data?.message);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data, null);
    } else {
      callback(null, response?.data?.message);
    }
  } catch (err) {
    callback(null, err?.message || err);
  } finally {
    yield put(stopLoading());
  }
}

function* getRegisteredUserLogs({ payload = {}, callback = () => {}  }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: api.API_URLS.REGISTERED_USER_LOGS,
      params: payload
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(null, response?.data?.message,);
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data, response?.status);
    } else {
      callback(null, response?.data?.message);
    }
  } catch (err) {
    callback(err?.message || err, STATUS_CODE.badRequest);
  } finally {
    yield put(stopLoading());
  }
}

function* getRegisteredLogsById({ payload = {}, callback = () => {}  }) {
  try {
    yield put(startLoading());
    const response = yield getRequest({
      API: api.API_URLS.REGISTERED_LOGS_BY_ID,
      params: payload
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      callback(null, response?.data?.message, "error");
    } else if (response.status == STATUS_CODE.successful) {
      callback(response?.data, response?.status);
    } else {
      callback(null, response?.data?.message, "error");
    }
  } catch (err) {
    callback({}, err?.message || STRINGS.SOMETHING_WENT_WRONG, "error");
  } finally {
    yield put(stopLoading());
  }
}

function* CommonSaga() {
  yield all([
    takeLatest(GET_TRANSACTIONS, get_transaction_history_list),
    takeLatest(GET_TOURNAMENTS_LOGS, getTournamentLogs),
    takeLatest(GET_TOURNAMENTS_GAME_USERS,getTournamentGameUsers),
    takeLatest(GET_MONTHLY_LOGS,getMonthlyLogs),
    takeLatest(GET_SUBSCRIPTION__FREE_TRIALS,getSubscriptionFreeTrials),
    takeLatest(GET_SUBSCRIPTIONS,getSubscriptions),
    takeLatest(GET_SUBSCRIPTIONS_USER_ACTIVITIES, getUserActivities),
    takeLatest(GET_REGISTERED_USER_LOGS, getRegisteredUserLogs),
    takeLatest(GET_REGISTERED_LOGS_BY_ID, getRegisteredLogsById),
  ]);
}

export default CommonSaga;
