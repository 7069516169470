import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from "react-modal"
import "../node_modules/bootstrap/scss/bootstrap.scss"
import './index.scss';
import App from './App';

ReactModal.setAppElement("#root")
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);