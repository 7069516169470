import { LABELS } from "Shared";
import { FREE_TRIAL_STATUS } from "Shared/Constants";

export const FREE_TRIALS_HEADINGS = [LABELS.S_NO, LABELS.USER_NAME, LABELS.UID, LABELS.EXPIRY_TIME, LABELS.STATUS];

export const handleShowStatus = (value) => {
    switch(value){
        case FREE_TRIAL_STATUS.ACTIVE : 
        return LABELS.ACTIVE;
        case FREE_TRIAL_STATUS.PENDING : 
        return LABELS.PENDING;
        case FREE_TRIAL_STATUS.EXPIRED : 
        return LABELS.EXPIRED;
        default : 
        return ""
    }
}
