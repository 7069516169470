const TableHeading = () => {
    return (
      <thead className="bg-dark text-white align-content-center">
        <tr>
        <th> S.no </th>
        <th> Date </th>
          <th> Email </th>
          <th> ETH</th>
          <th>UserID</th>

          <th>User Name</th>
          <th>Wallet Address</th>
          <th>Status</th>
        </tr>
      </thead>
    );
  };
  
  export default TableHeading;