const TableHeading = () => {
    return (
      <thead className="bg-dark text-white align-content-center">
        <tr>
        <th> S.no </th>
          <th> User Name</th>
          <th> Phone Number </th>
          <th> Email </th>
          <th> Country </th>
          <th> UID </th>
          <th> Details</th>
        </tr>
      </thead>
    );
  };
  
  export default TableHeading;