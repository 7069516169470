// libs
import React from "react";
import { withSnackbar } from "notistack";
// components
import CreateGames from "Components/molecules/CreateGames";
import TableList from "./Component/TableHeading/TableList";
// styles
import 'react-toastify/dist/ReactToastify.css'


const PrivateGames = () => {
 
  return (
    <div className="container-fluid page-body-wrapper">
      <CreateGames />
      <TableList  />
    </div>
  );
};

export default withSnackbar(PrivateGames);

