import Login from "Views/Login";
import { ROUTES } from "./Constants";

export const AUTH_ROUTES = [
  {
    path: ROUTES.LOGIN,
    component: Login,
    title: "Login",
  }
];
