const InputField = ({
  min,
  max,
  type,
  value,
  reference,
  onBlur,
  multiple,
  onChange,
  label,
  rows,
  name,
  textAria = false,
  placeholder,
  className,
  children,
  required,
  ...props
}) => {
  return (
  <div className="form-group">
   { name!== "fileUploads" && <label>{label}   <sup className="req_txt">*</sup></label>}
    
      <div className="input-fields">
        { !textAria ? (
          <input
            ref={reference}
            multiple={multiple}
            min={min}
            value={value}
            type={type}
            className={className}
            onBlur={onBlur}
            required={required}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            autoComplete="off"
            {...props}
          />
        ) : (
          <textarea
            onChange={onChange}
            type={type}
            value={value}
            name={name}
            onBlur={onBlur}
            className={`form-control `}
            rows={rows}
          >
            {children}
          </textarea>
        )}
      </div> 
    </div>
  );
};

export default InputField;
