// libs
import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
// components
import CustomModal from 'Components/atoms/CustomModal';
// hooks
import { useDispatch } from 'react-redux';
// utils
import { Button, TextField } from 'Components';
import { sendTeamWinnerRewards } from 'Redux/Actions/Team';
import { LABELS, PLACEHOLDERS, STRINGS, VAL_MESSAGES } from 'Shared';
import { STATUS_CODES } from 'Services/Api/Constants';

const validate = Yup.object({
  rewardAmount: Yup.number()
    .min(0, VAL_MESSAGES.AMOUNT_CAN_NOT_NEGATIVE)
    .max(99999999999, VAL_MESSAGES.AMOUNT_MAX)
    .required(VAL_MESSAGES.REQUIRED),
});

function GiveRewardModal({
  isOpen = false,
  handleModalClose = () => {},
  currentTeamId = '',
  currentId = '',
  fetchTeamDetails = () => {},
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = ({ rewardAmount }, { resetForm }) => {
    const payload = {
      rewardAmount: rewardAmount,
      teamId: currentTeamId,
      id: currentId,
    };
    dispatch(
      sendTeamWinnerRewards(payload, (response) => {
        enqueueSnackbar(response?.data?.message || STRINGS.SOMETHING_WENT_WRONG, {
          variant: response?.status == STATUS_CODES.SUCCESS ? 'success' : 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        if (response?.status == STATUS_CODES.SUCCESS) {
          fetchTeamDetails();
          handleModalClose();
        }
      })
    );
    resetForm();
  };

  return (
    <CustomModal handleToggle={handleModalClose} isOpen={isOpen}>
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validate}
        initialValues={{
          rewardAmount: 1,
        }}
      >
        {() => (
          <>
            <Form>
              <h2 className="h4 mb-4 text-capitalize text-center">{LABELS.REWARD}</h2>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12 col-lg-12">
                      <TextField
                        label={LABELS.REWARD_AMOUNT}
                        name="rewardAmount"
                        type="number"
                        step="1"
                        placeholder={PLACEHOLDERS.REWARD_AMOUNT}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center mt-4">
                <Button className="btn btn-md btn-secondary" type="submit">
                  {LABELS.GIVE}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </CustomModal>
  );
}

export default GiveRewardModal;
