export const ROUTES = {
    BLACKLIST_NFT: "/blacklist-nft",
    CREATE_NFT: "/create-nft",
    REDEEM_NFT: "/redeem-nft",
    REDEEM_AIRDROP: "/redeem-airdrop",
    REDEEM_CONTROL:"/redeem-control",
    ROYALTY: "/royalty",
    JACKPOT: "/jackpot",
    PRIVATE_GAMES: "/private-games",
    USER_LIST: "/user-list",
    CASH_LIST: "/cash-list",
    ALLOW_BOTS: "/allow-bots",
    REDEEM_USD: "/redeem-usd",
    REDEEM_BTC: "/redeem-btc",
    REDEEM_HKD: "/redeem-hkd",
    REFERRAL: "/referral",
    TRANSACTION_HISTORY: "/transaction-history",
    TOURNAMENT_MAINTENANCE : "/tournament-maintenance",
    TOURNAMENT_LOGS : "/tournament-logs",
    USER_REWARDS_HISTORY : "/user-rewards-history",
    TEAMS: "/teams",
    TEAM_TOURNAMENT_WINNERS: "/team-tournament-winners",
    TEAM_TOURNAMENT_RULES: "/team-tournament-rules",
    CASH_POINT_LOGS: "/cashpoint-logs",
    FREE_TRIALS: "/free-trials",
    SUBSCRIPTIONS: "/subscriptions",
    LOGIN: "/login",
    USER_LOGS: "/user-logs",
};