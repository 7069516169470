import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";

/**
 * Hook that checks clicks outside of the passed ref
 */
function useOutsideClickFunc(ref, onClick) {
	const isLoading = useSelector((state) => state?.video?.isLoading);

	useEffect(() => {
		/**
		 * do something if clicked on outside of wrapped element
		 */
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				!isLoading && onClick();
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [onClick, ref]);
}

/**
 * Component that checks if you click outside of it
 */
export default function CheckClickOutside(props) {

	
	const wrapperRef = useRef(null);
	useOutsideClickFunc(wrapperRef, props.onClick);
	return <div ref={wrapperRef}>{props.children}</div>;
}