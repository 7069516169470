// libs
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import "./style.scss";

// components
import { CustomTable } from "Components";
// utils
import { indexHistory } from "Services/Api/Constants";
import { deviceType, LABELS, PLACEHOLDERS, UTILITIES } from "Shared";
import {
  ACTIVITIES_HEADINGS,
  SUBSCRIPTION_HEADINGS,
  SUBSCRIPTION_STATE,
} from "./Helpers";
import { getSubscriptions, getUserActivities } from "Redux/Actions/Common";
// icons
import viewIcon from "assets/images/icons/eye_icon.svg";
import CustomModal from "Components/atoms/CustomModal";
// hooks
import { withSnackbar } from "notistack";
// constants
const limit = 10;

function Subscriptions({ enqueueSnackbar }) {
  const [pageCount, setPageCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [listData, setListData] = useState([]);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(null);
  const [dataStatus, setDataStatus] = useState(deviceType[3]);
  const skipMountref = useRef(false);

  const dispatch = useDispatch();

  const fetchRecords = (skip = 0, pageNo = "") => {
    const payload = {
      limit: limit,
      searchKey: search,
      type: dataStatus.value,
      skip,
    };
    dispatch(
      getSubscriptions(payload, (data) => {
        setListData(data?.data);
        setPageCount(Math.ceil(data?.count / limit));
        if (typeof pageNo === "number") {
          setCurrentIndex(pageNo);
        }
      })
    );
  };

  const handlePageClick = (pageData) => {
    let currentPage = pageData.selected;
    fetchRecords(currentPage * limit, currentPage);
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  const handleSearch = () => {
    fetchRecords();
  };

  useEffect(()=>{
    if(skipMountref.current) fetchRecords()
    else skipMountref.current = true
  },[dataStatus])

  const modalShow = (id) => {
    dispatch(
      getUserActivities(id, (response, error) => {
        if (error) {
          enqueueSnackbar(error, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else {
          setShowModal(response.data);
        }
      })
    );
  };

  const updateDeviceType = (item) => {
    switch (item.type) {
      case 1:
        return "Android"
      case 2:
        return "Paypal" 
      case 3:
        return "IOS" 

      default:
        return null
    }
        
  }

  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <div className="card mt-4">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-sm-4 mb-3">
                <h4 className="h6 mb-0">{LABELS.SUBSCRIPTIONS}</h4>
              </div>

              <div className="col-sm-8 mb-3">
                <div className="search_bar transaction-search input-group ms-auto">
                  <div className="input-group-prepend">
                    <Select
                      options={deviceType}
                      placeholder={"Select Transaction Type"}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      onChange={(e) => {
                        setDataStatus(e);
                      }}
                      value={dataStatus}
                    />
                  </div>
                  <input
                    type="text"
                    placeholder={PLACEHOLDERS.USERNAME_UID}
                    className="form-control"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  <div className="input-group-prepend">
                    <button
                      className="btn btn-md btn-primary"
                      onClick={handleSearch}
                    >
                      {LABELS.SUBMIT}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {listData?.length ? (
                <div className="col-md-12">
                  <CustomTable
                    isPagination
                    headings={SUBSCRIPTION_HEADINGS}
                    pageCount={pageCount}
                    currentIndex={currentIndex}
                    handlePageClick={handlePageClick}
                  >
                    {listData?.map((item, index) => {
                      return (
                        <tr key={item?.userId}>
                          <td>{indexHistory(currentIndex) + index}</td>
                          <td>{item?.userName || ""}</td>
                          <td>{updateDeviceType(item)}</td>
                          <td>{item?.userId || ""}</td>
                          <td>{item?.orderId || "N/A"}</td>
                          <td>
                            {UTILITIES.renderDate(item?.startTime) || "N/A"}
                          </td>
                          <td>
                            {UTILITIES.renderDate(item?.endTime) || "N/A"}
                          </td>
                          <td>
                            <button
                              className="btn btn-sm btn-primary mx-1"
                              onClick={() => modalShow(item?.userId)}
                            >
                              <img src={viewIcon} alt="view" width="20" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </CustomTable>
                </div>
              ) : (
                <p className="d-flex justify-content-center">
                  {LABELS.NO_RECORDS_FOUND}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        handleToggle={() => setShowModal(null)}
        isOpen={Boolean(showModal)}
        showClose
        large
      >
        <h2 className="h4 mb-3">{LABELS.SUBSCRIPTION_ACTIVITIES}</h2>
        {showModal?.length ? (
          <div className="col-md-12 subscription-activities">
            <CustomTable
              headings={ACTIVITIES_HEADINGS}
              pageCount={pageCount}
              currentIndex={currentIndex}
              handlePageClick={handlePageClick}
            >
              {showModal?.map((item, index) => {
                return (
                  <tr key={item.createdAt}>
                    <td>{index + 1}</td>
                    <td>{UTILITIES.renderDate(item?.createdAt)}</td>
                    <td>{SUBSCRIPTION_STATE[item?.status]}</td>
                  </tr>
                );
              })}
            </CustomTable>
          </div>
        ) : (
          <p className="d-flex justify-content-center">
            {LABELS.NO_RECORDS_FOUND}
          </p>
        )}
      </CustomModal>
    </>
  );
}

export default withSnackbar(Subscriptions);
