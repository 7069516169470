import { LABELS, STRINGS } from "Shared";
import { withSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { Button } from "Components";
import { JACKPOT_TAB_TYPES } from "./helpers";
import { CustomJackpot, DefaultJackpot } from "./components";

const Jackpot = () => {
  const [activeTab, setActiveTab] = useState(JACKPOT_TAB_TYPES.DEFAULT);

  const handleTabChange = (type) => {
    setActiveTab(type);
  };

  const renderActiveTab = useMemo(() => {
    switch (activeTab) {
      case JACKPOT_TAB_TYPES.DEFAULT:
        return <DefaultJackpot />;
      case JACKPOT_TAB_TYPES.CUSTOM:
        return <CustomJackpot />;
      default:
        return null;
    }
  }, [activeTab]);

  return (
    <div className="container-fluid page-body-wrapper">
      <div>
        <h2 className="h4 mb-4">{LABELS.JACKPOT}</h2>

        <div className="d-flex align-items-center justify-content-start flex-wrap">
          <Button
            className={`btn btn-md ${
              activeTab === JACKPOT_TAB_TYPES.DEFAULT
                ? "btn-primary"
                : "btn-secondary"
            } mx-1`}
            onClick={() => handleTabChange(JACKPOT_TAB_TYPES.DEFAULT)}
          >
            {LABELS.DEFAULT}
          </Button>
          <Button
            className={`btn btn-md ${
              activeTab === JACKPOT_TAB_TYPES.CUSTOM
                ? "btn-primary"
                : "btn-secondary"
            } mx-1`}
            onClick={() => handleTabChange(JACKPOT_TAB_TYPES.CUSTOM)}
          >
            {LABELS.CUSTOM}
          </Button>
        </div>
        <div className="card mt-3 px-5 pt-3 pb-3">{renderActiveTab}</div>
      </div>
    </div>
  );
};

export default withSnackbar(Jackpot);
