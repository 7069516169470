// libs
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withSnackbar } from "notistack";
// components
import Button from "../../Components/atoms/Button";
import { nftRange, previousRange } from "Redux/Actions/NftData";
import { LABELS, PLACEHOLDERS } from "Shared";


const Nft = ({enqueueSnackbar}) => {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  const dispatch = useDispatch();

  const setRestric = () => {
    const payload = {
      max: max,
      min: min,
    };
    dispatch(
      nftRange(payload, (data) => {
        enqueueSnackbar(data.data.message, {
          variant: data.status===200 ? "success" : "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      })
    );
  };
  useEffect(() => {
    dispatch(
      previousRange((data) => {
        setMin(data?.data?.data[0]?.min)
        setMax(data?.data?.data[0]?.max)
       
      })
    );
  }, []);
  return (
    <div className="container-fluid page-body-wrapper">
      <h2 className='h4'>{LABELS.BLACKLIST_NFT}</h2>
      <div className="row">
        <div className="col-md-6 col-lg-7">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>{LABELS.ENTER_MAX_VALUE}</label>
                    <input type={"text"} onChange={(e) => setMax(e.target.value)} value={max} placeholder={PLACEHOLDERS.MAX} className="form-control" required />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>{LABELS.ENTER_MIN_VALUE}</label>
                    <input type={"text"} onChange={(e) => setMin(e.target.value)} value={min} placeholder={PLACEHOLDERS.MIN} className="form-control" required />
                  </div>
                </div>
                <div className="col-12 text-center">
                  <Button className="btn btn-md btn-secondary" onClick={() => { setRestric() }}>{LABELS.UPDATE}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSnackbar(Nft);